import {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import IScrubOptionsAdvOutputConfig, {
  OptionGroup,
} from "src/interfaces/Main/IScrubOptionsAdvOutputConfig";
import KeyValuePair from "src/interfaces/KeyValuePair";

const ScrubOptionsAdvancedOutputConfigurator = forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const { projectId, campaignId } = useUploadListContext();
  const [configData, setConfigData] = useState<IScrubOptionsAdvOutputConfig[]>(
    [],
  );
  const [keyValuePairs, setKeyValuePairs] = useState<KeyValuePair[]>([]);

  useImperativeHandle(ref, () => ({
    save: handleSave,
  }));

  useEffect(() => {
    const fetchAdvancedOutputConfigurator = async () => {
      try {
        setIsLoading(true);
        const apiUrl = `Main/ScrubOptions/AdvancedOutputConfigurator?projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        if (response) {
          const initialKeyValuePairs: KeyValuePair[] = [];
          const initializedData = response.statusCodes.map(
            (code: IScrubOptionsAdvOutputConfig) => {
              code.dropdownOptions.forEach((group) => {
                initialKeyValuePairs.push({
                  key: `${code.statusCode}-${group.position}`,
                  value: group.selectedValue.toString(),
                });
              });
              return {
                ...code,
                dropdownOptions: code.dropdownOptions.map(
                  (group: OptionGroup) => ({
                    ...group,
                    selectedValue: group.selectedValue,
                  }),
                ),
              };
            },
          );
          setConfigData(initializedData);
          setKeyValuePairs(initialKeyValuePairs);
        }
      } catch (error) {
        console.error("Failed to fetch Scrub Options:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (campaignId !== "") {
      fetchAdvancedOutputConfigurator();
    }
  }, [projectId, campaignId]);

  const handleResetToDefaults = useCallback(() => {
    const resetKeyValuePairs: KeyValuePair[] = [];
    configData.forEach((code) => {
      code.dropdownOptions.forEach((group) => {
        resetKeyValuePairs.push({
          key: `${code.statusCode}-${group.position}`,
          value: group.defaultValue.toString(),
        });
      });
    });
    setKeyValuePairs(resetKeyValuePairs);
  }, [configData]);

  const handleDropdownChange = useCallback(
    (
      statusCode: string,
      optionGroupPosition: number,
      selectedValue: string,
    ) => {
      setKeyValuePairs((prevKeyValuePairs) =>
        prevKeyValuePairs.map((pair) => {
          if (pair.key === `${statusCode}-${optionGroupPosition}`) {
            return { key: pair.key, value: selectedValue };
          }

          if (optionGroupPosition !== 0 && pair.key === `${statusCode}-0`) {
            return { key: pair.key, value: "0" };
          }

          if (
            optionGroupPosition === 0 &&
            pair.key.startsWith(`${statusCode}-`)
          ) {
            return { key: pair.key, value: selectedValue };
          }

          return pair;
        }),
      );
    },
    [],
  );

  const handleSave = async () => {
    try {
      const apiUrl = `Main/ScrubOptions/SaveAdvancedOutputConfigurator?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.POST, keyValuePairs);
      if (response) {
        console.log("Save successful");
      }
    } catch (error) {
      console.error("Failed to save Scrub Options:", error);
    }
  };

  const renderDropdown = useCallback(
    (code: IScrubOptionsAdvOutputConfig, group: OptionGroup, index: number) => {
      let value = group.selectedValue.toString();

      if (code.type === "i" && group.position === 0) {
        const positions = [1, 2, 3, 4];
        const selectedValues = positions.map(
          (pos) =>
            keyValuePairs.find(
              (pair) => pair.key === `${code.statusCode}-${pos}`,
            )?.value,
        );

        const allValuesSame = selectedValues.every(
          (val) => val === selectedValues[0] && val !== undefined,
        );

        if (allValuesSame && selectedValues[0] !== undefined) {
          value = selectedValues[0].toString();
        } else {
          value = "0";
        }
      } else {
        value =
          keyValuePairs
            .find((pair) => pair.key === `${code.statusCode}-${group.position}`)
            ?.value?.toString() || group.selectedValue.toString();
      }

      return (
        <select
          key={`${code.statusCode}-${group.position}`}
          id={`${code.statusCode}-${group.position}`}
          className="form-select"
          disabled={!code.canUserChangeOutputFile}
          value={value}
          onChange={(e) =>
            handleDropdownChange(
              code.statusCode,
              group.position,
              e.target.value,
            )
          }
        >
          {code.type === "i" && group.position === 0 && (
            <option key="blank" value="0"></option>
          )}
          {group.dropdownOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      );
    },
    [keyValuePairs, handleDropdownChange],
  );

  const renderBTypeTable = useCallback(() => {
    const filteredStatusCodes = configData.filter((code) => code.type === "b");

    return (
      <table className="table mt-3">
        <thead>
          <tr>
            <th>Code</th>
            <th>Scrub Status Description</th>
            <th>Output File</th>
          </tr>
        </thead>
        <tbody>
          {filteredStatusCodes.map((code, index) => (
            <tr key={index}>
              <td>{code.statusCode}</td>
              <td>{code.description}</td>
              <td>{renderDropdown(code, code.dropdownOptions[0], 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [configData, renderDropdown]);

  const renderITypeTable = useCallback(() => {
    const filteredStatusCodes = configData.filter((code) => code.type === "i");
    return (
      <table className="table mt-3">
        <thead>
          <tr>
            <th>Code</th>
            <th>Scrub Status Description</th>
            <th>EBR Type</th>
            <th>Output File</th>
          </tr>
        </thead>
        <tbody>
          {filteredStatusCodes.map((code, index) => (
            <Fragment key={index}>
              <tr>
                <td rowSpan={5}>{code.statusCode}</td>
                <td rowSpan={5}>{code.description}</td>
                <td>All EBRs</td>
                <td>{renderDropdown(code, code.dropdownOptions[0], 0)}</td>
              </tr>
              <tr>
                <td>Inquiry</td>
                <td>{renderDropdown(code, code.dropdownOptions[2], 2)}</td>
              </tr>
              <tr>
                <td>Sale</td>
                <td>{renderDropdown(code, code.dropdownOptions[1], 1)}</td>
              </tr>
              <tr>
                <td>Permission</td>
                <td>{renderDropdown(code, code.dropdownOptions[3], 3)}</td>
              </tr>
              <tr>
                <td>Recent Sale</td>
                <td>{renderDropdown(code, code.dropdownOptions[4], 4)}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    );
  }, [configData, renderDropdown]);

  const getSelectedValuesDisplay = () => {
    return keyValuePairs.map((pair) => {
      const [statusCode, position] = pair.key.split("-");
      const code = configData.find((code) => code.statusCode === statusCode);
      const group = code?.dropdownOptions.find(
        (group) => group.position.toString() === position,
      );
      const option = group?.dropdownOptions.find(
        (option) => option.key === pair.value,
      );
      return {
        statusCode,
        position,
        description: code?.description || "",
        selectedValue: pair.value,
        selectedName: option?.value || "",
      };
    });
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {renderBTypeTable()}
          {renderITypeTable()}
          <button
            type="button"
            onClick={handleResetToDefaults}
            className="btn btn-primary mt-3 me-2"
          >
            Reset Output Files Configuration to Defaults
          </button>
          <div className="d-none mt-3">
            <h5>Selected Values:</h5>
            <ul>
              {getSelectedValuesDisplay().map((item, index) => (
                <li key={index}>
                  Code: {item.statusCode}, Position: {item.position}, Selected
                  Value: {item.selectedValue}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
});

export default ScrubOptionsAdvancedOutputConfigurator;
