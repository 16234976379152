import IframeComponent from "src/components/IframeComponent";

function SelfRegister() {
  return (
    <div className="row">
      <IframeComponent
        title="Self Register"
        src="https://classic.dncscrub.com/app/users/u-profile.adp"
        width="100%"
        height="275px"
      />
    </div>
  );
}

export default SelfRegister;
