import { useState, useEffect, useCallback, useRef } from "react";
import { selectAuth } from "src/features/authSlice";
import { useAppSelector } from "src/app/hooks";
import { callApi, HttpMethod } from "src/services/apiService";

const useInactivity = (timeoutDuration: number): [boolean, () => void] => {
  // We check here if the user is authenticated as the timer
  // should only run if the user is autheticated (eg not at the login screen)
  const { isAuthenticated } = useAppSelector(selectAuth);

  const [inactive, setInactive] = useState<boolean>(false);

  const inactivityTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetSetTimeout = useCallback(() => {
    setInactive(false);

    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);

    inactivityTimer.current = setTimeout(() => {
      setInactive(true);
    }, timeoutDuration);
  }, [timeoutDuration, setInactive, inactivityTimer]);

  const sendKeepAliveAndReset: () => Promise<void> = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    // Every time a click happens we want
    // to send a keepalive to the server so the server
    // doesn't expire the session.
    const sendKeepAlive = async () => {
      //alert("Sending keepalive. isAuthenticated: " + isAuthenticated);
      //console.log("Sending keepalive");
      await callApi("users/IsAuthenticated", HttpMethod.GET);
      setInactive(false);
      resetSetTimeout();
    };

    sendKeepAlive(); // Call the async function within useCallback
  }, [isAuthenticated, resetSetTimeout]);

  useEffect(() => {
    // Debounce function to prevent flooding the server
    // I put this funciton in here as trying to define it
    // outside the useEffect would cause the useEffect
    // to keep repeating the function call
    function debounce<T extends (...args: any[]) => void>(
      func: T,
      wait: number,
    ): T {
      let timeout: number | null = null;

      const debouncedFunction = (...args: Parameters<T>) => {
        const later = () => {
          timeout = null;
          func(...args);
        };
        if (timeout !== null) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait) as unknown as number;
      };

      return debouncedFunction as T;
    }

    const debouncedKeepAlive = debounce(sendKeepAliveAndReset, 5000);

    if (!isAuthenticated) {
      return;
    }
    window.addEventListener("click", debouncedKeepAlive);
    window.addEventListener("keydown", debouncedKeepAlive);
    window.addEventListener("mousemove", debouncedKeepAlive);

    const handleVisibilityChange = () =>
      document.visibilityState === "visible" && debouncedKeepAlive();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    debouncedKeepAlive(); // Start the timer when the hook is used

    return () => {
      // Cleanup
      window.removeEventListener("click", debouncedKeepAlive);
      window.removeEventListener("keydown", debouncedKeepAlive);
      window.removeEventListener("mousemove", debouncedKeepAlive);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, [sendKeepAliveAndReset, isAuthenticated]);

  return [inactive, resetSetTimeout];
};

export default useInactivity;
