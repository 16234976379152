import { useState, useEffect } from "react";
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import SaveInProgress from "src/components/SaveInProgress";
import {
  ICrossAccountsAgencyAccount,
  ICrossAccountAccess,
} from "src/interfaces/Admin/ICrossAccount";

interface CrossAccountsAccessProps {
  agency: ICrossAccountsAgencyAccount;
  onClose: () => void;
}

interface FormValues {
  projects: ICrossAccountAccess[];
}

const CrossAccountsAccess: React.FC<CrossAccountsAccessProps> = ({
  agency,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [allAccess, setAllAccess] = useState<string>("");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [formData, setFormData] = useState<ICrossAccountAccess[]>([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      projects: [],
    },
  });

  useEffect(() => {
    const fetchCrossAccountAccesss = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `admin/crossaccounts/ProjectAccess?agencyAcctId=${agency.agencyAcctId}`;
        let response: ICrossAccountAccess[] | ICrossAccountAccess =
          await callApi(apiUrl, HttpMethod.GET);
        const projects = Array.isArray(response) ? response : [response];
        setFormData(projects);
        setValue("projects", projects);
      } catch (error) {
        console.error("Failed to fetch project access data:", error);
        setWarningMessage("Failed to fetch project access data.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchCrossAccountAccesss();
  }, []);

  const onCrossAccountAccessSubmit = async (
    formData: FormValues,
    event?: React.BaseSyntheticEvent,
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    //Abhi K:
    //Value is converted to string as KeyValuePair.Value is string
    const data = formData.projects
      .filter(({ access }) => access !== 0)
      .map(({ projId, access }) => ({
        key: projId,
        value: typeof access === "string" ? access : JSON.stringify(access),
      }));
    console.log(data);
    setSaveInProgress(true);
    try {
      const apiUrl = `admin/crossaccounts/Update/${agency.agencyAcctId}`;
      const method = HttpMethod.POST;
      const body = data;

      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
    setSaveInProgress(false);
  };

  useEffect(() => {
    if (allAccess !== "") {
      formData.forEach((_, index) => {
        setValue(`projects.${index}.access`, parseInt(allAccess));
      });
    }
  }, [allAccess]);

  const handleSetAllChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAllAccess(e.target.value);
  };
  if (isLoading) return <Loading />;
  if (warningMessage) return <Warning message={warningMessage} />;

  return (
    <Modal show={true} onHide={onClose} animation={false}>
      <form onSubmit={handleSubmit(onCrossAccountAccessSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mark text-center">
            <strong>{agency.agencyName}</strong>
          </p>
          <div id="agencywrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span>
                <span>Configure Access to my projects:</span>
              </span>
              <select
                id="setAllDropdown"
                value={allAccess}
                onChange={handleSetAllChange}
              >
                <option value="">Set All...</option>
                <option value="0">No Access</option>
                <option value="2">Limited Access</option>
                <option value="14">Full Access</option>
              </select>
            </div>
            {formData.map((project: ICrossAccountAccess, index: number) => (
              <div
                key={project.projId}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <span
                  style={{
                    fontWeight: project.masterProj === 1 ? "bold" : "normal",
                  }}
                >
                  {project.usesMasterProjDNCdb ||
                  project.usesMasterProjEBRdb ? (
                    <>
                      <span>This Project uses the Master Project's </span>
                      {project.usesMasterProjDNCdb && <span>DNC/DNM</span>}
                      {project.usesMasterProjDNCdb &&
                        project.usesMasterProjEBRdb && <span> and </span>}
                      {project.usesMasterProjEBRdb && <span>EBR</span>}
                      <span>
                        {" "}
                        database
                        {project.usesMasterProjDNCdb &&
                        project.usesMasterProjEBRdb
                          ? "s"
                          : ""}
                      </span>
                    </>
                  ) : project.masterProj === 1 ? (
                    <span>Master Project</span>
                  ) : (
                    project.name
                  )}
                </span>
                <select
                  {...register(`projects.${index}.access`, {
                    valueAsNumber: true,
                  })}
                  defaultValue={project.access - project.masterProj || "0"}
                >
                  <option value="0">No Access</option>
                  <option value="2">Limited Access</option>
                  <option value="14">Full Access</option>
                </select>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isDirty}
            onClick={() => handleSubmit(onCrossAccountAccessSubmit)()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
      <SaveInProgress isVisible={saveInProgress} />
    </Modal>
  );
};

export default CrossAccountsAccess;
