import { useState } from "react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { PdncSearchDTO, CpaSection } from "src/interfaces/Main/IInternalDNC";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";

interface SearchDNCDatabaseProps {
  access: CpaSection;
  fetchedRegions: { state: string; stateName: string }[];
  onActionComplete: () => void;
}

const SearchDNCDatabase: React.FC<SearchDNCDatabaseProps> = ({
  access,
  fetchedRegions,
  onActionComplete,
}) => {
  const { projectId, campaignId } = useUploadListContext();
  const { register, getValues, reset, handleSubmit } = useForm<PdncSearchDTO>({
    defaultValues: {
      isDNC: true,
      isDNM: false,
      fullPhone: "",
      firstName: "",
      lastName: "",
      city: "",
      state: "",
      zip: "",
      email: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<PdncSearchDTO[]>([]);
  const [checkedSearchResults, setCheckedSearchResults] = useState<number[]>(
    [],
  );
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);
  const [centralizedErrorMessage, setCentralizedErrorMessage] = useState("");

  const validateForm = (data: PdncSearchDTO): string | null => {
    if (data.isDNC && !data.fullPhone) {
      return "Phone number is required when 'Do Not Call' is checked.";
    }
    if (
      data.isDNM &&
      (!data.firstName ||
        !data.lastName ||
        !data.city ||
        !data.state ||
        !data.zip)
    ) {
      return "First Name, Last Name, City, State, and Zip are required when 'Do Not Mail' is checked.";
    }
    return null;
  };

  const handleSearch = async (searchCriteria: PdncSearchDTO) => {
    try {
      setIsLoading(true);
      searchCriteria.fullPhone = searchCriteria.fullPhone.replace(/\D/g, "");
      const apiUrl = `main/dncdnm/search?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.POST, searchCriteria);
      setIsLoading(false);

      setSearchResults(response);
      setShowSearchResults(true);
    } catch (error) {
      console.error("Error searching:", error);
      // Handle error
    }
  };

  const handleAdd = async (addToMaster = false) => {
    const data = getValues();
    data.fullPhone = data.fullPhone.replace(/\D/g, "");
    const formValidationMessage = validateForm(data);
    if (formValidationMessage) {
      setCentralizedErrorMessage(formValidationMessage);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 10000);
      return;
    }

    try {
      setIsLoading(true);
      const apiUrl = `main/dncdnm/add?projId=${projectId}&campaignId=${campaignId}&addToMaster=${addToMaster}`;
      await callApi(apiUrl, HttpMethod.POST, data);
      onActionComplete();
      setShowSearchResults(false);
      setSearchResults([]);
      setCheckedSearchResults([]);
      reset();
    } catch (error) {
      console.error("Error adding data:", error);
      setCentralizedErrorMessage(
        error instanceof Error ? error.message : "An unknown error occurred",
      );
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 10000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    reset();
    setShowSearchResults(false);
    setCheckedSearchResults([]);
    setSearchResults([]);
  };

  const handleCheckAll = () => {
    setCheckedSearchResults(
      searchResults.map((result) => result.projDNCId ?? 0),
    );
  };

  const handleUncheckAll = () => {
    setCheckedSearchResults([]);
  };

  const handleSearchResultsChecked = (
    projDNCId: number,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      setCheckedSearchResults((prev) => [...prev, projDNCId]);
    } else {
      setCheckedSearchResults((prev) => prev.filter((id) => id !== projDNCId));
    }
  };

  const handleRemoveChecked = async () => {
    try {
      setIsLoading(true);
      const apiUrl = `main/dncdnm/remove?projId=${projectId}&campaignId=${campaignId}`;
      await callApi(apiUrl, HttpMethod.POST, checkedSearchResults);
      setSearchResults((prev) =>
        prev.filter(
          (result) => !checkedSearchResults.includes(result.projDNCId ?? 0),
        ),
      );
      if (searchResults.length === 0) setShowSearchResults(false);
      setCheckedSearchResults([]);
      onActionComplete();
    } catch (error) {
      console.error("Error removing items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || access === undefined) {
    return <Loading />;
  }

  return (
    <div className="card mt-4">
      <div className="card-header">
        {access.Select}
        {access.Select && "Search"}
        {access.Add && (access.Select ? " & " : "") + "Add"} to Project's
        Databases
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(handleSearch)}>
          <div>
            <label className="pe-3">
              <input
                type="checkbox"
                className="form-check-input"
                {...register("isDNC")}
              />
              <span className="ps-2">Do Not Call</span>
            </label>
            <label>
              <input
                type="checkbox"
                className="form-check-input"
                {...register("isDNM")}
              />
              <span className="ps-2">Do Not Mail</span>
            </label>
          </div>
          <div className="row">
            <div className="col">Phone/Fax:</div>
            <div className="col">
              <input
                {...register("fullPhone")}
                className="form-control"
                placeholder="Phone/Fax"
              />
            </div>
            <div className="col">E-mail:</div>
            <div className="col">
              <input
                {...register("email")}
                className="form-control"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">First Name:</div>
            <div className="col">
              <input
                {...register("firstName")}
                className="form-control"
                placeholder="First Name"
              />
            </div>
            <div className="col">Last Name:</div>
            <div className="col">
              <input
                {...register("lastName")}
                className="form-control"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">Address:</div>
            <div className="col">
              <input
                {...register("address")}
                className="form-control"
                placeholder="Address"
              />
            </div>
            <div className="col">Care of:</div>
            <div className="col">
              <input
                {...register("careOf")}
                className="form-control"
                placeholder="Care Of"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">City:</div>
            <div className="col">
              <input
                {...register("city")}
                className="form-control"
                placeholder="City"
              />
            </div>
            <div className="col">
              <select {...register("state")} className="w-100 form-select">
                <option value="">Select a State</option>
                {fetchedRegions.map((region, index) => (
                  <option key={index} value={region.state}>
                    {region.stateName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <input
                {...register("zip")}
                className="form-control"
                placeholder="Zip"
              />
            </div>
          </div>
          <div className="mt-3">
            {access?.Select && (
              <button type="submit" className="btn btn-primary me-3">
                Search
              </button>
            )}
            {access?.Add && (
              <>
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() => handleAdd(false)}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() => handleAdd(true)}
                >
                  Add to Project & Master
                </button>
              </>
            )}
            <button
              type="button"
              className="btn btn-primary me-3"
              onClick={handleClear}
            >
              Clear
            </button>
            {showMessage && <div>{centralizedErrorMessage}</div>}
          </div>
        </form>
        {showSearchResults && (
          <div className="mt-3">
            <h6>Search Results:</h6>
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      {access.Delete && <td>&nbsp;</td>}
                      <td>Phone/Fax#</td>
                      <td>First Name</td>
                      <td>Last Name</td>
                      <td>City</td>
                      <td>State</td>
                      <td>Zip</td>
                      <td>E-mail</td>
                      <td>Added</td>
                      <td>DB</td>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((result, index) => (
                      <tr key={index}>
                        {access.Delete && (
                          <td>
                            <input
                              type="checkbox"
                              checked={checkedSearchResults.includes(
                                result.projDNCId ?? index,
                              )}
                              onChange={(e) =>
                                handleSearchResultsChecked(
                                  result.projDNCId ?? index,
                                  e.target.checked,
                                )
                              }
                            />
                          </td>
                        )}
                        <td>{`${result.fullPhone}`}</td>
                        <td>{result.firstName || ""}</td>
                        <td>{result.lastName || ""}</td>
                        <td>{result.city || ""}</td>
                        <td>{result.state || ""}</td>
                        <td>{result.zip || ""}</td>
                        <td>{result.email || ""}</td>
                        <td>
                          {result.addedOn
                            ? new Date(result.addedOn).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                },
                              )
                            : ""}
                        </td>
                        <td>
                          {result.isDNC ? "DNC" : ""}{" "}
                          {result.isDNM ? "DNM" : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {access.Delete && (
              <div>
                <button
                  onClick={handleCheckAll}
                  className="btn btn-secondary me-3"
                >
                  Check All
                </button>
                <button
                  onClick={handleUncheckAll}
                  className="btn btn-secondary me-3"
                >
                  Uncheck All
                </button>
                <button
                  onClick={handleRemoveChecked}
                  className="btn btn-danger"
                >
                  Remove Checked from DB
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchDNCDatabase;
