import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./Login.module.css";
import { callApi, HttpMethod } from "src/services/apiService";
import { useLocation } from "react-router-dom";
import Loading from "src/components/Loading";
import { useForm } from "react-hook-form";
import SaveInProgress from "src/components/SaveInProgress";

type FormData = {
  newPassword: string;
  confirmPassword: string;
};

const NewPassword = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasExpired, setHasExpired] = useState<boolean>(true);
  const queryParams = new URLSearchParams(location.search);
  const requestValue = queryParams.get("request");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const password = watch("newPassword");

  useEffect(() => {
    async function ShowNewPassword() {
      try {
        let apiUrl = `PasswordReset/NewPassword?request=${requestValue}`;
        const fetchedData = await callApi(apiUrl, HttpMethod.GET);
        if (fetchedData.message === "expired") {
          setHasExpired(true);
        } else {
          if (fetchedData.message === "success") {
            setHasExpired(false);
            console.log("Success: ", fetchedData.message);
          }
        }
        setIsLoading(false);
      } catch (err) {
        const errorMessage = (err as Error).message;
        console.log(errorMessage);
        setIsLoading(false);
      }
    }

    ShowNewPassword();
  }, [requestValue]);

  const [errorMsg, setErrorMsg] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);

  const onSubmit = async (data: FormData) => {
    setSaveInProgress(true);
    try {
      const apiUrl = `PasswordReset/NewPassword?request=${encodeURIComponent(
        requestValue?.toString() || "",
      )}`;
      const response = await callApi(apiUrl, HttpMethod.POST, data);
      if (response.message === "success") {
        window.location.href = "/login";
      } else {
        setErrorMsg(response.message);
      }
    } catch (error) {
      alert("Failed to reset password. Please try again.");
      console.error("Failed to reset password:", error);
    }
    setSaveInProgress(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {hasExpired ? (
        <>
          <div className="pt-1 text-black pb-2">
            Your request to change password has expired.
          </div>
          <div>
            <Link
              to="/login/reset-your-password"
              className={`d-block {styles["login-btn"]}`}
            >
              Request a new link
            </Link>
            <Link to="/Login" className="d-block mt-3">
              Back to Login
            </Link>
          </div>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="text-black fw-bold mb-3">Reset Password</h3>
            {errorMsg && (
              <div className={`${styles["error-input-box"]} mb-4 p-1`}>
                {errorMsg}
              </div>
            )}
            <input
              {...register("newPassword", {
                required: "New Password is required",
                minLength: {
                  value: 10,
                  message: "Password must be at least 10 characters",
                },
              })}
              placeholder="New Password"
              type="password"
              autoComplete="new-password"
              className={`${styles["form-control"]} ${errors.newPassword ? styles["error-input-box"] : ""}`}
            />
            {errors.newPassword && (
              <p className={styles["error-message"]}>
                {errors.newPassword.message}
              </p>
            )}
            <input
              {...register("confirmPassword", {
                required: "Confirm Password is required",
                validate: (value) =>
                  value === password || "The passwords do not match",
              })}
              placeholder="Confirm Password"
              type="password"
              className={`${styles["form-control"]} ${errors.confirmPassword ? styles["error-input-box"] : ""}`}
            />
            {errors.confirmPassword && (
              <p className={styles["error-message"]}>
                {errors.confirmPassword.message}
              </p>
            )}
            <button className={styles["login-btn"]} type="submit">
              <SaveInProgress isVisible={saveInProgress} loadingText="" />
              {!saveInProgress && <>Continue</>}
            </button>
            <Link to="/Login" className="d-block mt-3">
              Back to Login
            </Link>
          </form>
        </>
      )}
    </>
  );
};

export default NewPassword;
