import { useEffect, useState } from "react";
import * as React from "react";

// Redux modules
import { useAppSelector } from "src/app/hooks";
import { selectAuth, setAuthInfo } from "src/features/authSlice";
import { useAppDispatch } from "src/app/hooks";

import LoadingToRedirect from "./LoadingToRedirect";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "./Loading";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated: isAuthenticatedInRedux } =
    useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [hasCookieBeenChecked, setHasCookieBeenChecked] = useState(false);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      // If the user is not logged in per redux
      // and the user has a loginId cookie,
      // see if it's a valid cookie
      if (!isAuthenticatedInRedux && !hasCookieBeenChecked) {
        const apiUrl = "users/IsAuthenticated?data=true";
        const response = await callApi(apiUrl, HttpMethod.GET);
        if (
          response &&
          (response.message.toLowerCase() ?? "") === "authorized"
        ) {
          const data = response.data;

          dispatch(
            setAuthInfo({
              acctId: data.acctId,
              acctName: data.acctName,
              username: data.username,
              fullName: data.fullName,
              quickScrub: data.quickScrub,
              role: data.role,
              isSysadmin: data.isSysadmin,
              loginId: data.loginId,
              csrfToken: data.csrfToken,
              hasTrainingFeature: data.hasTrainingFeature,
              homePage: data.homePage,
              acctMessage: data.acctMessage,
            }),
          );
        }
      }
      setHasCookieBeenChecked(true);
    };
    fetchAuthStatus();
  }, [
    setHasCookieBeenChecked,
    dispatch,
    isAuthenticatedInRedux,
    hasCookieBeenChecked,
  ]);

  if (isAuthenticatedInRedux) {
    return <>{children}</>;
  } else if (hasCookieBeenChecked) {
    return <LoadingToRedirect />;
  }
  return <Loading />;
};

export default PrivateRoute;
