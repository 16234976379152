import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import storageService from "src/services/storageService";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Abhi K: Do not store the last location when the user is in the not found page
    storageService.setItem("storeLastLocation", "true");
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <h4 className="alert-heading">Access Denied!</h4>
      <div style={{ color: "red" }}>
        You do not have access to this page. Please contact your administrator.
      </div>
      <button className="btn btn-secondary" onClick={goBack}>
        Go Back
      </button>
    </>
  );
};

export default NotFound;
