import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  FocusEvent,
  useRef,
} from "react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { DataReceiverProps } from "src/interfaces/DataReceiverProps";
import Warning from "src/components/Warning";
import Dropdown from "src/components/Dropdown";
import KeyValuePair from "src/interfaces/KeyValuePair";
import {
  IScrubOptions,
  IntelliScrubDbItem,
  scrubOptionsDbItem,
} from "src/interfaces/IScrubOptions";
import IFtcInfo from "src/interfaces/Main/IFtcInfo";
import SafeHtml from "src/components/SafeHtml";
import SaveInProgress from "src/components/SaveInProgress";
import ScrubOptionsAdvancedOutputConfigurator from "./ScrubOptionsAdvancedOutputConfigurator";
import ICpaSection, {
  getCaseInsensitiveValueFromCpaSection,
} from "src/interfaces/ICpaSection";

type ScrubOptionsProps = DataReceiverProps<string>;

interface IntelliScrubDbCheckboxStatus {
  checked: "yes" | "no";
  disabled: "yes" | "no";
}

const ScrubOptions: React.FC<ScrubOptionsProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [inheritSettings, setInheritSettings] = useState(false);
  const [access, setAccess] = useState<ICpaSection>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    setError,
    clearErrors,
  } = useForm({ mode: "onSubmit" });
  const scrubOptionsAdvancedOutputConfiguratorRef = useRef<{
    save: () => Promise<void>;
  } | null>(null);
  const [formData, setFormData] = useState<IScrubOptions>();
  const [regions, setRegions] = useState<KeyValuePair[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<KeyValuePair[]>([]);
  const [scrubOptionsDbItems, setScrubOptionsDbItem] = useState<
    scrubOptionsDbItem[]
  >([]);
  //
  const { projectId, campaignId, sourceComponent } = useUploadListContext();
  const [showAdvancedOutputConfigurator, setShowAdvancedOutputConfigurator] =
    useState(false);
  const section = sourceComponent;
  const [scrubAgainstAll, setScrubAgainstAll] = useState(true);
  const [scrubOffOverrides, setScrubOffOverrides] = useState(false);
  const [scrubUnselectAlert, setScrubUnselectAlert] = useState("");
  const [ftcUSInfo, setFtcUSInfo] = useState<IFtcInfo | null>(null);
  const [ftcCAInfo, setFtcCAInfo] = useState<IFtcInfo | null>(null);
  const [warningMessage, setWarningMessage] = useState("");
  const configLink = `/main/project-settings/${campaignId}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrubUnselectAlert(
      "We do not recommend you uncheck scrubbing against this database. \n" +
        "By doing so any numbers you scrub that are in this database\n" +
        "will be marked 'clean'.\n\n" +
        "It is preferable to let IntelliScrub determine which numbers\n" +
        "are clean based on your projects' exemption settings.",
    );
  }, []);

  const npoCallsPlacedByOptions = [
    { key: "", value: "Calls Placed: (Select One)" },
    { key: "Self", value: "by employee or volunteer" },
    { key: "Out", value: "by for-profit outsourcer" },
  ];

  const npoCallsSolicitOptions = [
    { key: "", value: "Calls Solicit:(Select One)" },
    { key: "Donations", value: "donations" },
    { key: "Sales", value: "sales of goods/services" },
  ];

  //Global Overlord Mode - Abhi K
  const allFormData = watch(); //Use this as a last resort if it still does not work

  const showDefaultsForAllProjects = watch("showDefaultsForAllProjects");
  const npoCallsChecked = watch("ScrubOptions.NpoCalls");
  const npoCallsSolicitValue = watch("ScrubOptions.NpoCallsSolicit");
  const npoCallsPlacedByValue = watch("ScrubOptions.NpoCallsPlacedBy");
  const scrubberMode = watch("ScrubOptions.ScrubberMode");
  const callsPlacedFromThisState = watch(
    "ScrubOptions.CallsPlacedFromThisState",
  );

  const toggleSAOCVisibility = useCallback(() => {
    setShowAdvancedOutputConfigurator((prevState) => !prevState);
  }, []);

  const handleInheritChange = () => {
    setInheritSettings((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchAndUpdateScrubOptions = async () => {
      try {
        setWarningMessage("");
        setIsLoading(true);
        const apiUrl = `Main/ScrubOptions/Get?section=${section}&projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        setFormData(response);
        if (response) {
          const exemptFrom2022MDRoboCallLaw =
            response.attributes.ExemptFrom2022MDRoboCallLaw === "1";
          const exemptFrom2022OKRoboCallLaw =
            response.attributes.ExemptFrom2022OKRoboCallLaw === "1";
          const exemptFrom2022FLRoboCallLaw =
            response.attributes.ExemptFrom2022FLRoboCallLaw === "1";
          reset({
            ...response,
            ScrubOptions: {
              ...response.ScrubOptions,
              MarkAllMDNumbersDNCUnlessEWC: exemptFrom2022MDRoboCallLaw
                ? false
                : response.ScrubOptions.MarkAllMDNumbersDNCUnlessEWC || false,
              MarkAllOKNumbersDNCUnlessEWC: exemptFrom2022OKRoboCallLaw
                ? false
                : response.ScrubOptions.MarkAllOKNumbersDNCUnlessEWC || false,
              MarkAllFLNumbersDNCUnlessEWC: exemptFrom2022FLRoboCallLaw
                ? false
                : response.ScrubOptions.MarkAllFLNumbersDNCUnlessEWC || false,
              HideDncReasonInQuickScrub:
                response.ScrubOptions.HideDncReasonInQuickScrub || false,
              DetailedOutput: response.ScrubOptions.DetailedOutput || false,
              SeperateLitigatorOutput:
                response.ScrubOptions.SeperateLitigatorOutput || false,
              ScrubOffNoCall: response.ScrubOptions.ScrubOffNoCall || false,
              DoNotScrubNDNCRecordsAddedWithin:
                response.ScrubOptions.DoNotScrubNdncrecordsAddedWithin || false,
              ScrubOffRecordsAddedWithinWeeks:
                response.ScrubOptions.ScrubOffRecordsAddedWithinWeeks || false,
              NpoCallsPlacedBy: response.ScrubOptions.npoCallsPlacedBy || "",
              NpoCallsSolicit: response.ScrubOptions.npoCallsSolicit || "",
              CallsPlacedFromThisState:
                response.ScrubOptions.CallsPlacedFromThisState || "",
              ScrubberMode: response.ScrubOptions.ScrubberMode || "0",
            },
            ScrubStatusCodes: response.ScrubStatusCodes || [],
            Regions: response.Regions || [],
            aggressiveModeSelected: response.aggressiveModeSelected || [],
            IntelliScrubbDbs: response.IntelliScrubbDbs || [],
          });
          setRegions(response.Regions || []);
          setFtcUSInfo(response.FtcInfo.US || null);
          setFtcCAInfo(response.FtcInfo.Canada || null);
          setScrubOptionsDbItem(response.scrubOptionsDb || []);
          setAccess(response.cpaSection);
          if (response.ScrubOptions) {
            setValue(
              "ScrubOptions.ScrubberMode",
              response.ScrubOptions.ScrubberMode || "0",
            );
            setScrubAgainstAll(response.ScrubOptions.ScrubAgainstAllAllowedDbs);
            setScrubOffOverrides(response.ScrubOptions.ScrubOffOverrides);
          }
          if (response.ScrubOptionsDb) {
            setValue(
              "ScrubOptionsDb",
              response.ScrubOptions.ScrubberMode || "0",
            );
          }
          const selectedRegionsFromResponse = response.Regions.filter(
            (region: KeyValuePair) =>
              response.aggressiveModeSelected.includes(region.key),
          );
          setSelectedRegions(selectedRegionsFromResponse);

          setInheritSettings(response.inheritRadioChecked);
        }
      } catch (error) {
        setWarningMessage(`Failed to fetch Scrub Options`);
        console.error("Failed to fetch Scrub Options:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (campaignId !== "") {
      fetchAndUpdateScrubOptions();
    }
  }, [projectId, campaignId, reset, setValue, section]);

  //Reset scrubber Mode to 0 if no state is selected and make it readonly
  useEffect(() => {
    if (callsPlacedFromThisState === "") {
      setValue("ScrubOptions.ScrubberMode", "0");
    }
    // Include setValue in the dependency array for useEffect
    // Add other dependencies as needed
  }, [callsPlacedFromThisState, setValue]);

  function getErrorMessage(errors: any, fieldName: string): string | undefined {
    const fieldNames = fieldName.split(".");
    let currentError = errors;

    for (const name of fieldNames) {
      if (!currentError || !currentError[name]) {
        return undefined;
      }
      currentError = currentError[name];
    }

    return currentError.message;
  }

  const getContentRepositoryValue = (key: string) => {
    const field = formData?.contentRepository.find(
      (field) => field.key.trim() === key.trim(),
    );

    return field?.value || null;
  };

  const callsPlacedFromThisStateOptions = useMemo(() => {
    return [
      { key: "", value: "" },
      ...regions,
      { key: "OO", value: "Outside US States" },
    ];
  }, [regions]);

  const getUnselectedRegions = () => {
    return regions.filter(
      (region) =>
        !selectedRegions.some((selected) => selected.value === region.value),
    );
  };

  const handleAddSelected = (selectedValues: string[]) => {
    const regionsToAdd = regions.filter((region) =>
      selectedValues.includes(region.key),
    );
    const updatedSelectedRegions = [...selectedRegions, ...regionsToAdd].sort(
      (a, b) => a.value.localeCompare(b.value),
    );
    setSelectedRegions(updatedSelectedRegions);
  };

  const handleRemoveSelected = (selectedValues: string[]) => {
    const updatedSelectedRegions = selectedRegions
      .filter((region) => !selectedValues.includes(region.key))
      .sort((a, b) => a.value.localeCompare(b.value));

    setSelectedRegions(updatedSelectedRegions);
  };

  const isDBsTypeRecords = useMemo(() => {
    // Function to chunk the array
    const chunkArray = (
      array: IntelliScrubDbItem[],
      size: number,
    ): IntelliScrubDbItem[][] => {
      const chunkedArray: IntelliScrubDbItem[][] = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
      }
      return chunkedArray;
    };

    // First filter to get only records of type 'S', then chunk them
    const recordsOfTypeS =
      (formData?.intelliscrubDbs.filter(
        (record) => record.type === "S",
      ) as IntelliScrubDbItem[]) || [];
    return chunkArray(recordsOfTypeS, 3);
  }, [formData?.intelliscrubDbs]);

  const isDBtTypeRecords = useMemo(
    () =>
      formData?.intelliscrubDbs.filter((record) => record.type === "T") || [],
    [formData?.intelliscrubDbs],
  );

  const isDBpTypeRecords = useMemo(
    () =>
      formData?.intelliscrubDbs.filter((record) => record.type === "P") || [],
    [formData?.intelliscrubDbs],
  );

  const isDBfTypeRecords = useMemo(
    () =>
      formData?.intelliscrubDbs.filter((record) => record.type === "F") || [],
    [formData?.intelliscrubDbs],
  );

  const handleIntelliScrubbDbsCheckUncheckAll = (check: boolean): void => {
    document
      .querySelectorAll<HTMLInputElement>('input[name^="IntelliScrubbDbs."]')
      .forEach((checkbox) => {
        if (
          checkbox.name === "IntelliScrubbDbs.15" ||
          checkbox.name === "IntelliScrubbDbs.36"
        ) {
          return; // Do not change these 2!
        }
        if (!checkbox.disabled) {
          setValue(checkbox.name, check);
          //checkbox.checked = check;
        }
      });
  };

  const handleNpoChanges = () => {
    const npoCallsPlacedByValue = getValues("ScrubOptions.NpoCallsPlacedBy");
    const npoCallsSolicitValue = getValues("ScrubOptions.NpoCallsSolicit");

    const shouldDisableFccExemption =
      !(npoCallsPlacedByValue === "Out" && npoCallsSolicitValue === "Sales") ||
      inheritSettings;
    const shouldDisableNpoSalesExempt =
      !(
        npoCallsPlacedByValue === "Out" &&
        npoCallsSolicitValue === "Sales" &&
        !(npoCallsPlacedByValue === "Self" && npoCallsSolicitValue === "Sales")
      ) || inheritSettings;

    if (shouldDisableFccExemption) {
      setValue("ScrubOptions.FccExemptionForOutsourceSales", false);
    }
    if (shouldDisableNpoSalesExempt) {
      setValue("ScrubOptions.NpoSalesAreExemptFromIncomeTax", false);
    }
  };

  const handleScrubOffNoCallValuesBlur = (
    event: FocusEvent<HTMLInputElement>,
  ) => {
    const inputValue: string = event.target.value;
    const { formattedValue, hasInvalid } =
      formatAndValidateNoCallValues(inputValue);

    setValue("scrubOffNoCallValues", formattedValue);

    if (hasInvalid) {
      setError("ScrubOffNoCallValues", {
        type: "manual",
        message: "Contains invalid region key or area code.",
      });
    } else {
      clearErrors("ScrubOffNoCallValues");
    }
  };

  const formatAndValidateNoCallValues = (inputValue: string) => {
    if (!inputValue) return { formattedValue: "", hasInvalid: false };
    let formattedValue = inputValue.toUpperCase().replace(/,/g, " ");
    formattedValue = formattedValue.replace(/\s+/g, " ");
    const valuesArray = formattedValue.split(" ");

    let hasInvalid = false;
    const processedValues = valuesArray.map((value) => {
      const isValid =
        regions.some((region) => region.key.toUpperCase() === value) ||
        /^\d{3}$/.test(value);
      if (!isValid) hasInvalid = true;
      return isValid ? value : `*${value}*`;
    });

    return {
      formattedValue: processedValues.join(" "),
      hasInvalid,
    };
  };

  const saveScrubOptions = async (rawData: any) => {
    setSaveInProgress(true);
    // Convert ScrubOptions.ScrubberMode to a number
    //data.ScrubOptions.ScrubberMode = Number(data.ScrubOptions.ScrubberMode);
    //scrubOptionsDb
    setWarningMessage("");

    if (rawData.ScrubOptions.NpoCalls) {
      // Validate the dropdowns have a selection other than the default
      if (
        rawData.ScrubOptions.NpoCallsPlacedBy === "" ||
        rawData.ScrubOptions.NpoCallsSolicit === ""
      ) {
        setError("NpoCalls", {
          type: "manual",
          message: "Non-profit must make a selection in both items below.",
        });
        setWarningMessage("Non-profit must make a selection in both items.");
        setSaveInProgress(false);
        return; // Stop form submission// Stop form submission
      }
    }

    if (
      rawData.ScrubOptions.ScrubOffNoCall &&
      !rawData.scrubOffNoCallValues.trim()
    ) {
      setError("scrubOffNoCallValues", {
        type: "manual",
        message:
          "You have selected to scrub off no call states/areacodes however you have not entered any no call areacodes or states.",
      });
      setWarningMessage(
        "You have selected to scrub off no call states/areacodes however you have not entered any no call areacodes or states.",
      );
      setSaveInProgress(false);
      return; // To prevent form submission as there is an error
    }

    const {
      attributes,
      CrossAccount,
      crtcOrgId,
      crtcAreacodeSetId,
      ftcOrgId,
      intelliscrubDbs,
      IntelliScrubbDbs,
      inheritRadioChecked,
      contentRepository,
      currentAreacodeSetId,
      Regions,
      ScrubStatusCodes,
      scrubOptionsDb,
      ...data
    } = rawData;
    const selectedDbIds = formData?.intelliscrubDbs
      .map((dbRecord) => {
        const isChecked = getValues(`IntelliScrubbDbs.${dbRecord.id}`);
        if (isChecked) {
          return { dbId: dbRecord.id };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (data.ScrubOptions) {
      data.ScrubOptions.ScrubOffOverrides =
        data.ScrubOptions.ScrubOffOverrides === "false";
      data.ScrubOptions.DefaultAreacode =
        typeof data.ScrubOptions.DefaultAreacode === "string" &&
        data.ScrubOptions.DefaultAreacode.trim() !== ""
          ? parseInt(data.ScrubOptions.DefaultAreacode.trim(), 10)
          : null;
      if (isNaN(data.ScrubOptions.DefaultAreacode)) {
        data.ScrubOptions.DefaultAreacode = null;
      }
      data.ScrubOptions.ScrubAgainstAllAllowedDbs = scrubAgainstAll;
      data.ScrubOptions.SecondaryPdncProjId = data.SecondaryPdncProjCheckbox
        ? formData?.SecondaryPdncProjId
        : null;
    }

    const modifiedData = {
      ...data,
      scrubOptionsDb: selectedDbIds,
      aggressiveModeSelected: selectedRegions.map((region) => region.key),
      InhertRadioChecked: inheritSettings ? true : false,
    };

    try {
      //use XUnitTest for testing when debugging the WebAPI in Visual Studio to avoid locking the tables
      //const apiUrl = `ScrubOptions/Save?projId=${projectId}&campaignId=${campaignId}&XUnitTest=true`;
      const apiUrl = `main/ScrubOptions/Save?projId=${projectId}&campaignId=${campaignId}`;
      const method = HttpMethod.POST;
      const body = modifiedData;

      const response = await callApi(apiUrl, method, body);
      if (response && response.message) {
        if (response.message.toLowerCase() !== "success") {
          setWarningMessage("Failed to save Scrub settings.");
        }
      }
      console.log(
        "Settings saved successfully. Now saving advanced output configurator settings.",
      );
      if (scrubOptionsAdvancedOutputConfiguratorRef.current) {
        await scrubOptionsAdvancedOutputConfiguratorRef.current.save();
      }
    } catch (error) {
      setWarningMessage("Failed to save Scrub settings.");
      console.error("Failed to save settings:", error);
    }
    setSaveInProgress(false);
  };

  const handleLAOKCheckboxChange = () => {
    alert(
      "This is only recommended for advanced users. Email support@dnc.com for questions.",
    );
  };

  const getIntelliScrubCheckboxStatus = (
    record: IntelliScrubDbItem,
  ): IntelliScrubDbCheckboxStatus => {
    if (!scrubOptionsDbItems || !access) {
      return { checked: "no", disabled: "yes" }; // no data available to parse
    }

    let recordId = record.id;
    let recordStatus = record.status;
    let cpaLitigator = getCaseInsensitiveValueFromCpaSection(
      access,
      `scrubLitigator`,
    );
    let cpaScrubAccess = getCaseInsensitiveValueFromCpaSection(
      access,
      `scrub${record.shortName}`,
    );

    if (record.type === "P") {
      if (
        (recordId === 44 && !cpaLitigator) ||
        (recordId !== 44 && !cpaScrubAccess)
      ) {
        recordStatus = 0;
      }
    }

    switch (recordStatus) {
      case -1:
      case 0:
      case 4:
        return { checked: "no", disabled: "yes" }; // Disallow or block
      case 3:
        return { checked: "yes", disabled: "yes" }; // Require
      case 1:
        // Allow as optional
        const isChecked = scrubOptionsDbItems.some(
          (option) => option.dbId === record.id,
        );
        return { checked: isChecked ? "yes" : "no", disabled: "no" };
      case 2:
        // Scrub by default
        const isScrubbedByDefault = scrubOptionsDbItems.some(
          (option) => option.dbId === record.id,
        );
        return { checked: isScrubbedByDefault ? "yes" : "no", disabled: "no" };
      default:
        console.error("Unrecognized status:", record.status);
        return { checked: "no", disabled: "yes" };
    }
  };

  if (!formData) {
    return <Loading />;
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h2>Scrub Options</h2>
          <Warning message={warningMessage} />
          <form onSubmit={handleSubmit(saveScrubOptions)}>
            <div className="row">
              <div className="col-9">
                {showDefaultsForAllProjects ? (
                  <div className="bg-warning p-3">
                    <b>
                      NOTE: Since this is the Default Campaign of the Master
                      Project these settings will be the defaults for all of
                      your Projects and Campaigns.
                    </b>
                  </div>
                ) : null}
                <div
                  style={{
                    display: showDefaultsForAllProjects ? "none" : "block",
                  }}
                >
                  <label>
                    <input
                      type="radio"
                      name="scrubOption"
                      className="me-2"
                      value="default"
                      checked={inheritSettings}
                      onChange={handleInheritChange}
                    />
                    <b>Use the default Scrub Options</b>
                  </label>
                  <div id="noticeOfSettingsChange" style={{ display: "none" }}>
                    This page will show correct settings after you save.
                  </div>
                  <br />
                  <label>
                    <input
                      type="radio"
                      name="scrubOption"
                      className="me-2"
                      value="custom"
                      checked={!inheritSettings}
                      onChange={handleInheritChange}
                    />
                    <b>
                      Configure specific Scrub Option on this {section} below
                    </b>
                  </label>
                </div>
              </div>
              <div className="col-3 text-end">
                <SaveInProgress isVisible={saveInProgress} />
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="btn btn-primary"
                >
                  <span className="bi-save"></span>
                  <br />
                  Save
                </button>
              </div>
            </div>

            <hr />
            <div>
              <span className="label">
                Default area code (allows QuickScrubbing 7-digit phone numbers):
              </span>
              <input
                type="text"
                className="form-control"
                {...register("ScrubOptions.DefaultAreacode", {
                  onChange: (e) => {
                    // Allow only digits
                    const value = e.target.value.replace(/\D/g, "");
                    setValue("ScrubOptions.DefaultAreacode", value);
                  },
                })}
                disabled={inheritSettings}
                maxLength={3}
                pattern="\d*"
                size={3}
                style={{ width: "75px" }}
              />
            </div>
            <hr />
            <div>
              <h6>Maryland Phone Numbers:</h6>
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.MarkAllMDNumbersDNCUnlessEWC")}
                  disabled={
                    inheritSettings ||
                    formData?.attributes?.ExemptFrom2023MDRoboCallLaw === "1"
                  }
                />
                <span className="ps-2">
                  After 1/1/24: Mark all Maryland numbers DNC unless there is
                  Express Written Consent / Permission EBR or a Sale EBR when
                  scrubbing-against National DNC
                </span>
                {formData?.attributes?.ExemptFrom2023MDRoboCallLaw === "1" && (
                  <div className="pt-3 input-group-text text-start">
                    <div className="row">
                      <div className="col-12">
                        The option to apply this rule is disabled as the
                        ExemptFrom2022MDRoboCallLaw attribute is set to on
                        account.
                      </div>

                      <div className="col-12">
                        Contact{" "}
                        <a href="mailto:support@dnc.com">support@dnc.com</a> to
                        change.
                      </div>
                    </div>
                  </div>
                )}
              </label>
            </div>
            <hr />
            <div>
              <h6>Oklahoma Phone Numbers:</h6>
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.MarkAllOKNumbersDNCUnlessEWC")}
                  disabled={
                    inheritSettings ||
                    formData?.attributes?.ExemptFrom2022OKRoboCallLaw === "1"
                  }
                />
                <span className="ps-2">
                  Mark all Oklahoma numbers DNC unless there is Express Written
                  Consent / Permission EBR or a Sale EBR when scrubbing-against
                  National DNC or the Oklahoma State DNC list
                </span>
                {formData?.attributes?.ExemptFrom2022OKRoboCallLaw === "1" && (
                  <div className="pt-3 input-group-text text-start">
                    <div className="row">
                      <div className="col-12">
                        The option to apply this rule is disabled as the
                        ExemptFrom2022OKRoboCallLaw attribute is set to on
                        account.
                      </div>

                      <div className="col-12">
                        Contact{" "}
                        <a href="mailto:support@dnc.com">support@dnc.com</a> to
                        change.
                      </div>
                    </div>
                  </div>
                )}
              </label>
            </div>
            <hr />
            <div>
              <h6>Florida Phone Numbers:</h6>
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.MarkAllFLNumbersDNCUnlessEWC")}
                  disabled={
                    inheritSettings ||
                    formData?.attributes?.ExemptFrom2021FLRoboCallLaw === "1"
                  }
                />
                <span className="ps-2">
                  Mark all Florida numbers DNC unless there is Express Written
                  Consent / Permission EBR or a Sale EBR when scrubbing-against
                  National DNC or the Florida State DNC list
                </span>
                {formData?.attributes?.ExemptFrom2021FLRoboCallLaw === "1" && (
                  <div className="pt-3 input-group-text text-start">
                    <div className="row">
                      <div className="col-12">
                        The option to apply this rule is disabled as the
                        ExemptFrom2022FLRoboCallLaw attribute is set to on
                        account.
                      </div>

                      <div className="col-12">
                        Contact{" "}
                        <a href="mailto:support@dnc.com">support@dnc.com</a> to
                        change.
                      </div>
                    </div>
                  </div>
                )}
              </label>
            </div>
            <hr />
            <div>
              <h6>QuickScrub Options:</h6>
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.HideDncReasonInQuickScrub")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Hide Reason / DB Matched for Do-Not-Call Numbers in QuickScrub
                  Results
                </span>
              </label>
            </div>
            <hr />
            <div>
              <div className="row">
                <div className="col-6">
                  <h6>Output Options:</h6>
                </div>
                <div className="col-6 text-end">
                  <button
                    onClick={toggleSAOCVisibility}
                    type="button"
                    style={{
                      background: "none",
                      border: "none",
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      padding: "0",
                    }}
                  >
                    {showAdvancedOutputConfigurator
                      ? "Hide Advanced Output Configurator"
                      : "Show Advanced Output Configurator"}
                  </button>
                </div>
              </div>
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.DetailedOutput")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Include detailed output in scrub results
                </span>
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.SeperateLitigatorOutput")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Separate litigator DNC records from other DNC records. Only
                  applies if litigator scrub is purchased.
                </span>
              </label>
            </div>
            {showAdvancedOutputConfigurator && (
              <>
                <ScrubOptionsAdvancedOutputConfigurator
                  ref={scrubOptionsAdvancedOutputConfiguratorRef}
                />
              </>
            )}
            <hr />
            <div>
              <h6>No-Call States / Area Codes:</h6>
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.ScrubOffNoCall")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Always block all phone numbers in these No-Call States and
                  Area Codes regardless of exemption, EBR status or whether the
                  number is even in any Do Not Call database (use state
                  abbreviations and separate with spaces or commas):
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                {...register("scrubOffNoCallValues")}
                onBlur={handleScrubOffNoCallValuesBlur}
                onChange={() => clearErrors("scrubOffNoCallValues")}
                disabled={inheritSettings}
              />
              {errors.ScrubOffNoCallValues && (
                <p className="error">
                  {errors.ScrubOffNoCallValues.message?.toString()}
                </p>
              )}
            </div>
            <hr />
            <div>
              <h6>Special Handling for Recently Added Phone Numbers:</h6>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    {...register(
                      "ScrubOptions.DoNotScrubNdncrecordsAddedWithin",
                    )}
                    disabled={inheritSettings}
                  />
                  <span className="ps-2">
                    Do not scrub against phone numbers added to the National Do
                    Not Call database in the last
                    <input
                      type="number"
                      className="form-control"
                      {...register(
                        "ScrubOptions.DoNotScrubNdncrecordsAddedWithinNum",
                        {
                          valueAsNumber: true,
                          validate: (value) =>
                            value <= 25 ||
                            "This value cannot be greater than 25",
                        },
                      )}
                      disabled={inheritSettings}
                    />
                    {getErrorMessage(
                      errors,
                      "ScrubOptions.DoNotScrubNdncrecordsAddedWithinNum",
                    ) && (
                      <p className="error-message">
                        {getErrorMessage(
                          errors,
                          "ScrubOptions.DoNotScrubNdncrecordsAddedWithinNum",
                        )}
                      </p>
                    )}
                  </span>
                </label>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    {...register(
                      "ScrubOptions.ScrubOffRecordsAddedWithinWeeks",
                    )}
                    disabled={inheritSettings}
                  />
                  <span className="ps-2">
                    Always Scrub off phone numbers added to DNC databases within
                    the last{" "}
                    <input
                      type="text"
                      className="form-control"
                      {...register(
                        "ScrubOptions.ScrubOffRecordsAddedWithinWeeksNum",
                      )}
                      disabled={inheritSettings}
                    />{" "}
                    weeks despite my exempt status.
                  </span>
                  <br />
                  <input
                    type="radio"
                    {...register("ScrubOptions.ScrubOffOverrides")}
                    value="false"
                    checked={scrubOffOverrides === false}
                    onChange={() => setScrubOffOverrides(false)}
                    disabled={inheritSettings}
                  />
                  &nbsp; Does <b>NOT</b> apply to EBR Overrides
                  <br />
                  <input
                    type="radio"
                    {...register("ScrubOptions.ScrubOffOverrides")}
                    value="true"
                    checked={scrubOffOverrides === true}
                    onChange={() => setScrubOffOverrides(true)}
                    disabled={inheritSettings}
                  />
                  &nbsp; Does apply to EBR Overrides (i.e. EBR status will be
                  disregared)
                </label>
              </div>
            </div>
            <hr />
            <div>
              <h6>Campaign Details:</h6>

              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.CallsPlacedBySeller")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Calls placed by seller itself, not outsourced (for Indiana
                  exemptions to apply)
                </span>
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.CallTimeLegalPrizeStateRulesOnly")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Sales calls to Rhode Island: the prospective purchaser will
                  receive a prize/gift.
                </span>
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.OptOutEbroverrideCallingWindow")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Ignore a state's EBR exemptions to their calling window.
                </span>
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  {...register("ScrubOptions.OptOutEbroverrideStateOfEmerg")}
                  disabled={inheritSettings}
                />
                <span className="ps-2">
                  Ignore a state's EBR exemptions to a state of emergency
                  declaration.
                </span>
              </label>
            </div>
            <hr />
            <div className="input-group-text row text-start">
              <div className="col-12">
                <label>
                  <input
                    type="checkbox"
                    {...register("ScrubOptions.NpoCalls")}
                    disabled={inheritSettings}
                  />
                  <span className="ps-2">Non-Profit</span>
                </label>
              </div>
              {npoCallsChecked && (
                <>
                  <div className="col-12">
                    {errors.NpoCalls && (
                      <p className="error">
                        {errors.NpoCalls.message?.toString()}
                      </p>
                    )}
                    <select
                      {...register("ScrubOptions.NpoCallsPlacedBy")}
                      className="form-control"
                      onChange={handleNpoChanges}
                    >
                      {npoCallsPlacedByOptions.map((option, index) => (
                        <option key={index} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    <select
                      {...register("ScrubOptions.NpoCallsSolicit")}
                      className="form-control"
                      onChange={handleNpoChanges}
                    >
                      {npoCallsSolicitOptions.map((option, index) => (
                        <option key={index} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <label>
                      <input
                        type="checkbox"
                        {...register(
                          "ScrubOptions.NpoSalesAreExemptFromIncomeTax",
                        )}
                        disabled={
                          (!(
                            npoCallsPlacedByValue === "Out" &&
                            npoCallsSolicitValue === "Sales"
                          ) &&
                            !(
                              npoCallsPlacedByValue === "Self" &&
                              npoCallsSolicitValue === "Sales"
                            )) ||
                          inheritSettings
                        }
                      />
                      <span className="ps-2">
                        Sales are exempt from Federal Income Tax & Wisconsin
                        Sales Tax
                      </span>
                    </label>
                  </div>
                  <div className="col-12">
                    <label>
                      <input
                        type="checkbox"
                        {...register(
                          "ScrubOptions.FccExemptionForOutsourceSales",
                        )}
                        disabled={
                          !(
                            npoCallsPlacedByValue === "Out" &&
                            npoCallsSolicitValue === "Sales"
                          ) || inheritSettings
                        }
                      />
                      <span className="text-wrap ps-2">
                        <b>Aggressive:</b> Ignore FTC's non-recognition of
                        Outsourced Sales as exempt and follow FCC rules instead.
                        (Note: If you call only within your state which you
                        specify below and your state gives you an exemption to
                        Outsourced Sales, you will be considered exempt anyway
                        due to lack of FTC jurisdiction)
                      </span>
                    </label>
                  </div>
                </>
              )}
              <div>
                <label>
                  Calls placed from this state:
                  <span className="text-wrap ps-2">
                    <Dropdown
                      name="ScrubOptions.CallsPlacedFromThisState"
                      options={callsPlacedFromThisStateOptions}
                      register={register}
                      disabled={inheritSettings}
                    />
                    {!callsPlacedFromThisState && (
                      <div>
                        <b>
                          Note: Once you choose a state you will be able to
                          select one of the other scrubbing modes below.
                        </b>
                      </div>
                    )}
                  </span>
                </label>
              </div>

              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className="pe-2" valign="top">
                        Scrubber <br />
                        Mode:
                      </td>
                      <td className="pe-2" valign="top">
                        <select
                          id="scrubberModeSelect"
                          {...register("ScrubOptions.ScrubberMode")}
                          disabled={
                            inheritSettings || callsPlacedFromThisState === ""
                          }
                          size={3}
                        >
                          <option value="0" className="p-1 m-0">
                            Ultra-conservative
                          </option>
                          <option value="1" className="p-1 m-0">
                            Conservative
                          </option>
                          <option value="2" className="p-1 m-0">
                            Aggressive
                          </option>
                        </select>
                      </td>
                      <td className="pe-2" valign="top">
                        {(scrubberMode === "0" || scrubberMode === 0) && (
                          <SafeHtml
                            html={getContentRepositoryValue(
                              "m-scrub-opt_ultraConsvExpl",
                            )}
                          />
                        )}
                        {(scrubberMode === "1" || scrubberMode === 1) && (
                          <div className="text-wrap">
                            <SafeHtml
                              html={getContentRepositoryValue(
                                "m-scrub-opt_ConsvExpl",
                              )}
                            />
                          </div>
                        )}
                        {(scrubberMode === "2" || scrubberMode === 2) && (
                          <div className="text-wrap">
                            <SafeHtml
                              html={getContentRepositoryValue(
                                "m-scrub-opt_AggressiveExpl",
                              )}
                            />
                          </div>
                        )}

                        {(scrubberMode === "1" ||
                          scrubberMode === 1 ||
                          scrubberMode === "2" ||
                          scrubberMode === 2) && (
                          <div id="regionSelection">
                            <div className="row">
                              <div className="col-4 pe-2">
                                <h6>Available Regions</h6>
                                <select
                                  multiple
                                  size={5}
                                  disabled={inheritSettings}
                                  onChange={(e) =>
                                    handleAddSelected(
                                      Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value,
                                      ),
                                    )
                                  }
                                >
                                  {getUnselectedRegions().map((region) => (
                                    <option key={region.key} value={region.key}>
                                      {region.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-4 text-center align-middle">
                                <button
                                  className="btn btn-primary"
                                  disabled={inheritSettings}
                                  onClick={() =>
                                    handleAddSelected(
                                      getUnselectedRegions().map(
                                        (region) => region.value,
                                      ),
                                    )
                                  }
                                >
                                  {">>"}
                                </button>
                                <br />
                                <button
                                  className="btn btn-primary"
                                  disabled={inheritSettings}
                                  onClick={() =>
                                    handleRemoveSelected(
                                      selectedRegions.map(
                                        (region) => region.value,
                                      ),
                                    )
                                  }
                                >
                                  {"<<"}
                                </button>
                              </div>
                              <div className="col-4">
                                <h6>Selected Regions</h6>
                                <select
                                  multiple
                                  size={5}
                                  disabled={inheritSettings}
                                  onChange={(e) =>
                                    handleRemoveSelected(
                                      Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value,
                                      ),
                                    )
                                  }
                                >
                                  {selectedRegions.map((region) => (
                                    <option key={region.key} value={region.key}>
                                      {region.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <h6>Scrub Against Intelliscrub Database Settings:</h6>
            <div className="row">
              <div className="col-12">
                <label>
                  <input
                    type="radio"
                    {...register("ScrubOptions.ScrubAgainstAllAllowedDbs")}
                    value="true"
                    checked={scrubAgainstAll === true}
                    onChange={() => setScrubAgainstAll(true)}
                    disabled={inheritSettings}
                  />
                  &nbsp; Scrub-against all databases set to 'Scrub By Default'
                  or 'Require' in IntelliScrub settings (RECOMMENDED)
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    {...register("ScrubOptions.ScrubAgainstAllAllowedDbs")}
                    value="false"
                    checked={scrubAgainstAll === false}
                    onChange={() => setScrubAgainstAll(false)}
                    disabled={inheritSettings}
                  />
                  &nbsp; Specify databases to scrub-against below
                </label>
              </div>
            </div>
            <hr />
            <h6>Scrub Against These IntelliScrub Databases:</h6>
            <div className="card">
              <div className="card-header">
                Intelliscrub Databases [$ = Premium DB]
              </div>
              <div className="card-body">
                <div className="card-text">
                  <div className="row">
                    {isDBsTypeRecords.map((chunk, chunkIndex) => (
                      <div className="col" key={`chunk-${chunkIndex}`}>
                        {chunk.map((record) => {
                          const checkboxStatus =
                            getIntelliScrubCheckboxStatus(record);
                          return (
                            <div key={`IntelliScrubbDbs-${record.id}`}>
                              <label>
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  {...register(`IntelliScrubbDbs.${record.id}`)}
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      alert(scrubUnselectAlert);
                                    }
                                  }}
                                  disabled={
                                    inheritSettings ||
                                    record.status === -1 ||
                                    record.status === 0 ||
                                    record.status === 4 ||
                                    record.status === 3 ||
                                    scrubAgainstAll === true ||
                                    checkboxStatus.disabled === "yes"
                                  }
                                  defaultChecked={
                                    checkboxStatus.checked === "yes"
                                  }
                                />
                                {record.name}
                                {record.isPremium && "$"}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>

                  <br />
                  <div className="row">
                    {isDBtTypeRecords.map((record) => {
                      const checkboxStatus =
                        getIntelliScrubCheckboxStatus(record);
                      return (
                        <div
                          className="col-12"
                          key={`IntelliScrubbDbs-${record.id}`}
                        >
                          <label>
                            <input
                              type="checkbox"
                              className="me-2"
                              {...register(`IntelliScrubbDbs.${record.id}`)}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  alert(scrubUnselectAlert);
                                }
                              }}
                              disabled={
                                inheritSettings ||
                                scrubAgainstAll === true ||
                                checkboxStatus.disabled === "yes"
                              }
                              defaultChecked={checkboxStatus.checked === "yes"}
                            />
                            {record.name}
                            {record.isPremium && access && (
                              <>
                                {(record.id === 44 &&
                                  !access?.scrubLitigator) ||
                                (record.id !== 44 &&
                                  getCaseInsensitiveValueFromCpaSection(
                                    access,
                                    `scrub${record.shortName}`,
                                  ) === false)
                                  ? " $ (Contact account administrator to purchase)"
                                  : " $"}
                              </>
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <div className="row">
                    {isDBpTypeRecords.map((record) => {
                      const checkboxStatus =
                        getIntelliScrubCheckboxStatus(record);
                      return (
                        <div
                          className="col-12"
                          key={`IntelliScrubbDbs-${record.id}`}
                        >
                          <label>
                            <input
                              type="checkbox"
                              className="me-2"
                              {...register(`IntelliScrubbDbs.${record.id}`)}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  alert(scrubUnselectAlert);
                                }
                              }}
                              disabled={
                                inheritSettings ||
                                scrubAgainstAll === true ||
                                checkboxStatus.disabled === "yes"
                              }
                              defaultChecked={checkboxStatus.checked === "yes"}
                            />
                            {record.name}
                            {record.isPremium && access && (
                              <>
                                {(record.id === 44 &&
                                  !access?.scrubLitigator) ||
                                (record.id !== 44 &&
                                  getCaseInsensitiveValueFromCpaSection(
                                    access,
                                    `scrub${record.shortName}`,
                                  ) === false)
                                  ? " $ (Contact account administrator to purchase)"
                                  : " $"}
                              </>
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <br />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleIntelliScrubbDbsCheckUncheckAll(true)}
                  disabled={scrubAgainstAll === true || inheritSettings}
                >
                  Check All
                </button>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleIntelliScrubbDbsCheckUncheckAll(false)}
                  disabled={scrubAgainstAll === true || inheritSettings}
                >
                  Uncheck All
                </button>
              </div>
            </div>
            <hr />
            <h6>National DNC Subscription:</h6>
            <div className="row">
              {(() => {
                const recordWithId15 = isDBfTypeRecords.find(
                  (record) => record.id === 15,
                );

                if (recordWithId15) {
                  const checkboxStatus =
                    getIntelliScrubCheckboxStatus(recordWithId15);
                  return (
                    <>
                      <div className="col-12">
                        <label>
                          <input
                            type="checkbox"
                            className="me-2"
                            {...register(`IntelliScrubbDbs.15`)}
                            disabled={
                              inheritSettings ||
                              recordWithId15.status === -1 ||
                              recordWithId15.status === 0 ||
                              recordWithId15.status === 4 ||
                              recordWithId15.status === 3
                            }
                            onChange={(e) => {
                              if (!e.target.checked) {
                                alert(scrubUnselectAlert);
                              }
                            }}
                            defaultChecked={checkboxStatus.checked === "yes"}
                          />
                          Scrub against this National DNC account:
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-3">States Included</div>
                              <div className="col-9">
                                Additional Area Codes Included
                              </div>
                            </div>
                          </div>
                          <div className="card-body card-text">
                            {ftcUSInfo?.OrgId ? (
                              <div>
                                {ftcUSInfo?.EntireCountry ? (
                                  <div className="row">
                                    <div className="col-12">
                                      Entire {ftcUSInfo.CountryName}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-3">
                                      {ftcUSInfo?.States}
                                    </div>
                                    <div className="col-9">
                                      <div className="row">
                                        {ftcUSInfo?.AreaCodes}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row">
                                <div>
                                  {access?.scrubUSA ? (
                                    <div className="col-12">
                                      <i
                                        className="bi bi-exclamation-triangle-fill me-2"
                                        style={{ color: "red" }}
                                      ></i>
                                      Warning: This project does not have a
                                      valid National Registry Subscription.{" "}
                                      <a href={configLink} className="link">
                                        CLICK HERE
                                      </a>{" "}
                                      to configure this project.
                                    </div>
                                  ) : access?.scrubCanada ? (
                                    <div className="col-12">
                                      <i
                                        className="bi bi-exclamation-triangle-fill me-2"
                                        style={{ color: "red" }}
                                      ></i>
                                      Warning: This project does not have a
                                      valid Canada National Registry
                                      Subscription.{" "}
                                      <a href={configLink} className="link">
                                        CLICK HERE
                                      </a>{" "}
                                      to configure this project.
                                    </div>
                                  ) : (
                                    <p>
                                      (Contact account administrator to obtain
                                      access to this database.)
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
                return null;
              })()}
            </div>
            <hr />
            <h6>Canada National DNC List Subscription:</h6>
            <div className="row">
              {(() => {
                const recordWithId36 = isDBfTypeRecords.find(
                  (record) => record.id === 36,
                );
                if (recordWithId36) {
                  const checkboxStatus =
                    getIntelliScrubCheckboxStatus(recordWithId36);
                  return (
                    <>
                      <div className="col-12">
                        <label>
                          <input
                            type="checkbox"
                            className="me-2"
                            disabled={
                              inheritSettings ||
                              recordWithId36.status === -1 ||
                              recordWithId36.status === 0 ||
                              recordWithId36.status === 4 ||
                              recordWithId36.status === 3
                            }
                            {...register(`IntelliScrubbDbs.36`)}
                            defaultChecked={checkboxStatus.checked === "yes"}
                          />
                          Scrub against this Canada registration account:
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-6">Provinces Included</div>
                              <div className="col-6">
                                Additional Area Codes Included
                              </div>
                            </div>
                          </div>
                          <div className="card-body card-text">
                            {ftcCAInfo?.OrgId ? (
                              <div>
                                {ftcCAInfo?.EntireCountry ? (
                                  <div className="row">
                                    <div className="col-12">
                                      All of {ftcCAInfo.CountryName}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-3">
                                      {ftcCAInfo?.States}
                                    </div>
                                    <div className="col-9">
                                      <div className="row">
                                        {ftcCAInfo?.AreaCodes}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row">
                                {formData.CanScrubCa ? (
                                  <div className="col-12">
                                    <i
                                      className="bi bi-exclamation-triangle-fill me-2"
                                      style={{ color: "red" }}
                                    ></i>
                                    Warning: This project does not have a valid
                                    Canada DNC list Subscription.{" "}
                                    <a href={configLink} className="link">
                                      CLICK HERE
                                    </a>{" "}
                                    to configure this project.
                                  </div>
                                ) : (
                                  <div className="col-12">
                                    (Contact account administrator to obtain
                                    access to this database.)
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
                return null;
              })()}
            </div>
            <hr />
            <h6>Scrub Against these Project-specific DNC databases:</h6>
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">Project Code</div>
                  <div className="col-6">Project Name</div>
                </div>
              </div>
              <div className="card-body card-text">
                <div className="row">
                  <div className="col-1">
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={true}
                      disabled={true}
                      {...register(`PrimaryPdncProjId`)}
                    />
                  </div>
                  <div className="col-5">{formData?.PrimaryPdncProjId}</div>
                  <div
                    className="col-6"
                    dangerouslySetInnerHTML={{
                      __html: formData?.PrimaryPdncProjName || "",
                    }}
                  ></div>
                </div>
                {formData?.SecondaryPdncProjId &&
                  formData.SecondaryPdncProjId !==
                    formData.PrimaryPdncProjId && (
                    <div className="row">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="me-2"
                          checked={Boolean(
                            formData?.ScrubOptions.SecondaryPdncProjId,
                          )}
                          disabled={inheritSettings}
                          {...register(`ScrubOptions.SecondaryPdncProjId`)}
                        />
                      </div>
                      <div className="col-5">
                        {formData?.SecondaryPdncProjId}
                      </div>
                      <div className="col-6">
                        {formData?.SecondaryPdncProjName}
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <hr />
            {formData?.attributes?.AllowProjectsToExemptLAOK === "1" && (
              <div>
                <h6>Lousiana and Oklahoma Advanced Settings:</h6>
                <label>
                  <input
                    type="checkbox"
                    {...register("ScrubOptions.OverrideLAAndDisallow")}
                    disabled={
                      inheritSettings ||
                      formData?.attributes?.AllowProjectsToExemptLAOK !== "1"
                    }
                    onChange={handleLAOKCheckboxChange}
                  />
                  <span className="ps-2">
                    Mark project or campaign <b>exempt</b> from scrubbing
                    against the Louisiana State DNC database.
                  </span>
                </label>
                <label>
                  <input
                    type="checkbox"
                    {...register("ScrubOptions.OverrideOKAndDisallow")}
                    disabled={
                      inheritSettings ||
                      formData?.attributes?.AllowProjectsToExemptLAOK !== "1"
                    }
                    onChange={handleLAOKCheckboxChange}
                  />
                  <span className="ps-2">
                    Mark project or campaign <b>exempt</b> from scrubbing
                    against the Oklahoma State DNC database.
                  </span>
                </label>
              </div>
            )}
            {formData &&
              parseInt(
                formData.attributes.AllowAbilityToSelectLitigatorTypes || "0",
              ) === 1 && (
                <>
                  <hr />
                  <h6>Litigator Scrub Advanced Settings:</h6>
                  <div className="card">
                    <div className="card-header">Database Selection</div>
                    <div className="card-body card-text">
                      <label>
                        <input
                          type="checkbox"
                          {...register("ScrubOptions.ScrubTcpalitigatorOnly")}
                          disabled={inheritSettings}
                          onChange={() =>
                            alert(
                              "This is only recommended for advanced users. We recommend most customers scrub-against our full litigator list.",
                            )
                          }
                        />
                        <span className="ps-2">
                          Only scrub known TCPA plaintiffs or known TCPA
                          pre-litigation demands
                        </span>
                      </label>
                    </div>
                  </div>
                </>
              )}
            <br />
            <button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="btn btn-primary"
            >
              <span className="bi-save me-2"></span>Save
            </button>
            <SaveInProgress isVisible={saveInProgress} />
            <Warning message={warningMessage} />
            {!isValid && (
              <span className="error-message">
                Please correct the errors before saving.
              </span>
            )}
          </form>
          <div className="d-none">
            {/* Show the watched data for debugging purposes only */}
            <pre>{JSON.stringify(allFormData, null, 2)}</pre>
          </div>
        </>
      )}
    </div>
  );
};

export default ScrubOptions;
