import { createContext, useContext } from "react";
import * as React from "react";

interface LayoutContextType {
  heading: string;
  tagline: string;
  PageTitle: string;
  setHeading: React.Dispatch<React.SetStateAction<string>>;
  setTagline: React.Dispatch<React.SetStateAction<string>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

const LayoutContext = createContext<LayoutContextType>({
  heading: "",
  tagline: "",
  PageTitle: "",
  setHeading: () => {}, // No-op function as default
  setTagline: () => {}, // No-op function as default
  setPageTitle: () => {}, // No-op function as default
});

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContext;
