import React from "react";

// AK 10/21/2024: Colors are currently hard coded to keep the look and feel consistent across the whole app.
interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div
      className="progress my-3"
      style={{
        height: `20px`,
        backgroundColor: "#e9ecef",
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: `${progress}%`,
          backgroundColor: "#0d6efd",
          transition: "width 0.4s ease-in-out",
        }}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {progress}%
      </div>
    </div>
  );
};

export default ProgressBar;
