// Logs a user in using redux toolkit query
// Differs from other API calls in that it uses redux toolkit query
// all our other API calls use a custom function callApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_APP_API_URL,
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body: { acctId: string; username: string; password: string }) => {
        return {
          url: "/users/authenticate",
          method: "POST",
          body,
        };
      },
    }),
    logoutUser: builder.mutation({
      query: () => {
        return {
          url: "/users/logout",
          method: "POST",
          credentials: "include", // Ensure cookies are sent with the request
        };
      },
    }),
  }),
});

export const { useLoginUserMutation, useLogoutUserMutation } = authApi;
