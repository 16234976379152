import { useState, useEffect, useCallback, useMemo } from "react";
import * as React from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import Definitions from "./Templates/Definitions";
import CommercialRegistration from "./Templates/CommercialRegistration";
import DoNotCallList from "./Templates/DoNotCallList";
import TimeRestriction from "./Templates/TimeRestriction";
import HolidayRestrictions from "./Templates/HolidayRestrictions";
import IDDisclosure from "./Templates/IDDisclosure";
import ContractCancellation from "./Templates/ContractCancellation";
import NPOSolicitationExemptionsChart from "./Templates/NPOSolicitationExemptionsChart";
import DNCExemptions from "./Templates/DNCExemptions";
import PrerecCalls from "./Templates/PrerecCalls";
import PermissionToContinue from "./Templates/PermissionToContinue";
import NoRebuttal from "./Templates/NoRebuttal";
import CallMonitoring from "./Templates/CallMonitoring";
import SimpleTopic from "./Templates/SimpleTopic";
import Loading from "src/components/Loading";

interface RegionsProps {
  topics: { topicId: number; topicName: string }[];
  regions: { state: string; stateName: string }[];
}

const Regions: React.FC<RegionsProps> = ({ topics, regions }) => {
  const memoizedTopics = useMemo(() => topics, [topics]);
  const memoizedRegions = useMemo(() => regions, [regions]);
  const [dataByTopic, setDataByTopic] = useState<
    Record<string, Record<number, string[]>>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchedTopics, setFetchedTopics] = useState<
    { topicId: number; topicName: string }[]
  >([]);
  const [fetchedRegions, setFetchedRegions] = useState<
    { state: string; stateName: string }[]
  >([]);

  const [
    isCheckedIncludePreviousYearHolidays,
    setIsCheckedIncludePreviousYearHolidays,
  ] = useState(false);

  const [isCheckedHideStatesWithNoRules, setIsCheckedHideStatesWithNoRules] =
    useState(false);

  const getRegulations = useCallback(async () => {
    // Check if the regions or topics have actually changed
    const haveRegionsChanged =
      memoizedRegions.some(
        (region) =>
          !fetchedRegions.some((fetched) => fetched.state === region.state),
      ) || memoizedRegions.length !== fetchedRegions.length;

    const newTopics = memoizedTopics.filter(
      (topic) =>
        !fetchedTopics.some((fetched) => fetched.topicId === topic.topicId),
    );

    // Proceed only if there are actual changes
    if (haveRegionsChanged || newTopics.length > 0) {
      try {
        setIsLoading(true);
        const allData: Record<string, Record<number, any[]>> = {
          ...dataByTopic,
        };
        const regionCsv = memoizedRegions.map((r) => r.state).join(",");

        for (const topic of memoizedTopics) {
          const apiUrl = `ComplianceGuide/Regulations?regions=${regionCsv}&topic=${topic.topicId}`;
          const dataStrForTopic = await callApi(apiUrl, HttpMethod.GET);

          if (!dataStrForTopic || typeof dataStrForTopic !== "string") {
            continue;
          }

          let recordsForTopic;
          try {
            recordsForTopic = JSON.parse(dataStrForTopic);
          } catch (e) {
            console.error(
              "Error parsing JSON for topic:",
              topic.topicId,
              "Error:",
              e,
            );
            continue;
          }

          for (const record of recordsForTopic) {
            if (
              ("complianceRule" in record && record.complianceRule === null) ||
              ("overview" in record && record.overview === null) ||
              ("regRule" in record && record.regRule === null) ||
              ("callTimeWeekday" in record && record.callTimeWeekday === null)
            ) {
              continue;
            }

            const region = record.state;
            if (!allData[region]) allData[region] = {};
            if (!allData[region][topic.topicId])
              allData[region][topic.topicId] = [];
            allData[region][topic.topicId].push(record);
          }
        }

        setDataByTopic(allData);
        setFetchedTopics(memoizedTopics);
        setFetchedRegions(memoizedRegions);
        setIsLoading(false);
        setIsCheckedIncludePreviousYearHolidays(false);
      } catch (e) {
        console.error("Error fetching data:", e);
        setIsLoading(false);
      }
    }
  }, [
    memoizedRegions,
    memoizedTopics,
    fetchedTopics,
    fetchedRegions,
    dataByTopic,
  ]);

  useEffect(() => {
    getRegulations();
  }, [getRegulations]);

  const handleIncludePreviousYearHolidayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      setIsCheckedIncludePreviousYearHolidays(isChecked);
    },
    [],
  );

  const handleHideStatesWithNoRulesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      setIsCheckedHideStatesWithNoRules(isChecked);
      getRegulations();
    },
    [getRegulations],
  );

  const renderTopicComponent = (
    topic: { topicId: number; topicName: string },
    region: string,
    data: string[] | "",
  ) => {
    let commonProps;

    if (data === "") {
      commonProps = {
        topic: topic.topicName,
        region: region,
        data: "",
      };
    } else {
      commonProps = {
        topic: topic.topicName,
        region: region,
        data: JSON.stringify(data),
      };
    }

    switch (topic.topicId) {
      case 1:
        return (
          <CommercialRegistration
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 2:
        return (
          <IDDisclosure
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 3:
        return (
          <ContractCancellation
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 4:
        return (
          <TimeRestriction
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 5:
        return (
          <DoNotCallList
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 6:
        return (
          <NPOSolicitationExemptionsChart
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 7:
        return (
          <Definitions
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 9:
        return (
          <PermissionToContinue
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 10:
        return (
          <NoRebuttal
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 13:
        return (
          <DNCExemptions
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 14:
        return (
          <CallMonitoring
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 16:
        return (
          <PrerecCalls
            {...commonProps}
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      case 17:
        return (
          <HolidayRestrictions
            region={region}
            isCheckedIncludePreviousYearHolidays={
              isCheckedIncludePreviousYearHolidays
            }
            isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
          />
        );
      default:
        if (topic.topicId > 17) {
          return (
            <SimpleTopic
              {...commonProps}
              isCheckedHideStatesWithNoRules={isCheckedHideStatesWithNoRules}
            />
          );
        }
        return <div>Unknown topic</div>;
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {topics.some((s) => s.topicId === 17) ? (
        <div className="row">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <div className="row">
              <div
                className="col-md-12 d-flex justify-content-end"
                style={{ marginBottom: 10 }}
              >
                <label style={{ marginRight: 10 }}>
                  <input
                    type="checkbox"
                    className="me-1 form-check-input"
                    checked={isCheckedHideStatesWithNoRules}
                    onChange={handleHideStatesWithNoRulesChange}
                  />
                  Do not show states with no rules
                </label>
                <label>
                  <input
                    type="checkbox"
                    className="me-1 form-check-input"
                    checked={isCheckedIncludePreviousYearHolidays}
                    onChange={handleIncludePreviousYearHolidayChange}
                  />
                  Include Holidays One Year Prior
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <div className="row">
              <div
                className="col-md-12 d-flex justify-content-end"
                style={{ marginBottom: 10 }}
              >
                <label style={{ marginRight: 10 }}>
                  <input
                    type="checkbox"
                    className="me-1 form-check-input"
                    checked={isCheckedHideStatesWithNoRules}
                    onChange={handleHideStatesWithNoRulesChange}
                  />
                  Do not show states with no rules
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="regulatory-guide-section">
        {regions &&
          [...regions].map((region) => (
            <div
              className="row"
              key={region.state}
              id={`conditionalRender_${region.state}`}
              style={{ display: "none" }}
            >
              <div className="regulatory-title-subheader-section">
                <h5 className="regulatory-title">{region.stateName}</h5>
              </div>
              {topics &&
                [...topics]
                  .sort((a, b) => a.topicId - b.topicId)
                  .map((topic) => (
                    <div className="regulatory-rules-info" key={topic.topicId}>
                      {renderTopicComponent(
                        topic,
                        region.state,
                        dataByTopic[region.state]?.[topic.topicId] || "",
                      )}
                    </div>
                  ))}
            </div>
          ))}
      </div>
    </>
  );
};

export default Regions;
