import { useCallback, useEffect, useState } from "react";
import { useLayoutContext } from "src/contexts/LayoutContext";
import { useForm, Controller } from "react-hook-form";
import { validationSchema } from "./litigatorScrubSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { callApi, HttpMethod } from "src/services/apiService";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import styles from "./Index.module.css";
import { Grid } from "react-loader-spinner";

type ScrubbedResponse = {
  cleanResult: ScrubbedResult[];
  dncResult: ScrubbedResult[];
  message: string;
};

type ScrubbedResult = {
  areaCode: string;
  local: string;
  scubResult: string;
};

export const Index = () => {
  const { setHeading, setTagline } = useLayoutContext();
  const { acctId } = useAppSelector(selectAuth);
  const [scrubbedResponse, setScrubbedResponse] = useState<ScrubbedResponse>();
  const [showResultModal, setShowResultModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const formatPhoneNumbers = (input: string) => {
    const lines = input.split("\n");
    return lines
      .map((line) => {
        if (line.length === 10 && /^[0-9]{10}$/.test(line)) {
          return `(${line.substring(0, 3)}) ${line.substring(
            3,
            6,
          )}-${line.substring(6)}`;
        }
        return line; // Keep empty lines or lines that don't match the pattern unchanged
      })
      .join("\n");
  };

  const onSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);
      const phoneNumbers: string[] = data.phoneNumbers
        .split("\n")
        .map((str: string) => str.replace(/\D/g, ""))
        .filter((num: string) => num.length === 10);
      const request = {
        acctId: acctId,
        phoneNumbers: phoneNumbers,
      };
      try {
        const apiUrl = "ComplianceGuide/ScrubLitigator";
        const response: ScrubbedResponse = await callApi(
          apiUrl,
          HttpMethod.POST,
          request,
          true,
        );
        console.log(response);
        setScrubbedResponse(response);
        setShowResultModal(true);
        reset();
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching ScrubLitigator", error);
      }
    },
    [reset],
  );
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Litigator QuickScrub");
      setTagline(
        "Scrub your phone numbers against our list of known TCPA and FDCPA litigators. You can scrub up to 100 phone numbers per month. If you need to scrub more or need bulk or API scrubbing access and do not have a Scrub Account,  please contact support@dnc.com",
      );
    }
  }, [setHeading, setTagline]);

  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes! Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [isDirty]);

  const handleFormatPhoneNumber = (phoneNumber: string) => {
    const formattedPhone = `(${phoneNumber.substring(
      0,
      3,
    )}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    return formattedPhone;
  };

  const handleDisplayResult = useCallback(
    (data: ScrubbedResult[], title: string, titleColor: string) => {
      return (
        <div style={{ padding: 10 }}>
          <span style={{ color: titleColor, fontSize: 20, fontWeight: "bold" }}>
            {title}
          </span>
          {data.map((data: ScrubbedResult, index: number) => (
            <div key={index}>
              {handleFormatPhoneNumber(`${data.areaCode}${data.local}`)}
            </div>
          ))}
        </div>
      );
    },
    [],
  );
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <div className="loader-container">
            <Grid
              height="60"
              width="60"
              color="#0eb0e5"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass=""
              visible={isLoading}
            />
          </div>
        )}
        <div className="row">
          <Controller
            name="phoneNumbers"
            control={control}
            defaultValue={""}
            render={({ field: { onChange, ...field } }) => (
              <div className="col-md-12" style={{ paddingRight: 20 }}>
                <label>{"Phone Number(s)"}</label>
                <textarea
                  {...field}
                  onChange={(e) => {
                    const formatted = formatPhoneNumbers(e.target.value);
                    onChange(formatted);
                  }}
                  className={`form-control ${
                    errors.phoneNumbers ? "is-invalid" : ""
                  }`}
                  placeholder="Enter one number per line"
                  style={{ height: 300 }}
                ></textarea>
                {errors.phoneNumbers && (
                  <p>{errors.phoneNumbers.message?.toString()}</p>
                )}
              </div>
            )}
          />
        </div>
        <button type="submit" className="mt-3 btn btn w-100 btn-primary">
          <i className="bi bi-save"></i> Scrub
        </button>
      </form>
      {showResultModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={showResultModal ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2>{"Litigator Scrub Results"}</h2>

                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowResultModal(false)}
                  title="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className={styles.container}>
                  {scrubbedResponse?.cleanResult &&
                    handleDisplayResult(
                      scrubbedResponse?.cleanResult,
                      "Clean",
                      "#5cb85c",
                    )}
                  {scrubbedResponse?.dncResult &&
                    handleDisplayResult(
                      scrubbedResponse?.dncResult,
                      "Litigator DNC",
                      "#d9534f",
                    )}
                  {scrubbedResponse?.cleanResult === null &&
                    scrubbedResponse?.dncResult === null && (
                      <span>{scrubbedResponse.message}</span>
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowResultModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showResultModal ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}
    </>
  );
};
export default Index;
