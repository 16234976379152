import { useState, useEffect } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useLayoutContext } from "src/contexts/LayoutContext";

const Index = () => {
  const [faqEntries, setFaqEntries] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedQuestionIds, setExpandedQuestionIds] = useState<number[]>([]);

  const { setHeading, setTagline } = useLayoutContext();

  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("FAQs");
      setTagline("Performance Marketing Frequently Asked Questions");
    }
  }, [setHeading, setTagline]);

  useEffect(() => {
    async function fetchFAQs() {
      try {
        let apiUrl = `FAQEntries/GetFAQEntries`;
        const fetchedData = await callApi(apiUrl, HttpMethod.GET);
        setFaqEntries(fetchedData);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError((err as Error).message || "An error occurred");
      }
    }

    fetchFAQs();
  }, []);

  const toggleAnswer = (id: number) => {
    setExpandedQuestionIds((prevState) =>
      prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id],
    );
  };

  let currentCategory = "";

  if (isLoading) return <Loading />;
  if (error) return <p>Error: {error}</p>;
  //{expandedQuestionIds.includes(entry.id) && <p>{entry.answer}</p>}
  return (
    <div>
      {faqEntries.map((entry) => {
        const isNewCategory = currentCategory !== entry.category;
        currentCategory = entry.category; // update current category for next iteration
        return (
          <div className="mb-1" key={entry.id}>
            {isNewCategory && <h2 className="mt-3">{entry.category}</h2>}
            <div className="row" onClick={() => toggleAnswer(entry.id)}>
              <span className="text-decoration-underline cursor-pointer">
                {entry.question}
              </span>
            </div>
            {expandedQuestionIds.includes(entry.id) && (
              <div
                className="bg-color-alt mb-2 mt-2 p-1 rounded remove-bg-color-on-inner-span"
                dangerouslySetInnerHTML={{ __html: "" + entry.answer }}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Index;
