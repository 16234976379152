import { useState, useEffect } from "react";
import * as React from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";

interface EBRNotifySettings {
  projId?: string;
  active?: boolean;
  toAddress?: string;
  inquiryWarningDays?: number;
  salesWarningDays?: number;
  bunchUpInquiryWarning?: number;
  bunchUpSalesWarning?: number;
  nextInquiryDate?: string | null;
  nextSaleDate?: string | null;
  canEBRN: boolean;
}

const EBRNotify = () => {
  const [eBRNotifySettings, setEBRNotifySettings] =
    useState<EBRNotifySettings | null>(null);
  const [initialEBRNotifySettings, setInitialEBRNotifySettings] =
    useState<EBRNotifySettings | null>(null);
  const [isModified, setIsModified] = useState(false);
  const { projectId, campaignId } = useUploadListContext();

  useEffect(() => {
    const fetchEBRNotifyData = async () => {
      try {
        const apiUrl = `Main/Projects/GetEBRNotifySettings?projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);

        setEBRNotifySettings(response);
        setInitialEBRNotifySettings(response);

        setIsModified(false);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };
    fetchEBRNotifyData();
  }, [projectId, campaignId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const target = e.target as HTMLInputElement;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setEBRNotifySettings((prevSettings) => {
      if (prevSettings === null) return null;
      const newSettings = {
        ...prevSettings,
        [name]: value,
      };
      setIsModified(!isEqual(newSettings, initialEBRNotifySettings));
      return newSettings;
    });
  };

  const isEqual = (
    obj1: EBRNotifySettings | null,
    obj2: EBRNotifySettings | null,
  ) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const saveSettings = async () => {
    if (eBRNotifySettings === null) {
      console.error("No settings to save");
      return;
    }

    try {
      const apiUrl = `Main/Projects/UpdateEBRNotifySettings`;
      const method = HttpMethod.POST;
      const body = eBRNotifySettings;

      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
      setInitialEBRNotifySettings(eBRNotifySettings);
      setIsModified(false);
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  if (!eBRNotifySettings) {
    return <Loading />;
  }

  return (
    <>
      <h6 style={{ display: "inline", paddingRight: 10 }}>EBRNotify™</h6>
      <small>(Existing Business Relationship databases)</small>
      <div className="mt-3">
        EBRMaster™ allows IntelliScrub™ to take advantage of your existing
        business relationship status with customers while scrubbing call lists.
        Further, with EBRNotify™, you will receive an email notification on a
        regular basis informing you of existing business relationships that are
        coming close to their expiration date. This Project's default scrub mode
        and pre-recorded settings are used to determine the expiration date.
        <br />
        <div className="card bg-warning p-3 m-3">
          This Project's EBRNotify results are NOT scrubbed against Wireless or
          any other IntelliScrub databases.
        </div>
      </div>
      <label>
        <input
          type="checkbox"
          name="active"
          checked={eBRNotifySettings.active || false}
          disabled={eBRNotifySettings.canEBRN === false}
          onChange={handleChange}
        />
        <span className="ps-2">Enable EBRNotify™ for this Project</span>
      </label>
      <hr />
      <h6>E-mail EBR Expiration Notifications To:</h6>
      <input
        type="text"
        className="form-control"
        name="toAddress"
        value={eBRNotifySettings.toAddress || ""}
        onChange={handleChange}
      />
      <hr />
      <h6>
        Miscellaneous Settings:{eBRNotifySettings.inquiryWarningDays || ""}
      </h6>
      <table className="table">
        <thead>
          <tr>
            <td>Setting Description</td>
            <td>Inquiries</td>
            <td>Sales</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>How many days in advance to send expiration warning for:</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="inquiryWarningDays"
                value={eBRNotifySettings.inquiryWarningDays || ""}
                onChange={handleChange}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="salesWarningDays"
                value={eBRNotifySettings.salesWarningDays || ""}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>
              To reduce volume of individual EBR Notifications they can be
              bunched up:
            </td>
            <td>
              <select
                name="bunchUpInquiryWarning"
                id="bunchUpInquiryWarning"
                value={eBRNotifySettings?.bunchUpInquiryWarning || ""}
                onChange={handleChange}
              >
                <option value="">Never</option>
                <option value="0">Daily</option>
                <option value="1">Mon & Wed</option>
                <option value="2">Weekly</option>
                <option value="3">Every 10 Days</option>
                <option value="4">Bi-Weekly</option>
              </select>
            </td>
            <td>
              <select
                name="bunchUpSalesWarning"
                id="bunchUpSalesWarning"
                value={eBRNotifySettings?.bunchUpSalesWarning || ""}
                onChange={handleChange}
              >
                <option value="">Never</option>
                <option value="0">Daily</option>
                <option value="1">Mon & Wed</option>
                <option value="2">Weekly</option>
                <option value="3">Every 10 Days</option>
                <option value="4">Bi-Weekly</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        className="btn btn-primary me-3"
        onClick={saveSettings}
        disabled={!isModified}
      >
        Save
      </button>
    </>
  );
};

export default EBRNotify;
