import IsNotLoggedIn from "./IsNotLoggedIn";
import Guide from "./Guide";
import { useAppSelector } from "src/app/hooks";

const Index = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isAuthenticated);

  if (isLoggedIn === null) return <p>Loading...</p>;
  return <div>{isLoggedIn ? <Guide /> : <IsNotLoggedIn />}</div>;
};

export default Index;
