import { useState } from "react";
import * as React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
Modal.setAppElement("#root");

interface Props {
  label: string;
  url: string;
  labelName?: string;
}

export const ModalLink = React.memo(({ label, url, labelName }: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  if (url === "") return null;

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <>
      {labelName ? (
        <Link to="#" onClick={handleLinkClick}>
          <span style={{ color: "var(--bs-body-color)" }}>{labelName}</span>
          {label}
        </Link>
      ) : (
        <Link to="#" onClick={handleLinkClick}>
          {label}
        </Link>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={{
          overlay: {
            zIndex: 1000,
            overflowY: "auto",
          },
          content: {
            width: "95%",
            height: "95%",
            margin: "auto",
            padding: "5px",
            overflow: "hidden",
          },
        }}
      >
        {url && (
          <iframe
            src={url}
            title="External Content"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "auto",
            }}
          ></iframe>
        )}
        <button
          onClick={() => setModalOpen(false)}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
          className="btn btn-danger"
        >
          <i className="bi bi-x fs-7"></i>
        </button>
      </Modal>
    </>
  );
});
