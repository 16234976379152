import { useState, useEffect } from "react";
import * as React from "react";
import moment from "moment-timezone";

interface LocalTimeProps {
  UTCOffset: string;
}

const LocalTime: React.FC<LocalTimeProps> = ({ UTCOffset }) => {
  const [localTime, setLocalTime] = useState<string>("");

  useEffect(() => {
    const updateLocalTime = () => {
      const currentTimeUtc = moment.utc();
      const timeWithOffset = currentTimeUtc
        .clone()
        .add(parseInt(UTCOffset), "minutes");
      let timeString = timeWithOffset.format("hh:mm a");
      if (timeString.startsWith("0")) {
        timeString = timeString.substring(1);
      }
      setLocalTime(timeString);
    };

    updateLocalTime();
    const intervalId = setInterval(updateLocalTime, 60000);

    return () => clearInterval(intervalId);
  }, [UTCOffset]);

  return <span>{localTime}</span>;
};

export default LocalTime;
