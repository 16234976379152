import * as React from "react";
import "./SaveInProgress.css";

type SaveInProgressProps = {
  isVisible: boolean;
  loadingText?: string; // Optional text prop
};

const SaveInProgress: React.FC<SaveInProgressProps> = ({
  isVisible,
  loadingText,
}) => {
  if (!isVisible) return null;

  return (
    <>
      <div className="spinner-overlay"></div>
      <div className="spinner-container">
        <div className="spinner"></div>
        {/* Conditionally render text if it exists */}
        {loadingText && <div className="spinner-text">{loadingText}</div>}
      </div>
    </>
  );
};

export default SaveInProgress;
