const CrossAccountInfo = () => {
  return (
    <>
      <h1>Cross Account Info</h1>
      <div>
        You have been granted access to the Nexxa Group account. You can
        navigate to projects and campaigns in the account via the tree to the
        left.
      </div>
    </>
  );
};

export default CrossAccountInfo;
