import IframeComponent from "src/components/IframeComponent";
import { useEffect } from "react";
import { useLayoutContext } from "src/contexts/LayoutContext";

const Index = () => {
  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("");
      setTagline("");
    }
  }, [setHeading, setTagline]);
  return (
    <IframeComponent
      src="https://core.dncscrub.com/app/litigator/search/index"
      title="Litigator Research"
      style={{
        marginLeft: "-45px",
        paddingTop: "-175px",
        marginTop: "-25px",
        width: "100%",
        height: "85vh",
        border: "0",
      }}
      allowFullScreen={true}
    />
  );
};

export default Index;
