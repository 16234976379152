import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import Cookies from "js-cookie";

export interface AuthState {
  acctId: string | null;
  acctName: string | null;
  username: string | null;
  fullName: string | null;
  quickScrub: boolean | null;
  role: string | null;
  isSysadmin: boolean | null;
  loginId: string | null;
  csrfToken: string | null;
  isAuthenticated: boolean | null;
  isLoading: boolean;
  hasTrainingFeature: boolean;
  homePage: string | null;
  acctMessage: string | null;
  theme: string | null;
}

const initialState: AuthState = {
  acctId: null,
  acctName: null,
  username: null,
  fullName: null,
  quickScrub: null,
  role: null,
  isSysadmin: null,
  loginId: null,
  csrfToken: null,
  isAuthenticated: false,
  isLoading: false,
  hasTrainingFeature: false,
  homePage: null,
  acctMessage: null,
  theme: null,
};

let domainName: string = ".dncscrub.com";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (
      state,
      action: PayloadAction<{
        acctId: string;
        acctName: string;
        username: string;
        fullName: string;
        quickScrub: boolean;
        role: string;
        isSysadmin: boolean;
        loginId: string;
        csrfToken: string;
        hasTrainingFeature: boolean;
        homePage: string;
        acctMessage: string;
      }>,
    ) => {
      state.acctId = action.payload.acctId;
      state.acctName = action.payload.acctName;
      state.username = action.payload.username;
      state.fullName = action.payload.fullName;
      state.quickScrub = action.payload.quickScrub;
      state.role = action.payload.role;
      state.isSysadmin = action.payload.isSysadmin;
      state.loginId = action.payload.loginId;
      state.csrfToken = action.payload.csrfToken;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.hasTrainingFeature = action.payload.hasTrainingFeature;
      state.homePage = action.payload.homePage;
      state.acctMessage = action.payload.acctMessage;

      Cookies.set("acctId", action.payload.acctId, {
        domain: domainName,
        path: "/",
      });
      Cookies.set("loginId", action.payload.loginId, {
        domain: domainName,
        path: "/",
      });
    },
    logout: (state) => {
      state.acctId = null;
      state.acctName = null;
      state.username = null;
      state.fullName = null;
      state.quickScrub = null;
      state.role = null;
      state.isSysadmin = null;
      state.loginId = null;
      state.csrfToken = null;
      state.hasTrainingFeature = false;
      state.isAuthenticated = false;
      state.homePage = null;
      state.acctMessage = null;

      // Do not remove it for now as this is used to prefil the account id in the login page
      //Cookies.remove("acctId", { domain: domainName, path: "/" });
      Cookies.remove("loginId", { domain: domainName, path: "/" });
      Cookies.remove("csrfToken", { domain: domainName, path: "/" });
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    setUnauthenticated: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    updateAccountInfo: (
      state,
      action: PayloadAction<{ acctId: string; acctName: string }>,
    ) => {
      state.acctId = action.payload.acctId;
      state.acctName = action.payload.acctName;
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const {
  setAuthInfo,
  logout,
  startLoading,
  setUnauthenticated,
  setIsAuthenticated,
  updateAccountInfo,
  setTheme,
} = authSlice.actions;

export default authSlice.reducer;
