import { ReactNode, createContext, useContext, useState } from "react";
import * as React from "react";
import { ITreeNode } from "src/interfaces/ITreeNode";

interface UploadListContextProps {
  parentProjectId: string;
  setParentProjectId: React.Dispatch<React.SetStateAction<string>>;
  parentProjectName: string;
  setParentProjectName: React.Dispatch<React.SetStateAction<string>>;
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
  newProjectName: string;
  setNewProjectName: React.Dispatch<React.SetStateAction<string>>;
  campaignName: string;
  setCampaignName: React.Dispatch<React.SetStateAction<string>>;
  projectId: string;
  setProjectId: React.Dispatch<React.SetStateAction<string>>;
  campaignId: string;
  setCampaignId: React.Dispatch<React.SetStateAction<string>>;
  acctId: string;
  setAcctId: React.Dispatch<React.SetStateAction<string>>;
  activeComponent: string;
  setActiveComponent: (component: string) => void;
  sourceComponent: string;
  setSourceComponent: React.Dispatch<React.SetStateAction<string>>;
  updateActiveComponent: (component: string, source?: string) => void;
  treeData: ITreeNode[];
  setTreeData: React.Dispatch<React.SetStateAction<ITreeNode[]>>;
  validCampaignId: boolean;
  setValidCampaignId: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTree: boolean;
  setRefreshTree: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContext: UploadListContextProps = {
  parentProjectId: "",
  setParentProjectId: () => {},
  parentProjectName: "",
  setParentProjectName: () => {},
  projectName: "",
  setProjectName: () => {},
  newProjectName: "",
  setNewProjectName: () => {},
  campaignName: "",
  setCampaignName: () => {},
  projectId: "",
  setProjectId: () => {},
  campaignId: "",
  setCampaignId: () => {},
  acctId: "",
  setAcctId: () => {},
  activeComponent: "",
  setActiveComponent: () => {},
  sourceComponent: "",
  setSourceComponent: () => {},
  updateActiveComponent: () => {},
  treeData: [],
  setTreeData: () => {},
  validCampaignId: true,
  setValidCampaignId: () => {},
  refreshTree: false,
  setRefreshTree: () => {},
};

const UploadListContext = createContext<UploadListContextProps>(initialContext);

const useUploadListContext = () => {
  const context = useContext(UploadListContext);
  if (!context) {
    throw new Error(
      "useUploadListContext must be used within a UploadListProvider",
    );
  }
  return context;
};

interface UploadListProviderProviderProps {
  children: ReactNode;
}

const UploadListProvider: React.FC<UploadListProviderProviderProps> = ({
  children,
}) => {
  const [parentProjectId, setParentProjectId] = useState<string>("");
  const [parentProjectName, setParentProjectName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [newProjectName, setNewProjectName] = useState<string>("");
  const [campaignName, setCampaignName] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");
  const [campaignId, setCampaignId] = useState<string>("");
  const [acctId, setAcctId] = useState<string>("");
  const [activeComponent, setActiveComponent] = useState<string>("");
  const [sourceComponent, setSourceComponent] = useState<string>("");
  const [treeData, setTreeData] = useState<ITreeNode[]>([]);
  const [validCampaignId, setValidCampaignId] = useState<boolean>(true);
  const [refreshTree, setRefreshTree] = useState<boolean>(false);

  const updateActiveComponent = (component: string, source: string = "") => {
    setActiveComponent(component);
    setSourceComponent(source);
  };

  return (
    <UploadListContext.Provider
      value={{
        parentProjectId,
        setParentProjectId,
        parentProjectName,
        setParentProjectName,
        projectName,
        setProjectName,
        newProjectName,
        setNewProjectName,
        campaignName,
        setCampaignName,
        projectId,
        setProjectId,
        campaignId,
        setCampaignId,
        acctId,
        setAcctId,
        activeComponent,
        setActiveComponent,
        sourceComponent,
        setSourceComponent,
        updateActiveComponent,
        treeData,
        setTreeData,
        validCampaignId,
        setValidCampaignId,
        refreshTree,
        setRefreshTree,
      }}
    >
      {children}
    </UploadListContext.Provider>
  );
};

export { UploadListProvider, useUploadListContext };
