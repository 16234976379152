import ComplianceChecklistIcon from "./ComplianceChecklistIcon.svg?react";
import EducationIcon from "./EducationIcon.svg?react";
import CallDeliverabilityIcon from "./CallDeliverabilityIcon.svg?react";
import Styles from "./GroupIcon.module.css";

type Group = string;

interface Props {
  group: Group;
}

const GroupIcon: React.FC<Props> = ({ group }) => {
  switch (group) {
    case "Compliance Tools":
      return <ComplianceChecklistIcon className={Styles.otherToolsSvgIcon} />;
    case "Call Deliverability":
      return <CallDeliverabilityIcon className={Styles.otherToolsSvgIcon} />;
    case "Education and Thought Leadership":
      return <EducationIcon className={Styles.otherToolsSvgIcon} />;
    default:
      return null;
  }
};

export default GroupIcon;
