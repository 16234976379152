import { useState, ReactNode, ComponentType, useEffect } from "react";
import SiteLayout from "src/layouts/SiteLayout";
import LayoutContext from "src/contexts/LayoutContext";

interface WithLayoutProps {
  Component: ComponentType;
  Layout?: ComponentType<{ children: ReactNode }>;
  PageTitle?: string;
}

// Note this was at first a function that returned a function
// but refactoring it a component to improve readability
const WithLayout: React.FC<WithLayoutProps> = ({
  Component,
  Layout = SiteLayout,
  PageTitle = "",
}) => {
  const [heading, setHeading] = useState("");
  const [tagline, setTagline] = useState("");
  const [currentPageTitle, setPageTitle] = useState(PageTitle);

  useEffect(() => {
    setPageTitle(PageTitle); // Set the page title once on mount
    document.title = `DNCScrub: ${PageTitle}`;
  }, [PageTitle, currentPageTitle]);

  return (
    <LayoutContext.Provider
      value={{
        heading,
        tagline,
        PageTitle: currentPageTitle,
        setHeading,
        setTagline,
        setPageTitle,
      }}
    >
      <Layout>
        <Component />
      </Layout>
    </LayoutContext.Provider>
  );
};
export default WithLayout;
