import { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { callApi, HttpMethod } from "src/services/apiService";
import { useLayoutContext } from "src/contexts/LayoutContext";
import { MagnifyingGlass } from "react-loader-spinner";

type FTCComplaintDTO = {
  ftcComplaintId: string | null;
  phone: string;
  createdDate: string;
  violationDate: string;
  city: string;
  state: string;
  areaCode: string;
  subject: string;
  recordedMessageOrRobocall: string;
};

const Index = () => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [ftcComplaints, setFTCComplaints] = useState<FTCComplaintDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatPhoneNumber = useCallback((value: any) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return `(${onlyNums}`;
    if (onlyNums.length <= 6)
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10,
    )}`;
  }, []);

  const fetchData = async (phoneNumber: string) => {
    const apiUrl = `ComplianceGuide/GetFTCComplaints?phoneNumber=${phoneNumber}`;
    return await callApi(apiUrl, HttpMethod.GET);
  };

  const onSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);
      setPhoneNumber(data.phoneNumber);
      const phoneNumber = data.phoneNumber.replace(/[^\d]/g, "");
      console.log(phoneNumber);
      try {
        const response = await fetchData(phoneNumber);
        console.log(response);
        setFTCComplaints(response);
        reset();
        setIsLoading(false);
        setValue("phoneNumber", data.phoneNumber);
      } catch (error) {
        console.error("Error fetching GetFTCComplaints", error);
        setIsLoading(false);
      }
    },
    [reset, setValue],
  );

  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Search FTC Complaints by Phone Number");
      setTagline(
        "Enter a phone number you place calls from to see if any FTC Complaints have been filed against your number.",
      );
    }
  }, [setHeading, setTagline]);

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone Number is required",
                pattern: {
                  value: /^\(\d{3}\) \d{3}-\d{4}$/,
                  message: "Phone Number must be in (xxx) xxx-xxxx format",
                },
              }}
              render={({ field }) => (
                <input
                  {...field}
                  onChange={(e) =>
                    field.onChange(formatPhoneNumber(e.target.value))
                  }
                  className={`form-control ${
                    errors.phoneNumber ? "is-invalid" : ""
                  }`}
                  placeholder="Outbound Phone Number"
                  type="text"
                />
              )}
            />
            {errors.phoneNumber && (
              <p>{errors.phoneNumber.message?.toString()}</p>
            )}
          </div>

          <button disabled={!isDirty} type="submit" className="btn btn-primary">
            Search
          </button>
        </form>
      </div>
      {isLoading && (
        <div className="loader-container">
          <MagnifyingGlass
            visible={isLoading}
            height="80"
            width="80"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#0d6efd"
          />
        </div>
      )}
      {ftcComplaints && ftcComplaints.length > 0 && !isLoading ? (
        <div style={{ marginTop: 20 }}>
          <span>
            Listed below are FTC Complaint data for phone # <b>{phoneNumber}</b>
          </span>
          <table className="table table-bordered table-hover table-striped border-primary">
            <thead>
              <tr>
                <th>Phone #</th>
                <th>Created Date</th>
                <th>Violation Date</th>
                <th>City</th>
                <th>State</th>
                <th>Area Code</th>
                <th>Subject</th>
                <th>Recorded Message Or Robocall</th>
              </tr>
            </thead>
            <tbody>
              {ftcComplaints.map((item: FTCComplaintDTO, index: number) => (
                <tr key={index}>
                  <td>{item.phone}</td>
                  <td>{item.createdDate}</td>
                  <td>{item.violationDate}</td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{item.areaCode}</td>
                  <td>{item.subject}</td>
                  <td>{item.recordedMessageOrRobocall}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <span>
          {ftcComplaints.length === 0 && isSubmitted ? "No records found." : ""}
        </span>
      )}
    </>
  );
};

export default Index;
