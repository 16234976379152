import { useState } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { callApi, HttpMethod } from "src/services/apiService";

Modal.setAppElement("#root");

interface CitationProps {
  statuteId: string;
  statuteName: string;
  ref1?: string | null;
  ref2?: string | null;
  ref3?: string | null;
}

const Citation: React.FC<CitationProps> = ({
  statuteId,
  statuteName,
  ref1,
  ref2,
  ref3,
}) => {
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const generateCitationName = () => {
    if (!ref1 && !ref2 && !ref3) {
      return statuteName;
    }

    let refs = [ref1, ref2, ref3]
      .filter((ref) => ref)
      .map((ref) => `(${ref})`)
      .join(" ");

    return `${statuteName} ${refs}`.trim();
  };

  const handleLinkClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      if (!iframeSrc) {
        const apiUrl = `ComplianceGuide/Statute?StatuteId=${statuteId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        const jsonData = JSON.parse(response);

        // Create an array of refs and filter out the ones that are null or undefined
        const refs = [ref1, ref2, ref3].filter((ref) => ref);

        // Convert the refs array to a comma-separated string and prepend with #
        const hashString = refs.length > 0 ? `#${refs.join(",")}` : "";

        const url = `https://classic.dncscrub.com${jsonData[0].url}?statutePopup=1${hashString}`;

        setIframeSrc(url);
      }
      setModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch URL:", error);
    }
  };

  return (
    <>
      <Link to="#" onClick={handleLinkClick}>
        {generateCitationName()}
      </Link>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={{
          overlay: {
            zIndex: 1000,
            overflowY: "auto",
          },
          content: {
            width: "95%",
            height: "95%",
            margin: "auto",
            padding: "5px",
            overflow: "hidden",
          },
        }}
      >
        {iframeSrc && (
          <iframe
            src={iframeSrc}
            title="External Content"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "auto",
            }}
          ></iframe>
        )}
        <button
          onClick={() => setModalOpen(false)}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
          className="btn btn-danger"
        >
          <i className="bi bi-x fs-7"></i>
        </button>
      </Modal>
    </>
  );
};

export default Citation;
