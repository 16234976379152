import { store } from "src/app/store";
import { logout } from "src/features/authSlice";
import * as Sentry from "@sentry/react";

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export async function callApi(
  endpoint: string,
  method: HttpMethod,
  body?: any,
  redirect: boolean = true,
  contentType?: string,
  file?: File,
): Promise<any> {
  const baseUrl = import.meta.env.VITE_APP_API_URL;

  const state = store.getState();
  const { acctId, loginId, csrfToken } = state.auth;

  let requestOptions: RequestInit;

  if (file) {
    // Handle file upload
    const formData = new FormData();
    formData.append("file", file); // Append file

    if (body) {
      Object.keys(body).forEach((key) => {
        formData.append(key, body[key]);
      });
    }

    formData.append("acctId", acctId ?? "");
    formData.append("loginId", loginId ?? "");
    formData.append("csrfToken", csrfToken ?? "");

    requestOptions = {
      method: method,
      body: formData,
      credentials: "include",
    };
  } else {
    // Handle regular JSON payload
    const headers = new Headers();
    headers.append("Content-Type", contentType ?? "application/json");
    headers.append("acctId", acctId ?? "");
    headers.append("loginId", loginId ?? "");
    headers.append("csrfToken", csrfToken ?? "");

    requestOptions = {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : undefined,
      credentials: "include",
    };
  }

  Sentry.addBreadcrumb({
    category: "fetch",
    message: `Fetching data from ${baseUrl}/${endpoint}. Using ${requestOptions}`,
  });

  const response = await fetch(`${baseUrl}/${endpoint}`, requestOptions);

  if (!response.ok) {
    // If user is unauthorized, redirect to login page
    if (response.status === 401 && redirect) {
      // Make sure redux store knows we are logged out
      // Doing this clears all session storage and cookies
      store.dispatch(logout());
      console.log("Session is not authorized");
      // Note the redirect will unload the page so nothing below this run
      window.location.href = "/login?reason=notauthorized";
      return;
    } else {
      throw new Error(response.statusText || "An error occurred");
    }
  }

  return response.json();
}
