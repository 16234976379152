import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLayoutContext } from "src/contexts/LayoutContext";
import styles from "./Index.module.css";
// Creat a variable prefix to use in the path

const Index = () => {
  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Welcome to the Compliance Guide");
      setTagline(
        "The Compliance Guide is backed by leading compliance attorneys and legal experts. The Compliance Guide offers:",
      );
    }
  }, [setHeading, setTagline]);

  const cgPrefix = "/compliance-guide";

  return (
    <>
      <div
        className="w-100 compliance-intro col compliance-card-container"
        style={{ marginTop: "1rem" }}
      >
        <div className="row">
          <div className="col-sm-4">
            <div className="compliance-card h-100">
              <h4 className="compliance-card-title">
                <Link to={`${cgPrefix}/regulatory-guide`}>
                  <i className="bi bi-bank"></i> <span>Regulation Guide</span>
                </Link>
              </h4>

              <p className="card-text">
                Access, compare and research all state and federal contact
                center compliance laws in one dedicated online portal.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="compliance-card h-100">
              <h4 className="compliance-card-title">
                <Link to={`${cgPrefix}/regulatory-charts`}>
                  <i className="bi bi-bar-chart-line"></i>{" "}
                  <span>Regulatory Charts</span>
                </Link>
              </h4>

              <p className="card-text">
                Analyze and compare compliance requirements state by state.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="compliance-card h-100">
              <h4 className="compliance-card-title">
                <Link to={`${cgPrefix}/compliance-wizard`}>
                  <i className="bi bi-magic"></i>{" "}
                  <span>Safe Harbor Wizard</span>
                </Link>
              </h4>

              <p className="card-text">
                Identifies your call centers most common safe harbor mistakes.
              </p>
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-sm-4">
            <div className="compliance-card h-100">
              <h4 className="compliance-card-title">
                <Link to={`${cgPrefix}/dnc-map`}>
                  <i className="bi bi-globe"></i>{" "}
                  <span>Interactive Compliance Map</span>
                </Link>
              </h4>

              <p className="card-text">
                Interactive USA map that quickly identifies calling rules and Do
                Not Call (DNC) metrics for over a dozen DNC scenarios.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="compliance-card h-100">
              <h4 className="compliance-card-title">
                <Link to={`${cgPrefix}/glossary`}>
                  <i className="bi bi-book"></i>{" "}
                  <span>Compliance Glossary</span>
                </Link>
              </h4>

              <p className="card-text">
                Resources to define and clarify common terms used in DNC
                compliance rules and regulations.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="compliance-card h-100">
              <h4 className="compliance-card-title">
                <Link to={`${cgPrefix}/faq`}>
                  <i className="bi bi-patch-question"></i> <span>FAQs</span>
                </Link>
              </h4>

              <p className="card-text">
                Frequently Asked Questions for quick ramp up on DNC rules.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles["accent-area"]}`}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2 className={`${styles["accent-area-header"]}`}>
            📚 Comp Guide Demo
          </h2>
          <iframe
            src="https://player.vimeo.com/video/882688433"
            width="640"
            height="360"
            title="Compliance Guide Intro"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Index;
