import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import { Project } from "src/interfaces/Project";
import styles from "./TreeView.module.css";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import ICpaSection from "src/interfaces/ICpaSection";
import TreeNode from "src/components/Main/TreeNode";
import { ITreeNode } from "src/interfaces/ITreeNode";
import DOMPurify from "dompurify";

interface TreeDataResponse {
  Projects: Project[];
  CpaSection: ICpaSection;
  TreeView: ITreeNode[];
}

const TreeView: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [access, setAccess] = useState<ICpaSection>();
  const [isHideInactiveChecked, setIsHideInactiveChecked] = useState(true);
  const { acctId, acctName } = useAppSelector(selectAuth);
  const {
    treeData,
    setTreeData,
    refreshTree,
    setRefreshTree,
    campaignId,
    setNewProjectName,
    setActiveComponent,
  } = useUploadListContext();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<number>(0);
  const [localNewProjectName, setLocalNewProjectName] = useState("");

  const fetchTreeData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data: TreeDataResponse = await callApi(
        `main/Projects/GetProjects?acctid=${acctId}&inactive=${isHideInactiveChecked}`,
        HttpMethod.GET,
      );

      if (data) {
        setAccess(data.CpaSection);
        setTreeData(data.TreeView);
      } else {
        console.warn("No projects returned from API.");
      }
    } catch (error) {
      console.error("Error fetching tree data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [acctId, isHideInactiveChecked, setTreeData]);

  useEffect(() => {
    if (refreshTree) {
      setRefreshTree(false);
      //console.log("Refreshing tree data...");
    }
    captureScrollPosition();
    fetchTreeData();
    restoreScrollPosition();
  }, [refreshTree, setRefreshTree, fetchTreeData]);

  const captureScrollPosition = () => {
    if (scrollableContainerRef.current) {
      scrollPosition.current = scrollableContainerRef.current.scrollTop;
    }
  };

  const restoreScrollPosition = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = scrollPosition.current;
    }
  };

  useLayoutEffect(() => {
    restoreScrollPosition();
  }, [treeData]);

  const renderTreeNodes = (nodes: ITreeNode[], parentNode?: ITreeNode) => {
    return nodes
      .filter((node) => !(isHideInactiveChecked && node.status === 2))
      .map((node) => (
        <TreeNode
          key={node.key}
          node={node}
          isHideInactiveChecked={isHideInactiveChecked}
          addNewCampaign={access?.InsertCampaign || false}
        >
          {node.children && renderTreeNodes(node.children, node)}
        </TreeNode>
      ));
  };

  const handleRefreshClick = async () => {
    captureScrollPosition();
    await fetchTreeData();
    restoreScrollPosition();
  };

  const handleHideInactiveChange = async () => {
    captureScrollPosition();
    console.log("Before fetching data in handleHideInactiveChange");
    setIsHideInactiveChecked(!isHideInactiveChecked);
    setIsLoading(true);

    try {
      await fetchTreeData();
    } catch (error) {
      console.error("Error reloading campaigns for expanded projects:", error);
    } finally {
      setIsLoading(false);
      restoreScrollPosition();
    }
  };

  const handleNewProjectNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocalNewProjectName(e.target.value);
  };

  const handleCreateNewProjectClick = () => {
    const sanitizedProjectName = DOMPurify.sanitize(localNewProjectName, {
      ALLOWED_TAGS: [],
    });
    setNewProjectName(sanitizedProjectName);
    setActiveComponent("ProjectSettings");
    navigate(`/main/project-settings/${campaignId}`);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            className={`text-white fw-bold text-uppercase small ms-3 text-truncate ${styles["account-name-box"]}`}
          >
            {acctName}
            {refreshTree ? " - Refreshing..." : ""}
          </div>
          <nav className={styles["treeview-container"]}>
            <div
              className={styles["treeview-project-list"]}
              ref={scrollableContainerRef}
            >
              <div
                style={{ marginLeft: 0 }}
                className="ps-1 nav flex-column w-100"
              >
                {treeData && treeData.length > 0 ? (
                  renderTreeNodes(treeData)
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
            <div className={styles["treeview-add-project-div"]}>
              <div className="d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="hideInactive"
                  checked={isHideInactiveChecked}
                  onChange={handleHideInactiveChange}
                />
                <label className="ps-1" htmlFor="hideInactive">
                  Hide Inactive Items
                </label>
              </div>
              {access?.InsertProject && (
                <div className="position-relative mt-2">
                  <input
                    type="text"
                    placeholder="Project name..."
                    value={localNewProjectName}
                    onChange={handleNewProjectNameChange}
                    className="form-control input-box-with-circle-btn"
                  />
                  <button
                    title="Add Project"
                    type="button"
                    onClick={handleCreateNewProjectClick}
                    className="circle-submit-btn position-absolute top-0 end-0"
                  >
                    <i>
                      <svg
                        fill="none"
                        height="33"
                        viewBox="0 0 33 33"
                        width="33"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="16.7023"
                          cy="16.1719"
                          r="16"
                          className="circle-btn-svg"
                        ></circle>
                        <path
                          clipRule="evenodd"
                          d="M22.6551 16.7093L17.1367 7.25781L11.618 16.7093L14.7223 15.8554L15.8636 24.1264H18.6077L19.4942 15.8554L22.6551 16.7093Z"
                          fill="white"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </i>
                  </button>
                </div>
              )}
              <button
                onClick={handleRefreshClick}
                className="btn btn-primary btn-sm mt-2 w-100"
              >
                Refresh
              </button>
            </div>
          </nav>
        </>
      )}
    </>
  );
};

export default TreeView;
