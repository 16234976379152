// Importing CSS this way gets it minified by the build tool
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "src/css/App.css";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import Header from "src/components/Header";
import PrivateRoute from "src/components/PrivateRoute";
import SiteInfo from "src/components/SiteInfo";
import { LiveChatWidget } from "@livechat/widget-react";
import { UploadListProvider } from "src/contexts/UploadListContext";

type SiteLayoutProps = {
  children: React.ReactNode;
  isLeftSidebarOpen?: boolean;
};

const SiteLayout: React.FC<SiteLayoutProps> = ({ children }) => {
  const liveChatLicense = "9040610";
  return (
    <PrivateRoute>
      <Helmet>
        <title>DNCScrub</title>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
          rel="stylesheet"
        />
      </Helmet>
      <UploadListProvider>
        <Header />

        {/* content area begin */}
        {children}
        {/* content area end */}
      </UploadListProvider>

      <SiteInfo />
      <LiveChatWidget license={liveChatLicense} />
    </PrivateRoute>
  );
};

export default SiteLayout;
