import logoData from "src/data/logo.json";

export const getLogo = (logoName: string): string | null => {
  try {
    const data = logoData as { [key: string]: string };
    const hostParts = window.location.hostname.split(".");
    let subdomain = "";
    if (hostParts.length > 2) {
      subdomain = hostParts.slice(0, -2).join(".");
    }

    subdomain = subdomain.toLowerCase();
    logoName = logoName.toLowerCase();

    // Checking for the logo 3 ways:
    // 1. Show logo based on subdomain
    if (subdomain && data[subdomain]) {
      return data[subdomain];
    } else if (logoName && data[logoName.toLowerCase()]) {
      // 2. Show logo based on the data that came from the API
      return data[logoName.toLowerCase()];
    } else {
      // 3. Return null if no logo found
      return null;
    }
  } catch (error) {
    console.error("Error fetching subdomain images:", error);
    return null;
  }
};
