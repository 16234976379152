import Styles from "./HelpButton.module.css";

const HelpButton = () => {
  const handleHelpClick = () => {
    window.open(
      "https://classic.dncscrub.com/app/help/h-helpdesk.adp",
      "hd_popup",
      "width=550,height=405,resizable=1,status=yes,toolbar=no,menubar=no,scrollbars=yes,location=no,top=0,left=0",
    );
  };

  return (
    <>
      <button
        className={`btn nav-link text-white nav-link ${Styles["help-button"]}`}
        id="bd-theme"
        type="button"
        data-bs-display="static"
        aria-label="Help"
        onClick={handleHelpClick}
      >
        <i className="bi bi-question-circle"></i>
        <span className="ms-1" id="help-text">
          Help
        </span>
      </button>
    </>
  );
};
export default HelpButton;
