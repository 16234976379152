import React from "react";
import styles from "src/components/ScrubWarnings.module.css";

export const WarningComponent: React.FC<{ message: string }> = React.memo(
  ({ message }) => {
    return (
      <div
        className={`card mt-3 mb-3 position-static ${styles["scrub-warning-box"]}`}
      >
        <div className="card-body d-flex align-items-center">
          <i
            className={`h1 bi bi-exclamation-triangle-fill pe-3 ${styles["scrub-warning-icon"]}`}
          ></i>
          {message}
        </div>
      </div>
    );
  },
);
