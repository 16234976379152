import { useState, useEffect } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useLayoutContext } from "src/contexts/LayoutContext";
import styles from "./Index.module.css";

const Index = () => {
  const [glossary, setGlossary] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setHeading, setTagline } = useLayoutContext();

  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Glossary of Industry Terms");
      setTagline("");
    }
  }, [setHeading, setTagline]);

  useEffect(() => {
    async function ShowGlossary() {
      try {
        let apiUrl = `ComplianceGuide/Glossary?category=Industry`;
        const fetchedData = await callApi(apiUrl, HttpMethod.GET);
        setGlossary(fetchedData);
        setIsLoading(false);
      } catch (err) {
        const errorMessage = (err as Error).message;
        console.log(errorMessage);
        setIsLoading(false); // Set loading to false in case of error to not leave the user in perpetual loading state
      }
    }

    ShowGlossary();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ul className={styles.glossaryStyles}>
          {glossary.map((item, index) => (
            <li key={index}>
              <b>{item.term}</b>
              <div className="help-text">{item.public}</div>
              <br />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Index;
