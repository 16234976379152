import { Transform } from "redux-persist";
//import CryptoJS from "crypto-js";
import { isEncrypted } from "../utils/encryptionUtils";

//const secretKey =
//  import.meta.env.VITE_APP_STORAGE_ENCRYPTION_KEY ||
//  "U2FsdGVkX1/NbLM8IQ4DkbisZeZUYCYqVpShvfDRhERCpdaDgd3PEcm8CKnON81kJw";

// Disable encryption of local storage so we don't get
// false sense of security

// Note encryption done in storageService.ts too
const encryptionEnabled = false;

const encryptor: Transform<any, any> = {
  in: (inboundState) => {
    if (!encryptionEnabled) return inboundState;
    try {
      /*const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(inboundState),
        secretKey,
      ).toString();
      return encrypted;*/
      throw new Error(
        "CryptJS removed to reduce bundle size as encryption disabled right now",
      );
    } catch (error) {
      console.error("Encryption error:", error);
      return inboundState;
    }
  },
  out: (outboundState) => {
    if (!encryptionEnabled && !isEncrypted(outboundState)) return outboundState;
    try {
      /* const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
      const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decrypted;*/
      throw new Error(
        "CryptJS removed to reduce bundle size as encryption disabled right now",
      );
    } catch (error) {
      console.error("Decryption error:", error);
      return outboundState;
    }
  },
};

export default encryptor;
