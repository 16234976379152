import { useState, useEffect } from "react";
import * as React from "react";
import MapChart from "./MapChart";
import optionsJson from "./options.json";
import { useLayoutContext } from "src/contexts/LayoutContext";
import styles from "./Index.module.css";

// Defining types for the options and legend
type LegendItem = {
  color: string;
  label: string;
};

type Option = {
  value: string;
  label: string;
  explanation: string;
  legend: LegendItem[];
};

// Casting the imported JSON to the right type
const options: Option[] = optionsJson as Option[];

const Index: React.FC = () => {
  const defaultOption: Option = options[0];
  const [selectedOption, setSelectedOption] = useState<string>(
    defaultOption.value,
  );

  const { setHeading, setTagline } = useLayoutContext();

  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading(defaultOption.label);
      setTagline(defaultOption.explanation);
    }
  }, [defaultOption, setHeading, setTagline]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    const selectedOptionData = options.find(
      (option) => option.value === selectedValue,
    );
    if (selectedOptionData) {
      if (setHeading && setTagline) {
        setHeading(selectedOptionData.label);
        setTagline(selectedOptionData.explanation);
      }
    }
  };

  const selectedLegend =
    options.find((option) => option.value === selectedOption)?.legend || [];

  return (
    <>
      <select
        className="form-select"
        value={selectedOption}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {selectedOption && <MapChart mapId={Number(selectedOption)} />}
      <div
        className="map-legend col-2"
        style={{ border: "2px solid darkgray" }}
      >
        {selectedLegend.map((item, index) => (
          <div
            key={index}
            className={styles.legendItem}
            style={{ backgroundColor: item.color }}
          >
            <span className="label-text">{item.label}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default Index;
