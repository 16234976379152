import { useState } from "react";
import * as React from "react";
import { selectAuth } from "src/features/authSlice";
import { useAppSelector } from "src/app/hooks";
import Styles from "./SiteInfo.module.css";
import SafeHtml from "./SafeHtml";

const SiteInfo: React.FC = () => {
  const { acctMessage } = useAppSelector(selectAuth);
  const [showSiteInfo] = useState(false);

  // As we don't current show links in the site info message, this
  // isn't needed. We deprecated change plan so that's why we don't
  // want to show links - Jeff 6/23/2024
  // useEffect(() => {
  //   const handleLinkClick = (event: MouseEvent) => {
  //     const target = event.target as HTMLAnchorElement;
  //     if (target && target.matches("a[data-link]")) {
  //       event.preventDefault();
  //       const dataLink = target.getAttribute("data-link");
  //       if (dataLink) {
  //         eval(dataLink);
  //       }
  //     }
  //   };

  //   document.addEventListener("click", handleLinkClick);

  //   return () => {
  //     document.removeEventListener("click", handleLinkClick);
  //   };
  // }, []);

  // Only render the div if acctMessage is truthy
  if (!acctMessage) {
    return null;
  }

  // Temporarily disable for everyone until we can debug
  // why it's not always right
  // Abhi: Had to add the flag to get rid of "Unreachable code" lint warning
  if (!showSiteInfo) {
    return null;
  }

  return (
    <div className={`${Styles["acct-info-wrapper"]}`}>
      <div className={`${Styles["acct-info"]}`}>
        <div className="me-2">
          <SafeHtml html={acctMessage} />
        </div>
        <div>
          Contact:{" "}
          {/* <a target="dncWindow" href="http://www.dnc.com/" className="link5DW">
            www.dnc.com
          </a>{" "} */}
          <a href="mailto:support@dnc.com">support@dnc.com</a> or 866-DNC-LIST
        </div>
      </div>
    </div>
  );
};

export default SiteInfo;
