import { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { IAccountSettings } from "src/interfaces/Admin/IAccount";
import SaveInProgress from "src/components/SaveInProgress";
import Styles from "./Account.module.css";
import SubAccounts from "../Accounts/SubAccounts";

const AccountSettings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [data, setData] = useState<IAccountSettings | null>(null);
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IAccountSettings>();

  useEffect(() => {
    const fetchAccountSettingsData = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `admin/Account`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        const transformedResponse = {
          ...response,
          checkboxes: {
            blockAfterScrubsReaches: isCheckBoxChecked(
              response.settings.blockAfterScrubsReaches,
            ),
            blockAfterPoliciesSentReaches: isCheckBoxChecked(
              response.settings.blockAfterPoliciesSentReaches,
            ),
            warnAfterScrubsReaches: isCheckBoxChecked(
              response.settings.warnAfterScrubsReaches,
            ),
            warnAfterPoliciesSentReaches: isCheckBoxChecked(
              response.settings.warnAfterPoliciesSentReaches,
            ),

            tcpaAuthorityAllowedCountThisMonth: isCheckBoxChecked(
              response.settings.tcpaAuthorityAllowedCountThisMonth,
            ),
            tcpaAuthorityCountBeforeSendEmail: isCheckBoxChecked(
              response.settings.tcpaAuthorityCountBeforeSendEmail,
            ),
            tcpaAuthorityPlusAllowedCountThisMonth: isCheckBoxChecked(
              response.settings.tcpaAuthorityPlusAllowedCountThisMonth,
            ),
            tcpaAuthorityPlusCountBeforeSendEmail: isCheckBoxChecked(
              response.settings.tcpaAuthorityPlusCountBeforeSendEmail,
            ),
            basicRNDAllowedCountThisMonth: isCheckBoxChecked(
              response.settings.basicRNDAllowedCountThisMonth,
            ),
            basicRNDCountBeforeSendEmail: isCheckBoxChecked(
              response.settings.basicRNDCountBeforeSendEmail,
            ),
          },
          positiveValues: {
            blockAfterScrubsReaches: setTextBoxValue(
              response.settings.blockAfterScrubsReaches,
            ),
            blockAfterPoliciesSentReaches: setTextBoxValue(
              response.settings.blockAfterPoliciesSentReaches,
            ),
            warnAfterScrubsReaches: setTextBoxValue(
              response.settings.warnAfterScrubsReaches,
            ),
            warnAfterPoliciesSentReaches: setTextBoxValue(
              response.settings.warnAfterPoliciesSentReaches,
            ),

            tcpaAuthorityAllowedCountThisMonth: setTextBoxValue(
              response.settings.tcpaAuthorityAllowedCountThisMonth,
            ),
            tcpaAuthorityCountBeforeSendEmail: setTextBoxValue(
              response.settings.tcpaAuthorityCountBeforeSendEmail,
            ),
            tcpaAuthorityPlusAllowedCountThisMonth: setTextBoxValue(
              response.settings.tcpaAuthorityPlusAllowedCountThisMonth,
            ),
            tcpaAuthorityPlusCountBeforeSendEmail: setTextBoxValue(
              response.settings.tcpaAuthorityPlusCountBeforeSendEmail,
            ),
            basicRNDAllowedCountThisMonth: setTextBoxValue(
              response.settings.basicRNDAllowedCountThisMonth,
            ),
            basicRNDCountBeforeSendEmail: setTextBoxValue(
              response.settings.basicRNDCountBeforeSendEmail,
            ),
          },
        };
        setData(transformedResponse);
        reset(transformedResponse);
      } catch (error) {
        setWarningMessage("Failed to fetch Account settings data.");
        console.error("Failed to fetch Account settings data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAccountSettingsData();
  }, [reset]);

  const onSubmit: SubmitHandler<IAccountSettings> = async (formData) => {
    setSaveInProgress(true);

    const payload = {
      ...formData,
      settings: {
        ...formData.settings,

        /* A negative value in the database means the value isn't set.
           Trick to retain last value before being unchecked */
        blockAfterScrubsReaches: getTextBoxValue(
          formData.checkboxes.blockAfterScrubsReaches,
          formData.positiveValues.blockAfterScrubsReaches,
        ),
        blockAfterPoliciesSentReaches: getTextBoxValue(
          formData.checkboxes.blockAfterPoliciesSentReaches,
          formData.positiveValues.blockAfterPoliciesSentReaches,
        ),
        warnAfterScrubsReaches: getTextBoxValue(
          formData.checkboxes.warnAfterScrubsReaches,
          formData.positiveValues.warnAfterScrubsReaches,
        ),
        warnAfterPoliciesSentReaches: getTextBoxValue(
          formData.checkboxes.warnAfterPoliciesSentReaches,
          formData.positiveValues.warnAfterPoliciesSentReaches,
        ),

        tcpaAuthorityAllowedCountThisMonth: getTextBoxValue(
          formData.checkboxes.tcpaAuthorityAllowedCountThisMonth,
          formData.positiveValues.tcpaAuthorityAllowedCountThisMonth,
        ),
        tcpaAuthorityCountBeforeSendEmail: getTextBoxValue(
          formData.checkboxes.tcpaAuthorityCountBeforeSendEmail,
          formData.positiveValues.tcpaAuthorityCountBeforeSendEmail,
        ),
        tcpaAuthorityPlusAllowedCountThisMonth: getTextBoxValue(
          formData.checkboxes.tcpaAuthorityPlusAllowedCountThisMonth,
          formData.positiveValues.tcpaAuthorityPlusAllowedCountThisMonth,
        ),
        tcpaAuthorityPlusCountBeforeSendEmail: getTextBoxValue(
          formData.checkboxes.tcpaAuthorityPlusCountBeforeSendEmail,
          formData.positiveValues.tcpaAuthorityPlusCountBeforeSendEmail,
        ),
        basicRNDAllowedCountThisMonth: getTextBoxValue(
          formData.checkboxes.basicRNDAllowedCountThisMonth,
          formData.positiveValues.basicRNDAllowedCountThisMonth,
        ),
        basicRNDCountBeforeSendEmail: getTextBoxValue(
          formData.checkboxes.basicRNDCountBeforeSendEmail,
          formData.positiveValues.basicRNDCountBeforeSendEmail,
        ),
      },
    };
    //Remove unwanted properties
    const { acctAuths, cpa, checkboxes, positiveValues, ...submitData } =
      payload;
    try {
      const apiUrl = `admin/Account`;
      const method = HttpMethod.POST;
      const body = submitData;

      // This has to have async or it blocks the main thread
      // and the SaveInProgress component doesn't redner
      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Failed to save settings:", error);
    }

    setSaveInProgress(false);
  };

  const handleReset = () => {
    if (data !== null) {
      reset(data);
    }
  };

  const resetToPlanDefaults = () => {
    document
      .querySelectorAll("input[type='checkbox'][data-default]")
      .forEach((element) => {
        const checkbox = element as HTMLInputElement; // Type assertion here
        const defaultValue = checkbox.getAttribute("data-default") === "true";
        checkbox.checked = defaultValue;
      });
  };

  function isCheckBoxChecked(value: number | null | undefined): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return value >= 0;
  }

  function setTextBoxValue(value: number | null | undefined): string | number {
    if (value === null || value === undefined) {
      return "";
    }
    return Math.abs(value).toLocaleString();
  }

  const getTextBoxValue = (
    isChecked: boolean,
    value: string | number,
  ): number => {
    const numericValue =
      typeof value === "string" ? parseInt(value.replace(/,/g, ""), 10) : value;
    return isChecked ? numericValue : -1 * numericValue;
  };

  const validatePassword = (password: string): boolean | string => {
    if (!password) return true;
    if (password.length < 7)
      return "Password must be at least 7 characters long";

    const checks = [
      /[A-Z]/, // Uppercase letters
      /[a-z]/, // Lowercase letters
      /[0-9]/, // Numbers
      /[^A-Za-z0-9]/, // Special characters
    ];

    const passedChecks = checks.reduce(
      (acc, check) => acc + +check.test(password),
      0,
    );
    return (
      passedChecks >= 3 ||
      "Password must contain 3 of the following: uppercase letters, lowercase letters, numbers, or special characters"
    );
  };

  const orderedAcctNames = [
    { key: "update_own_info", label: "Edit their own contact information" },
    { key: "update_own_password", label: "Edit their own password" },
    { key: "scrub_quick", label: "QuickScrub" },
    { key: "delete_pdnc", label: "Remove records from DNC/DNM database" },
  ];

  const defaultHomepageOptions = [
    { value: "", label: "" },
    { value: "m-quick-scrub.adp", label: "QuickScrub" },
    { value: "m-dncdnm-db.adp", label: "Internal DNC/DNM" },
    { value: "m-send.adp", label: "Send Policy" },
  ];

  if (isLoading || !data) {
    return <Loading />;
  }
  return (
    <div className="container-fluid vh-100 overflow-y-auto">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={Styles["container-form"]}
      >
        <h1>Account Settings</h1>
        <div className="card">
          <div className="card-header">Account Configuration</div>
          <div className="card-body">
            {warningMessage && (
              <div className="alert alert-danger">{warningMessage}</div>
            )}

            <div className="d-flex align-items-center">
              <span className="me-2 flex-shrink-0 fw-bold">Account Name:</span>
              <input
                type="text"
                className="form-control"
                {...register("acctName", { required: true })}
              />
            </div>
            {data.cpa.project && (
              <>
                <label className="d-block">
                  <input
                    type="checkbox"
                    className="me-1 form-check-input"
                    {...register("settings.autoPrefixProjIds")}
                    data-default="false"
                  />
                  <span>Auto-Prefix Project Codes with Account Code</span>
                </label>
              </>
            )}

            <div className="d-flex justify-content-between gap-3">
              <label className="flex-grow-1">
                <span className="fw-bold">E-mail background events to:</span>
                <textarea
                  className="form-control"
                  rows={2}
                  {...register("settings.eventRecipients")}
                />
              </label>

              <label className="flex-grow-1">
                <span className="fw-bold">Email reassigned reports to:</span>
                <br />
                <textarea
                  className="form-control"
                  rows={2}
                  {...register("settings.emailOnReassignedJobCompletion")}
                />
              </label>
            </div>
          </div>
        </div>

        {data.cpa.subAccounts === "1" && (
          <>
            <div className="card mt-3">
              <div className="card-header">Subaccount Security Settings</div>

              <div className="card-body">
                <div className="d-flex align-items-center mb-2">
                  <span className="me-2">
                    Password for the special user "subadmin":
                  </span>
                  <input
                    type="text"
                    className="form-control flex-grow-1"
                    style={{ maxWidth: "300px" }}
                    {...register("settings.subadminPasswordHash", {
                      validate:
                        validatePassword ||
                        "Password does not meet requirements",
                    })}
                  />
                  {errors.settings?.subadminPasswordHash && (
                    <p>{errors.settings.subadminPasswordHash.message}</p>
                  )}
                </div>

                {data.cpa.ftp === "1000" && (
                  <>
                    <div>
                      Currently hiding this as we do not have an automated way
                      to update the password in the sftp server - Abhi
                      02/14/2024
                    </div>
                    <label>
                      <span className="ps-2">Password for FTP Access:</span>
                      <input
                        type="textbox"
                        className="form-control"
                        {...register("settings.ftpPasswordHash")}
                      />
                    </label>
                  </>
                )}
                <p className="mb-0">
                  The password must be 7 characters long and contain 3 of the
                  following: uppercase letters, lowercase letters, numbers, or
                  special character. The password must also not contain your
                  account id and must be different than the last 4 passwords
                  used.
                </p>
              </div>
            </div>
          </>
        )}
        <div className="card mt-3">
          <div className="card-header">Agent Rights</div>
          <div className="card-body">
            {orderedAcctNames.map(({ key, label }) => {
              const isDisabled =
                key === "scrub_quick" && data.agentQSAuth === 0;
              watch(`agentRights.${key}`);

              return (
                <div key={key}>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      {...register(`agentRights.${key}`)}
                      defaultChecked={
                        data.acctAuths.find((a) => a.AcctName === key)
                          ?.IsChecked === "true"
                      }
                      disabled={isDisabled}
                      data-default="true"
                    />
                    <span className="ps-2">{label}</span>
                  </label>
                </div>
              );
            })}
            <Controller
              name="settings.agentHomepage"
              control={control}
              defaultValue={data.settings.agentHomepage || ""}
              render={({ field }) => (
                <div className="d-flex align-items-center">
                  {" "}
                  <span className="me-3">Default Agent Homepage:</span>{" "}
                  <select
                    {...field}
                    className="form-control form-select"
                    style={{ width: "auto", maxWidth: "100%" }}
                  >
                    {" "}
                    {defaultHomepageOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            />
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-header">Overage Control</div>
          <div className="card-body">
            {/* Overage controls in place when account hasn't made payment yet */}
            <div>
              {data.acctInfo.accountStatus === 6 && (
                <p>
                  While we await payment on your Account, the following limits
                  are in place:
                  <br />
                </p>
              )}

              {data.acctInfo.maxScrubsPerMonth !== null &&
                data.acctInfo.maxScrubsPerMonth !== "" &&
                data.acctInfo.maxScrubsPerMonth.toLowerCase() !== "unlimited" &&
                data.acctInfo.maxScrubsPerMonth.toLowerCase() !== "n/a" &&
                data.acctInfo.basePlanId !== "7" && (
                  <p>
                    Your Account can scrub up to{" "}
                    {data.acctInfo.maxScrubsPerMonth.toLocaleString()} records
                    per month.
                    <br />
                  </p>
                )}

              {data.acctInfo.maxPoliciesSentPerMonth !== null &&
                data.acctInfo.maxPoliciesSentPerMonth !== "" && (
                  <p>
                    Your Account can send up to{" "}
                    {data.acctInfo.maxPoliciesSentPerMonth.toLocaleString()}{" "}
                    policies per month.
                  </p>
                )}

              {data.acctInfo.basePlanId === "4" && (
                <div>
                  <span>
                    Auto-upgrade to Multi-User account if scrubbing limit
                    reached:
                  </span>
                  <select
                    {...register("settings.autoUpgradeToMultiUser")}
                    className="textbox1"
                  >
                    <option value={0}>Don't auto-upgrade</option>
                    <option value={1}>
                      Auto-Upgrade, don't keep QuickScrub
                    </option>
                    <option value={2}>
                      Auto-Upgrade, keep 1 user QuickScrub
                    </option>
                  </select>
                </div>
              )}
            </div>

            {/* List of checkboxes with actual overage controls */}
            <div className={Styles["overage-controls"]}>
              <div className="fw-bold">Do Not Call Scrubbing</div>
              <p>
                Overage controls for do not call scrubbing only apply to scrubs
                done via Web or SFTP. They do not apply to scrubs done via API.
              </p>
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  {...register("checkboxes.warnAfterScrubsReaches")}
                  className="me-2 form-check-input"
                  data-default="false"
                />
                <span>
                  Show and E-mail warning after this many records scrubbed
                  (monthly):
                </span>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ maxWidth: "150px" }}
                  {...register("positiveValues.warnAfterScrubsReaches")}
                  disabled={!watch("checkboxes.warnAfterScrubsReaches")}
                  step="1"
                />
              </label>

              <label className="d-flex align-items-center w-100">
                <input
                  type="checkbox"
                  {...register("checkboxes.blockAfterScrubsReaches")}
                  className="me-2 form-check-input"
                  data-default="false"
                />
                <span>Block scrubbing after this many records (monthly):</span>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ maxWidth: "150px" }}
                  {...register("positiveValues.blockAfterScrubsReaches")}
                  disabled={!watch("checkboxes.blockAfterScrubsReaches")}
                  step="1"
                />
              </label>

              {/*Reassigned Products */}
              {data.acctInfo.hasAccountFeatureTCPAAuthority && (
                <>
                  <div className="mt-3 fw-bold">Reassigned Authority</div>
                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      {...register(
                        "checkboxes.tcpaAuthorityCountBeforeSendEmail",
                      )}
                      className="me-2 form-check-input"
                      data-default="false"
                    />
                    <span>
                      Show and e-mail warnings after this many Reassigned
                      Authority records (monthly):
                    </span>
                    <input
                      type="text"
                      className="form-control ms-2"
                      style={{ maxWidth: "150px" }}
                      {...register(
                        "positiveValues.tcpaAuthorityCountBeforeSendEmail",
                      )}
                      disabled={
                        !watch("checkboxes.tcpaAuthorityCountBeforeSendEmail")
                      }
                      step="1"
                    />
                  </label>

                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      {...register(
                        "checkboxes.tcpaAuthorityAllowedCountThisMonth",
                      )}
                      className="me-2 form-check-input"
                      data-default="false"
                    />
                    <span>
                      Block Reassigned Authority after this many records
                      (monthly) :
                    </span>
                    <input
                      type="text"
                      className="form-control ms-2"
                      style={{ maxWidth: "150px" }}
                      {...register(
                        "positiveValues.tcpaAuthorityAllowedCountThisMonth",
                      )}
                      disabled={
                        !watch("checkboxes.tcpaAuthorityAllowedCountThisMonth")
                      }
                      step="1"
                    />
                  </label>
                </>
              )}
              {data.acctInfo.hasAccountFeatureTCPAAuthorityPlus && (
                <>
                  <div className="mt-3 fw-bold">Reassigned AuthorityPlus</div>

                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      {...register(
                        "checkboxes.tcpaAuthorityPlusCountBeforeSendEmail",
                      )}
                      className="me-2 form-check-input"
                      data-default="false"
                    />
                    <span>
                      Show and e-mail warnings after this many Reassigned
                      AuthorityPlus records (monthly):
                    </span>
                    <input
                      type="text"
                      className="form-control ms-2"
                      style={{ maxWidth: "150px" }}
                      {...register(
                        "positiveValues.tcpaAuthorityPlusCountBeforeSendEmail",
                      )}
                      disabled={
                        !watch(
                          "checkboxes.tcpaAuthorityPlusCountBeforeSendEmail",
                        )
                      }
                      step="1"
                    />
                  </label>
                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      {...register(
                        "checkboxes.tcpaAuthorityPlusAllowedCountThisMonth",
                      )}
                      className="me-2 form-check-input"
                      data-default="false"
                    />
                    <span>
                      Block Reassigned Authority Plus after this many records
                      (monthly):
                    </span>
                    <input
                      type="text"
                      className="form-control ms-2"
                      style={{ maxWidth: "150px" }}
                      {...register(
                        "positiveValues.tcpaAuthorityPlusAllowedCountThisMonth",
                      )}
                      disabled={
                        !watch(
                          "checkboxes.tcpaAuthorityPlusAllowedCountThisMonth",
                        )
                      }
                      step="1"
                    />
                  </label>
                </>
              )}
              {data.acctInfo.hasAccountFeatureBasicRND && (
                <>
                  <div className="mt-3 fw-bold">Basic RND</div>
                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      {...register("checkboxes.basicRNDCountBeforeSendEmail")}
                      className="me-2 form-check-input"
                      data-default="false"
                    />
                    <span>
                      Show and e-mail warning after this many Basic RND records
                      (monthly):
                    </span>
                    <input
                      type="text"
                      className="form-control ms-2"
                      style={{ maxWidth: "150px" }}
                      {...register(
                        "positiveValues.basicRNDCountBeforeSendEmail",
                      )}
                      disabled={
                        !watch("checkboxes.basicRNDCountBeforeSendEmail")
                      }
                      step="1"
                    />
                  </label>

                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      {...register("checkboxes.basicRNDAllowedCountThisMonth")}
                      className="me-2 form-check-input"
                      data-default="false"
                    />
                    <span>
                      Block Basic RND after this many records (monthly):
                    </span>
                    <input
                      type="text"
                      className="form-control ms-2"
                      style={{ maxWidth: "150px" }}
                      {...register(
                        "positiveValues.basicRNDAllowedCountThisMonth",
                      )}
                      disabled={
                        !watch("checkboxes.basicRNDAllowedCountThisMonth")
                      }
                      step="1"
                    />
                  </label>
                </>
              )}

              {/* Do not call policies */}
              <div className="mt-3 fw-bold">Do Not Call Policies</div>
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  {...register("checkboxes.warnAfterPoliciesSentReaches")}
                  className="me-2 form-check-input"
                  data-default="false"
                />
                <span>
                  Show and E-mail warning after this many policies sent
                  (monthly):
                </span>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ maxWidth: "150px" }}
                  {...register("positiveValues.warnAfterPoliciesSentReaches")}
                  disabled={!watch("checkboxes.warnAfterPoliciesSentReaches")}
                  step="1"
                />
              </label>
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  {...register("checkboxes.blockAfterPoliciesSentReaches")}
                  className="me-2 form-check-input"
                  data-default="false"
                />
                <span>
                  Block sending policies after this many sent (monthly):
                </span>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ maxWidth: "150px" }}
                  {...register("positiveValues.blockAfterPoliciesSentReaches")}
                  disabled={!watch("checkboxes.blockAfterPoliciesSentReaches")}
                  step="1"
                />
              </label>
            </div>

            <label className="w-100">
              <div className="fw-bold mt-2 mb-1">
                E-mail all overage warnings to:
              </div>
              <textarea
                className="form-control"
                rows={2}
                {...register("settings.warningRecipients")}
              />
            </label>
          </div>
        </div>

        {/* Exclude mobile to mobile */}
        <div className="card mt-3">
          <div className="card-header">Reassigned Products</div>
          <div className="card-body">
            <label>
              <input
                type="checkbox"
                className="form-check-input"
                {...register("settings.excludeMobileToMobilePorts")}
                data-default="false"
              />
              <span className="ps-2">
                Exclude Mobile to Mobile from Reassigned Authority and
                Reassigned AuthorityPlus
              </span>
            </label>
          </div>
        </div>

        <div className="mt-3">
          <button type="submit" className="btn btn-primary">
            {!saveInProgress ? (
              "Save Changes"
            ) : (
              <SaveInProgress isVisible={saveInProgress} />
            )}
          </button>

          <button
            type="button"
            className="btn btn-secondary ms-2"
            onClick={handleReset}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-secondary ms-2"
            onClick={resetToPlanDefaults}
          >
            Reset to Plan Defaults
          </button>
        </div>
      </form>
      <div className="pd-5">&nbsp;</div>
      <div className="pd-5">&nbsp;</div>
      <div className="d-none debug-info">
        <h3>Debug Information</h3>
        <pre>{JSON.stringify(watch("checkboxes"), null, 2)}</pre>
        <pre>{JSON.stringify(watch("positiveValues"), null, 2)}</pre>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>
  );
};

export default AccountSettings;
