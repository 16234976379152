import { useState, useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import axios, { AxiosProgressEvent } from "axios";
import { useUploadListContext } from "src/contexts/UploadListContext";
import reassignedStyles from "./ReassignedScrub.module.css";
import ReassignedScrubListItem from "./ReassignedScrubListItem";
import { HttpMethod, callApi } from "src/services/apiService";
import {
  ReassignedUploadPermissionsDTO,
  ShowWarningOrBlockScrubbingDTO,
} from "./Common";
import scrubWarningStyles from "src/components/ScrubWarnings.module.css";
import { WarningComponent } from "./Components/Warning";
import { ReassignedProducts } from "./Components/ReassignedProduct";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

function getBlockedMessage(
  productType: ReassignedProducts,
  scrubsCount: string,
): string {
  switch (productType) {
    case ReassignedProducts.TCPAReassignedAuthority:
      return `Warning Reassigned Authority upload is disabled : Your reassigned block threshold has been reached. Excceded the scrub limit by ${scrubsCount} this month`;
    case ReassignedProducts.EnhancedRND:
      return `Warning Reassigned Authority Plus upload is disabled : Your reassigned block threshold has been reached. Excceded the scrub limit by ${scrubsCount} this month`;
    case ReassignedProducts.BasicRND:
      return `Warning Basic RND upload is disabled : Your reassigned block threshold has been reached. Excceded the scrub limit by ${scrubsCount} this month`;
    default:
      return "Warning: Unknown product type.";
  }
}
function getWarningMessage(
  productType: ReassignedProducts,
  scrubsCount: string,
): string {
  switch (productType) {
    case ReassignedProducts.TCPAReassignedAuthority:
      return `Warning Reassigned Authority : You have met or exceeded ${scrubsCount} scrubs this month.`;
    case ReassignedProducts.EnhancedRND:
      return `Warning Reassigned Authority Plus: You have met or exceeded ${scrubsCount} scrubs this month.`;
    case ReassignedProducts.BasicRND:
      return `Warning Basic RND: You have met or exceeded ${scrubsCount} scrubs this month.`;
    default:
      return "Warning: Unknown product type.";
  }
}
const warningNoReassignedProduct =
  "Warning: You are not subscribed to any Reassigned Products. Please contact your Account Representative to subscribe and gain access to these options.";

export const ReassignedScrub = () => {
  const { campaignId } = useUploadListContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { acctId: acctIdSlice, loginId: loginIdSlice } =
    useAppSelector(selectAuth);
  const [loginId, setLoginId] = useState(loginIdSlice);
  const [isUploading, setIsLoading] = useState(false);
  const [uploadPermission, setUploadPermission] = useState(false);
  const [basicRNDPermission, setBasicRNDPermission] = useState(false);
  const [tcpaAuthorityPlusPermission, setTCPAAuthorityPlusPermission] =
    useState(false);
  const [tcpaAuthorityPermission, setTCPAAuthorityPermission] = useState(false);

  const [reassignedAuthorityOverage, setReassignedAuthorityOverage] =
    useState<ShowWarningOrBlockScrubbingDTO>();
  const [reassignedAuthorityPlusOverage, setReassignedAuthorityPlusOverage] =
    useState<ShowWarningOrBlockScrubbingDTO>();
  const [basicRNDOverage, setBasicRNDOverage] =
    useState<ShowWarningOrBlockScrubbingDTO>();

  const styles = { ...reassignedStyles, ...scrubWarningStyles };
  const blockedMsgTCPAAuthority = useMemo(() => {
    return getBlockedMessage(
      ReassignedProducts.TCPAReassignedAuthority,
      reassignedAuthorityOverage?.blockOverageCount!,
    );
  }, [reassignedAuthorityOverage?.blockOverageCount]);

  const blockedMsgTCPAAuthorityPlus = useMemo(() => {
    return getBlockedMessage(
      ReassignedProducts.EnhancedRND,
      reassignedAuthorityPlusOverage?.blockOverageCount!,
    );
  }, [reassignedAuthorityPlusOverage?.blockOverageCount]);

  const blockedMsgBasicRND = useMemo(() => {
    return getBlockedMessage(
      ReassignedProducts.BasicRND,
      basicRNDOverage?.blockOverageCount!,
    );
  }, [basicRNDOverage?.blockOverageCount]);

  const warningMsgTCPAAuthority = useMemo(() => {
    return getWarningMessage(
      ReassignedProducts.TCPAReassignedAuthority,
      reassignedAuthorityOverage?.warningOverageCount!,
    );
  }, [reassignedAuthorityOverage?.warningOverageCount]);

  const warningMsgTCPAAuthorityPlus = useMemo(() => {
    return getWarningMessage(
      ReassignedProducts.EnhancedRND,
      reassignedAuthorityPlusOverage?.warningOverageCount!,
    );
  }, [reassignedAuthorityPlusOverage?.warningOverageCount]);

  const warningMsgBasicRND = useMemo(() => {
    return getWarningMessage(
      ReassignedProducts.BasicRND,
      basicRNDOverage?.warningOverageCount!,
    );
  }, [basicRNDOverage?.warningOverageCount]);

  const getSelectedProduct = () => {
    if (tcpaAuthorityPermission) return "1";
    if (tcpaAuthorityPlusPermission) return "2";
    if (basicRNDPermission) return "3";
    return ""; // No selection if all are false
  };
  const [selectedReassignedProduct, setSelectedReassignedProduct] =
    useState<string>(getSelectedProduct());

  const handleProductChange = (product: string) => {
    setSelectedReassignedProduct(product);
  };
  useEffect(() => {
    setLoginId(loginIdSlice);
  }, []);

  useEffect(() => {
    const fetchUploadFileAccess = async () => {
      try {
        const apiUrl = `sessions/ReassignedCheckPermissions?campaignId=${campaignId}`;
        const response: ReassignedUploadPermissionsDTO = await callApi(
          apiUrl,
          HttpMethod.GET,
        );
        setUploadPermission(response.scrubUploadPermission);
        setBasicRNDPermission(response.basicRNDPermission);
        setTCPAAuthorityPlusPermission(response.tcpaAuthorityPlusPermission);
        setTCPAAuthorityPermission(response.tcpaAuthorityPermission);

        if (response.basicRNDPermission) {
          handleProductChange("3");
        }
        if (response.tcpaAuthorityPlusPermission) {
          handleProductChange("2");
        }
        if (response.tcpaAuthorityPermission) {
          handleProductChange("1");
        }
        if (response.authorityOverage) {
          setReassignedAuthorityOverage(response.authorityOverage);
        }
        if (response.authorityPlusOverage) {
          setReassignedAuthorityPlusOverage(response.authorityPlusOverage);
        }
        if (response.basicRNDOverage) {
          setBasicRNDOverage(response.basicRNDOverage);
        }
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    setIsLoading(true);
    if (campaignId !== "") {
      fetchUploadFileAccess();
    }
    setIsLoading(false);
  }, [campaignId]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsLoading(true);
      const file = acceptedFiles[0];
      const acctId = acctIdSlice ?? "";

      const formData = new FormData();
      formData.append("campaignId", campaignId ?? "");
      formData.append("reassignedProduct", selectedReassignedProduct);
      formData.append("acctId", acctId);
      formData.append("files", file);

      const baseUrl = import.meta.env.VITE_APP_API_URL;
      const apiUrl = `${baseUrl}/Sessions/UploadFile`;
      const headers: { [key: string]: string } = {
        "Content-Type": "multipart/form-data",
      };

      if (loginId) {
        headers["loginId"] = loginId;
      }

      axios
        .post(apiUrl, formData, {
          headers: headers,
          withCredentials: true,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total || progressEvent.loaded; // Safe fallback
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / total,
            );
            setUploadProgress(percentCompleted);
          },
        })
        .then((response) => {
          setUploadProgress(0);
          setIsLoading(false);
        })
        .catch((error) => {
          setUploadProgress(0);
          setIsLoading(false);
        });
    },
    [campaignId, loginId, selectedReassignedProduct],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  // To do: Only disable if the user has exceeded the block thresholds not warning
  const disableUpload =
    reassignedAuthorityOverage?.blockScrubbing &&
    reassignedAuthorityPlusOverage?.blockScrubbing &&
    basicRNDOverage?.blockScrubbing;

  return (
    <>
      {uploadPermission &&
        (basicRNDPermission ||
          tcpaAuthorityPermission ||
          tcpaAuthorityPlusPermission) && (
          <>
            <h2>Reassigned Scrub</h2>
            <div className="row">
              {/* Uplaod control */}
              <div className="col-md-12 col-lg-6">
                <span className="badge text-bg-warning fs-6 me-1">
                  Reassigned Product:
                </span>
                {tcpaAuthorityPermission && (
                  <label className="me-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="1"
                      name="reassignedProduct"
                      onChange={() => handleProductChange("1")}
                      checked={selectedReassignedProduct === "1"}
                      disabled={reassignedAuthorityOverage?.blockScrubbing}
                    />
                    <span className="ps-1">Authority</span>
                  </label>
                )}
                {tcpaAuthorityPlusPermission && (
                  <label className="me-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="2"
                      checked={selectedReassignedProduct === "2"}
                      name="reassignedProduct"
                      onChange={() => handleProductChange("2")}
                      disabled={reassignedAuthorityPlusOverage?.blockScrubbing}
                    />
                    <span className="ps-1">AuthorityPlus</span>
                  </label>
                )}
                {basicRNDPermission && (
                  <label className="me-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="3"
                      name="reassignedProduct"
                      onChange={() => handleProductChange("3")}
                      checked={selectedReassignedProduct === "3"}
                      disabled={basicRNDOverage?.blockScrubbing}
                    />
                    <span className="ps-1">Basic RND</span>
                  </label>
                )}

                {isUploading ? (
                  <div className="d-flex gap-2 mt-2 mb-3 flex-wrap">
                    <div
                      {...getRootProps()}
                      className={`col ${styles["upload-file-container"]}`}
                    >
                      <input {...getInputProps()} />
                      <i
                        className={`bi bi-hourglass-split ${styles["upload-list-icon"]}`}
                      ></i>
                      <span className="text-center">UPLOADING...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Upload should only be disalbed when the block threshold is reached. Not the warning threshold. */}
                    {disableUpload ? (
                      <>
                        <div className="alert alert-warning mt-3" role="alert">
                          Your reassigned block threshold has been reached.
                          Reassigned upload is disabled.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex gap-2 mt-2 mb-3 flex-wrap">
                          <div
                            {...getRootProps()}
                            className={`col ${styles["upload-file-container"]}`}
                          >
                            <input {...getInputProps()} />
                            <i
                              className={`bi bi-file-earmark-arrow-up ${styles["upload-list-icon"]}`}
                            ></i>
                            <span className="text-center">
                              UPLOAD REASSIGNED FILE
                            </span>
                          </div>
                        </div>

                        <button
                          className="download-file-available mt-3 d-none"
                          onClick={() => onDrop([])}
                        >
                          Upload Data
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>

              {/* File format instructions */}
              <div className="col-md-12 col-lg-6">
                <div className="card p-3 rounded">
                  <h4>File Format Instructions</h4>
                  <p>
                    Filenames must end with a .csv extension, include a header
                    row, and have the first two columns as PhoneNumber and Date.
                    The file can then contain up to 25 additional columns with
                    any user-defined data to be included in the output.
                  </p>
                  <p>
                    Phone numbers must be 10 digits with no formatting
                    characters or leading 1s. Date should be the date after
                    which consent was given to contact the customer at the phone
                    number. Can be in format MM/DD/YYYY, MM/DD/YY, YYYY-MM-DD,
                    or YYYYMMDD.
                  </p>

                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">PhoneNumber</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>7074567890</td>
                        <td>2023-05-01</td>
                      </tr>
                      <tr>
                        <td>5417543010</td>
                        <td>2022-10-08</td>
                      </tr>
                      <tr>
                        <td>8663625478</td>
                        <td>2024-01-01</td>
                      </tr>
                    </tbody>
                  </table>

                  <a
                    href="/fileformats/ExampleReassignedFileFormat.csv"
                    className="col-md-12 btn btn-primary btn-sm"
                    download="ExampleReassignedFileFormat.csv"
                  >
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                    Download Example File
                  </a>
                </div>
              </div>
            </div>
          </>
        )}

      {uploadPermission &&
        !basicRNDPermission &&
        !tcpaAuthorityPermission &&
        !tcpaAuthorityPlusPermission && (
          <WarningComponent message={warningNoReassignedProduct} />
        )}

      {reassignedAuthorityOverage?.showWarning && tcpaAuthorityPermission && (
        <WarningComponent message={warningMsgTCPAAuthority} />
      )}
      {reassignedAuthorityPlusOverage?.showWarning &&
        tcpaAuthorityPlusPermission && (
          <WarningComponent message={warningMsgTCPAAuthorityPlus} />
        )}
      {basicRNDOverage?.showWarning && basicRNDPermission && (
        <WarningComponent message={warningMsgBasicRND} />
      )}

      {reassignedAuthorityOverage?.blockScrubbing &&
        tcpaAuthorityPermission && (
          <WarningComponent message={blockedMsgTCPAAuthority} />
        )}
      {reassignedAuthorityPlusOverage?.blockScrubbing &&
        tcpaAuthorityPlusPermission && (
          <WarningComponent message={blockedMsgTCPAAuthorityPlus} />
        )}
      {basicRNDOverage?.blockScrubbing && basicRNDPermission && (
        <WarningComponent message={blockedMsgBasicRND} />
      )}

      {uploadProgress > 0 && (
        <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
          <div
            style={{
              height: "24px",
              backgroundColor: "blue",
              width: `${uploadProgress}%`,
            }}
          >
            {uploadProgress}%
          </div>
        </div>
      )}
      <ReassignedScrubListItem />
    </>
  );
};
export default ReassignedScrub;
