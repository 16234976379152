import * as React from "react";
import { useParams } from "react-router-dom";
import IframeComponent from "./IframeComponent";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

interface ContentEntry {
  url: string;
  title: string;
}

interface ContentMap {
  [key: string]: ContentEntry;
}

// Define the contentMap outside the component if it doesn't change
const contentMap: ContentMap = {
  "reports-policies-sent": {
    url: "https://classic.dncscrub.com/app/report/r-policies-sent.adp",
    title: "Reports - Policies Sent",
  },
  users: {
    url: "https://classic.dncscrub.com/app/users/?u-main=u-admin%2eadp%3fadd%3d1",
    title: "Users",
  },
  users1: {
    url: "https://classic.dncscrub.com/app/users/u-profile.adp",
    title: "Users",
  },
  trustcall: {
    url: "https://core.dncscrub.com/app/trustcall/monitor",
    title: "TrustCall Monitor",
  },
  "litigator-search": {
    url: "https://core.dncscrub.com/app/litigator/search/index",
    title: "Litigator Research",
  },
  "sales-production-report": {
    url: "https://core.dncscrub.com/app/sysadmin/salesproductionreport/index",
    title: "Sales Report",
  },
  sysadmin: {
    url: "https://classic.dncscrub.com/app/sysadmin/",
    title: "Sysadmin",
  },
  "reports-number-history": {
    url: "https://classic.dncscrub.com/app/report/r-number-history.adp",
    title: "Reports - Investigate Phone Number",
  },
  billing: {
    url: "https://classic.dncscrub.com/app/scripts/b-main",
    title: "Sysadmin",
  },
};

const getSlugForRole = (slug: string, roleId: number): string => {
  if (slug === "users" && roleId === 1) {
    return "users1"; // Redirect to "users1" if roleId is 1
  }
  return slug;
};

const Tcl: React.FC = () => {
  const { theme, roleId } = useAppSelector(selectAuth);

  const params = useParams<{ slug?: string }>();
  const { slug } = params;

  if (!slug || !contentMap[slug]) {
    return <div>Unauthorized</div>;
  }

  const slugForRole = getSlugForRole(slug, roleId);
  const { url, title } = contentMap[slugForRole];

  const urlWithTheme = url.includes("?")
    ? `${url}&theme=${theme}`
    : `${url}?theme=${theme}`;

  return (
    <IframeComponent
      title={title}
      src={`${urlWithTheme}`}
      style={{
        backgroundColor: "white",
        position: "absolute",
        left: "0",
        width: "100%",
        height: "calc(100vh - var(--height-of-top-nav-bar))",
        border: "0",
        zIndex: -1, // Ensures menu is visible on top
      }}
      allowFullScreen
    ></IframeComponent>
  );
};

export default Tcl;
