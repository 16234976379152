import { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Main from "./ProjectSettings/Main";
import RetroScrub from "./ProjectSettings/RetroScrub";
import EBRNotify from "./ProjectSettings/EBRNotify";
import CanadaDNC from "./ProjectSettings/CanadaDNC";
import TrainingReminder from "./ProjectSettings/TrainingReminder";
import Loading from "src/components/Loading";
import ICpaSection from "src/interfaces/ICpaSection";
import "./ProjectSettings.module.css";

const ProjectSettings = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [access, setAccess] = useState<ICpaSection>();
  const { projectId, campaignId } = useUploadListContext();

  useEffect(() => {
    async function fetchAccess() {
      if (projectId && campaignId) {
        try {
          const apiUrl = `main/Projects/GetAccess?projId=${projectId}&campaignId=${campaignId}`;
          const response = await callApi(apiUrl, HttpMethod.GET);
          setAccess(response);
        } catch (error) {
          console.error("Failed to fetch project access data:", error);
        }
      }
    }

    fetchAccess();
  }, [projectId, campaignId]);

  if (!access) return <Loading />;

  return (
    <>
      <h4>Project Settings (apply to all campaigns of this project)</h4>

      <div>
        <ul className="nav nav-tabs gap-4">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "home" ? "active" : ""}`}
              onClick={() => setActiveTab("home")}
              href="#home"
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "retroscrub" ? "active" : ""
              }`}
              onClick={() => setActiveTab("retroscrub")}
              href="#retroscrub"
            >
              RetroScrub
            </a>
          </li>
          {/* EBR Notify is deprecated as of 5/2/2024 <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "ebrnotify" ? "active" : ""
              }`}
              onClick={() => setActiveTab("ebrnotify")}
              href="#ebrnotify"
            >
              EBRNotify
            </a>
          </li> */}
          {access.canada && (
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "canadadnc" ? "active" : ""
                }`}
                onClick={() => setActiveTab("canadadnc")}
                href="#canadadnc"
              >
                CanadaDNC
              </a>
            </li>
          )}
          {access.training && (
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "trainingmaster" ? "active" : ""
                }`}
                onClick={() => setActiveTab("trainingmaster")}
                href="#trainingmaster"
              >
                TrainingMaster
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content">
          {activeTab === "home" && <Main />}
          {activeTab === "retroscrub" && <RetroScrub />}
          {activeTab === "ebrnotify" && <EBRNotify />}
          {activeTab === "canadadnc" && <CanadaDNC />}
          {activeTab === "trainingmaster" && <TrainingReminder />}
        </div>
      </div>
    </>
  );
};

export default ProjectSettings;
