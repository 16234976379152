import { MouseEvent } from "react";
import { useLocation } from "react-router-dom";
import { UploadListProvider } from "src/contexts/UploadListContext";
import UploadList from "./UploadList/UploadList";
import SubNav from "./SubNav";
import styles from "./Main.module.css";

import ProjectSettings from "./ProjectSettings";
import CampaignSettings from "./CampaignSettings";
import InternalDNC from "./InternalDNC/InternalDNCMain";
import EBRMaster from "./EBRMaster/EBRMasterMain";
import DNCPolicy from "./DNCPolicy";
import SendPolicy from "./SendPolicy";
import ExemptionMaster from "./ExemptionMaster";
import TreeView from "src/components/Main/TreeView";
import Breadcrumbs from "./Breadcrumbs";
import ScrubOptions from "./ProjectSettings/ScrubOptions";
import TrainingMaster from "./TrainingMaster";
import QuickScrub from "./UploadList/QuickScrub";
import QuickScrubResults from "./UploadList/QuickScrubResults";
import BackToTop from "src/components/BackToTop";
import CrossAccountInfo from "./CrossAccountInfo";
import ReassignedScrub from "./ReassignedScrub/ReassignedScrub";

const MainContent = () => {
  const location = useLocation();

  const renderComponent = () => {
    const pathSegment = location.pathname.split("/")[2];
    switch (pathSegment) {
      case "upload-list":
        return <UploadList />;
      case "project-settings":
        return <ProjectSettings />;
      case "campaign-settings":
        return <CampaignSettings />;
      case "internal-dnc":
        return <InternalDNC />;
      case "ebr-master":
        return <EBRMaster />;
      case "dnc-policy":
        return <DNCPolicy />;
      case "send-policy":
        return <SendPolicy />;
      case "exemption-master":
        return <ExemptionMaster />;
      case "scrub-options":
        return <ScrubOptions dataFromSender="Projects" />;
      case "training-master":
        return <TrainingMaster />;
      case "quickscrubcomponent":
        return <QuickScrub />;
      case "quickscrub":
        return <QuickScrubResults />;
      case "cross-account-info":
        return <CrossAccountInfo />;
      case "reassigned-scrub":
        return <ReassignedScrub />;
      default:
        return <UploadList />;
    }
  };

  /* Collapse the left-hand navbar when a link is clicked on small screens */
  const collapseTreeviewOnClick = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === "SPAN") {
      const navbar = document.getElementById("left-hand-navbar");
      if (navbar) {
        navbar.classList.remove("show");
      }
    }
  };

  return (
    <div className="d-flex">
      {/* First flexbox column */}
      <div
        className="campaign-sidebar"
        id="left-hand-navbar"
        onClick={collapseTreeviewOnClick}
      >
        <TreeView />
      </div>

      {/* Second flexbox column */}
      <div className="maint-content show-scroll-back-to-top">
        <Breadcrumbs />
        {renderComponent()}
      </div>
      <BackToTop />

      <div className={`d-flex ${styles["right-navbar"]}`}>
        <SubNav />
      </div>
    </div>
  );
};

const Main = () => {
  return <MainContent />;
};

export default Main;
