import { useEffect } from "react";
import { useLayoutContext } from "src/contexts/LayoutContext";
import IframeComponent from "src/components/IframeComponent";

const Index = () => {
  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Statutes");
      setTagline(
        "Explore statutes and regulations related to outbound calling.",
      );
    }
  }, [setHeading, setTagline]);
  return (
    <>
      <div className="callout">
        Since laws change frequently, please verify the information before using
        it in any legal context.
      </div>

      <IframeComponent
        src="https://classic.dncscrub.com/legal/statutes/index.adp?statutePopup=1"
        title="DNCScrub Statutes"
        style={{
          backgroundColor: "var(--neutral-light)", // Fixes appearance in dark mode
          width: "100%",
          height: "calc(100vh - 75px)",
          border: "0",
        }}
        allowFullScreen={false}
      />
    </>
  );
};

export default Index;
