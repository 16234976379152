import { useState, useEffect, useCallback } from "react";
import * as React from "react";
import Cookies from "js-cookie";
import Styles from "./ThemeSelector.module.css";
import { useAppDispatch } from "src/app/hooks";
import { setTheme } from "src/features/authSlice";

const defaultTheme = "auto";

const ThemeSelector: React.FC = () => {
  const [selectedTheme, setSelectedTheme] = useState<
    "light" | "dark" | "midnight" | "auto"
  >(defaultTheme);

  // A TypeScript type guard function to ensure the value is a valid theme icon key
  const isThemeIconKey = useCallback(
    (value: string): value is "light" | "dark" | "midnight" | "auto" => {
      return ["light", "dark", "midnight", "auto"].includes(value);
    },
    [],
  );

  const dispatch = useAppDispatch();

  const handleThemeChange = useCallback(
    (themeValue: string) => {
      Cookies.set("theme", themeValue, { expires: 365 * 10 });
      if (isThemeIconKey(themeValue)) {
        setSelectedTheme(themeValue);
      }

      let themeAttribute = themeValue;
      if (themeValue === "auto") {
        themeAttribute = window.matchMedia("(prefers-color-scheme: dark)")
          .matches
          ? "dark"
          : "light";
      }
      document.body.setAttribute("data-theme", themeAttribute);
      dispatch(setTheme(themeAttribute));

      // Midnight is based off of the dark theme. Rather then repeat
      // all the dark mode variables for it, set the base theme to dark.
      // We can use this same pattern to create variants of the light
      // theme.
      if (themeAttribute === "midnight" || themeAttribute === "dark") {
        document.body.setAttribute("base-theme", "dark");
      } else {
        document.body.setAttribute("base-theme", "none");
      }
    },
    [isThemeIconKey, dispatch],
  );

  useEffect(() => {
    const theme = Cookies.get("theme") || defaultTheme;
    if (isThemeIconKey(theme)) {
      handleThemeChange(theme);
    }
  }, [isThemeIconKey, handleThemeChange]);

  type ThemeIcons = {
    light: string;
    dark: string;
    midnight: string;
    auto: string;
  };

  const themeIcons: ThemeIcons = {
    light: "bi-sun-fill",
    dark: " bi-moon-fill",
    midnight: " bi-moon-stars-fill",
    auto: "bi-circle-half",
  };

  return (
    <div className="nav-link nav-item dropdown d-flex p-0">
      <button
        className={`btn btn-link dropdown-toggle d-flex align-items-center text-white fs-6 ${Styles["theme-dropdown-button"]}`}
        id="bd-theme"
        type="button"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        aria-label="Theme"
      >
        <i className={`bi ${themeIcons[selectedTheme]}`}></i>
        <span className="d-md-none ms-2" id="bd-theme-text">
          Toggle theme
        </span>
      </button>
      <ul
        className={`dropdown-menu dropdown-menu-end ${Styles["theme-selector-menu"]}`}
        aria-labelledby="bd-theme-text"
      >
        <li>
          <button
            type="button"
            className="dropdown-item d-flex align-items-center gap-2"
            data-bs-theme-value="light"
            aria-pressed={selectedTheme === "light"}
            onClick={() => handleThemeChange("light")}
          >
            <i className={`bi ${themeIcons["light"]}`}></i>
            Light
            {selectedTheme === "light" && <i className="bi bi-check2"></i>}
          </button>
        </li>
        <li>
          <button
            type="button"
            className="dropdown-item d-flex gap-2"
            data-bs-theme-value="dark"
            aria-pressed={selectedTheme === "dark"}
            onClick={() => handleThemeChange("dark")}
          >
            <i className={`bi ${themeIcons["dark"]}`}></i>
            Dark
            {selectedTheme === "dark" && <i className="bi bi-check2"></i>}
          </button>
        </li>
        <li>
          <button
            type="button"
            className="dropdown-item d-flex gap-2"
            data-bs-theme-value="midnight"
            aria-pressed={selectedTheme === "midnight"}
            onClick={() => handleThemeChange("midnight")}
          >
            <i className={`bi ${themeIcons["midnight"]}`}></i>
            Midnight
            {selectedTheme === "midnight" && <i className="bi bi-check2"></i>}
          </button>
        </li>
        <li>
          <button
            type="button"
            className="dropdown-item d-flex gap-2"
            data-bs-theme-value="auto"
            aria-pressed={selectedTheme === "auto"}
            onClick={() => handleThemeChange("auto")}
          >
            <i className={`bi ${themeIcons["auto"]}`}></i>
            Auto
            {selectedTheme === "auto" && <i className="bi bi-check2"></i>}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ThemeSelector;
