export interface IEbrRecord {
  ebrId: number;
  fullPhone: string;
  addedOn: string;
  dateOfLastContact: string;
  type: EbrType;
  referenceNum: string;
  brand: string;
  email: string;
}

export interface CpaSection {
  Add: boolean;
  Delete: boolean;
  Upload: boolean;
  Download: boolean;
  Undo: boolean;
  Select: boolean;
  Clear: boolean;
}

export enum EbrType {
  Sale = 1,
  Inquiry = 2,
  Permission = 3,
  RecentSale = 4,
  NewspaperTrial = 5,
}

export const EbrTypeLabels: { [key in EbrType]: string } = {
  [EbrType.Sale]: "Sale",
  [EbrType.Inquiry]: "Inquiry",
  [EbrType.Permission]: "Permission",
  [EbrType.RecentSale]: "Recent Sale",
  [EbrType.NewspaperTrial]: "Newspaper Trial",
};
