import { useState, useEffect, useCallback } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { ITreeNode } from "src/interfaces/ITreeNode";
import styles from "./TreeView.module.css";
import AddNewCampaign from "./AddNewCampaign";
import storageService from "src/services/storageService";
import parse from "html-react-parser";

const EXPANDED_NODES_KEY = "expandedNodes";

interface TreeNodeProps {
  node: ITreeNode;
  isHideInactiveChecked: boolean;
  addNewCampaign: boolean;
  children?: React.ReactNode; // For nested TreeNodes
}

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  isHideInactiveChecked,
  addNewCampaign,
  children,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(() => {
    const expandedNodes = new Set(
      storageService.getItem<string[]>(EXPANDED_NODES_KEY) || [],
    );
    return expandedNodes.has(node.key);
  });
  const {
    campaignId,
    setParentProjectId,
    setParentProjectName,
    setProjectId,
    setProjectName,
    setCampaignId,
    setCampaignName,
    treeData,
  } = useUploadListContext();

  const updateUrlWithCampaignId = useCallback(
    (campaignId: string) => {
      const pathSegments = window.location.pathname.split("/");

      const crossAccountIndex = pathSegments.indexOf("cross-account-info");

      if (crossAccountIndex !== -1) {
        const lastSegment = pathSegments[pathSegments.length - 1];
        if (!isNaN(Number(lastSegment))) {
          pathSegments[crossAccountIndex] = "upload-list";
          pathSegments[pathSegments.length - 1] = campaignId.toString();
        } else {
          pathSegments[crossAccountIndex] = "upload-list";
          pathSegments.push(campaignId.toString());
        }
      } else {
        if (
          pathSegments.length > 0 &&
          !isNaN(Number(pathSegments[pathSegments.length - 1]))
        ) {
          pathSegments[pathSegments.length - 1] = campaignId.toString();
        } else {
          pathSegments.push(campaignId.toString());
        }
      }

      const newPath = pathSegments.join("/");
      navigate(newPath);
      //window.history.pushState({ path: newPath }, "", newPath);
    },
    [navigate],
  );

  useEffect(() => {
    const initializeContext = async () => {
      const pathSegments = window.location.pathname
        .split("/")
        .filter((segment) => segment !== ""); // Filter out empty segments
      const lastSegment = pathSegments[pathSegments.length - 1];
      const isCampaignIdInUrl = lastSegment && !isNaN(Number(lastSegment));

      if (isCampaignIdInUrl) {
        const initialCampaignId = lastSegment;
        if (initialCampaignId !== campaignId) {
          const apiUrl = `main/Campaigns/GetCampaignInfo?campaignId=${initialCampaignId}`;
          const response = await callApi(apiUrl, HttpMethod.GET);
          if (!response) {
            console.error("Failed to fetch data");
            return;
          }
          if (response.message === "No access") {
            navigate("/main/noaccess");
          } else {
            setProjectId(response.projId);
            setProjectName(response.projectName);
            setCampaignId(response.campaignId);
            setCampaignName(response.campaignName);
          }
        }
      } else {
        setProjectId(treeData[0].key);
        setProjectName(treeData[0].label);
        setCampaignId(treeData[0].defaultCampaignId.toString());
        setCampaignName("Default Campaign");
        updateUrlWithCampaignId(campaignId.toString());
      }
    };

    if (node.key === treeData[0]?.key) {
      initializeContext();
    }
  }, [
    node.key,
    treeData,
    campaignId,
    navigate,
    setProjectId,
    setProjectName,
    setCampaignId,
    setCampaignName,
    updateUrlWithCampaignId,
  ]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    const expandedNodes = new Set(
      storageService.getItem<string[]>(EXPANDED_NODES_KEY) || [],
    );
    if (isOpen) {
      expandedNodes.delete(node.key);
    } else {
      expandedNodes.add(node.key);
    }
    storageService.setItem(EXPANDED_NODES_KEY, Array.from(expandedNodes));
  };

  const handleNodeClick = (key: string, name: string, type: string) => {
    // Abhi - Expand the project when it is clicked
    if (!isOpen) {
      toggleOpen();
    }
    const breadcrumbs = buildBreadcrumbs(key, type);

    if (breadcrumbs.length > 0) {
      const parentNode = breadcrumbs[0];
      if (parentNode.type === "CrossAcc") {
        navigate(`/main/cross-account-info`);
      } else {
        if (breadcrumbs.length === 1) {
          const projectNode = breadcrumbs[0];
          if (projectNode.type === "Project") {
            // check if project is a cross account project
            const firstChild = projectNode.children && projectNode.children[0];
            if (firstChild && firstChild.type === "CrossAcc") {
              // user has clicked on the cross account project's company name
              setParentProjectId(projectNode.key);
              setParentProjectName(projectNode.label);
              setProjectId("");
              setProjectName("");
              setCampaignId("");
              setCampaignName("");
              navigate(`/main/cross-account-info`);
            } else {
              setParentProjectId("");
              setParentProjectName("");
              setProjectId(projectNode.key);
              setProjectName(projectNode.label);
              setCampaignId(projectNode.defaultCampaignId.toString());
              setCampaignName("Default Campaign");
              updateUrlWithCampaignId(projectNode.defaultCampaignId.toString());
            }
          }
        } else if (breadcrumbs.length === 2) {
          const parentProjectNode = breadcrumbs[0];
          const projectNode = breadcrumbs[1];

          if (projectNode.type === "Campaign") {
            const campaignId = key;
            const campaignName = name;

            setParentProjectId("");
            setParentProjectName("");
            setProjectId(parentProjectNode.key);
            setProjectName(parentProjectNode.label);
            setCampaignId(campaignId);
            setCampaignName(campaignName);
            updateUrlWithCampaignId(campaignId);
          } else {
            setParentProjectId(parentProjectNode.key);
            setParentProjectName(parentProjectNode.label);
            setProjectId(projectNode.key);
            setProjectName(projectNode.label);
            setCampaignId(projectNode.defaultCampaignId.toString());
            setCampaignName("Default Campaign");
            updateUrlWithCampaignId(projectNode.defaultCampaignId.toString());
          }
        } else if (breadcrumbs.length === 3) {
          // always cross account
          const rootNode = breadcrumbs[0];
          const midNode = breadcrumbs[1];
          const campaignNode = breadcrumbs[2];

          setParentProjectId(rootNode.key);
          setParentProjectName(rootNode.label);
          setProjectId(midNode.key);
          setProjectName(midNode.label);
          setCampaignId(campaignNode.key);
          setCampaignName(campaignNode.label);
          updateUrlWithCampaignId(campaignNode.key);
        }
      }
    }
  };

  const buildBreadcrumbs = (key: string, type: string): ITreeNode[] => {
    const breadcrumbs: ITreeNode[] = [];
    const traverse = (nodes: ITreeNode[], key: string): boolean => {
      for (const node of nodes) {
        if (node.key === key) {
          breadcrumbs.push(node);
          return true;
        }
        if (node.children && traverse(node.children, key)) {
          breadcrumbs.push(node);
          return true;
        }
      }
      return false;
    };
    traverse(treeData, key);
    return breadcrumbs.reverse();
  };

  const getIconClass = () => {
    if (node.type === "Campaign") {
      return styles["campaign-icon"];
    }
    return isOpen ? styles["folder-icon-closed"] : styles["folder-icon-open"];
  };

  const hasFirstChildCrossAccount = () => {
    return node.children && node.children[0]?.type === "CrossAcc";
  };

  return (
    <div style={{ marginLeft: 5 }}>
      <div
        className={`${styles["show-hand"]}`}
        onClick={() => handleNodeClick(node.key, node.label || "", node.type)}
        style={{ cursor: "pointer" }}
      >
        <button
          onClick={toggleOpen}
          className="btn btn-link p-0"
          title={node.label || ""}
        >
          <i className={getIconClass()} />
        </button>
        <span className={styles["treeview-project"]}>
          {parse(node.label || "")}
        </span>
      </div>

      {isOpen && (
        <div>
          {children}
          {node.type !== "Campaign" &&
            !hasFirstChildCrossAccount() &&
            addNewCampaign && <AddNewCampaign projectId={node.key} />}
        </div>
      )}
    </div>
  );
};

export default TreeNode;
