export const formatNumber = (num: number) => {
  return new Intl.NumberFormat("en-US").format(num);
};

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString);

  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(2)}`;
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${minutes}:${seconds} ${amPm}`;

  return `${formattedDate} ${formattedTime}`;
}
