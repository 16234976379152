import styles from "./Login.module.css";
/* Importing CSS here vs using link element in
   html avoids flash of unstyled content (FOUC) */

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "src/services/authApi";
import { useAppDispatch } from "src/app/hooks";
import { setAuthInfo } from "src/features/authSlice";
import Cookies from "js-cookie";
import Warning from "src/components/Warning";
import { useForm } from "react-hook-form";
import storageService from "src/services/storageService";
import SaveInProgress from "src/components/SaveInProgress";
import { useSearchParams } from "react-router-dom";

type LoginFormFields = {
  acctId: string;
  username: string;
  password: string;
};

const Login = () => {
  const [saveInProgress, setSaveInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginFormFields>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const navigate = useNavigate();
  const [loginUser, { data, isSuccess, isError, error }] =
    useLoginUserMutation();
  const [showWarning, setShowWarning] = useState("");

  // Allow for displaying a message on login about
  // what happened to session. Eg they were logged out
  // for inactivity.
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const reason = decodeURIComponent(searchParams.get("reason") || "");
    if (reason) {
      switch (reason.toLowerCase()) {
        case "expired":
          setShowWarning("Your session has expired. \nPlease log in again.");
          break;
        case "selfregistered":
          setShowWarning(
            "Your new account was created. \nPlease login with the username and password you chose.",
          );
          break;
        default:
          setShowWarning("You have been logged out.");
          break;
      }
    }
  }, [searchParams]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue("acctId", Cookies.get("acctId") || "");
  }, [setValue]);

  const handleLogin = async (formData: LoginFormFields) => {
    setSaveInProgress(true);
    const { acctId, username, password } = formData;
    if (acctId && username && password) {
      //Abhi: Adding backup uppercase conversion when mixed case is already prefilled without typing
      await loginUser({ acctId: acctId.toUpperCase(), username, password });
    }
    setSaveInProgress(false);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setAuthInfo({
          acctId: data.acctId,
          acctName: data.acctName,
          username: data.username,
          fullName: data.fullName,
          quickScrub: data.quickScrub,
          role: data.role,
          isSysadmin: data.isSysadmin,
          loginId: data.loginId,
          csrfToken: data.csrfToken,
          hasTrainingFeature: data.hasTrainingFeature,
          homePage: data.homePage,
          acctMessage: data.acctMessage,
        }),
      );

      //later need to add the default home page for agents
      let defaultPage = data.role === 4 ? "/login/self-register" : "/home";

      // Navigate to the last location
      const lastLocationKey = `lastLocation_${data.acctId}_${data.username}`;
      let lastLocation = storageService.getItem<string>(lastLocationKey);
      if (lastLocation?.toLowerCase().startsWith("/login")) {
        storageService.removeItem(lastLocationKey);
        lastLocation = null;
      }

      if (lastLocation && data.role !== 4) {
        navigate(lastLocation);
      } else {
        navigate(defaultPage);
      }
    } else if (isError && error) {
      const errorMessage = (error as any).data?.message || "Login failed";
      setShowWarning(errorMessage);
    }
  }, [isSuccess, isError, error, data, dispatch, navigate]);

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Warning message={showWarning} />
      <input
        {...register("acctId", {
          required: "Account ID is required",
          onChange: (e) => setValue("acctId", e.target.value.toUpperCase()), // Abhi: Convert to uppercase as api does not like mixed case
        })}
        className={`${styles["form-control"]} ${errors.acctId ? styles["error-input-box"] : ""}`}
        placeholder="Account"
      />
      {errors.acctId?.message && (
        <div className={styles["error-message"]}>{errors.acctId.message}</div>
      )}

      <input
        {...register("username", { required: "Username is required" })}
        className={`${styles["form-control"]} ${errors.username ? styles["error-input-box"] : ""}`}
        placeholder="Username"
        autoComplete="username"
      />
      {errors.username?.message && (
        <div className={styles["error-message"]}>{errors.username.message}</div>
      )}

      <input
        {...register("password", { required: "Password is required" })}
        className={`${styles["form-control"]} ${errors.password ? styles["error-input-box"] : ""}`}
        placeholder="Password"
        type="password"
        autoComplete="current-password"
      />
      {errors.password?.message && (
        <div className={styles["error-message"]}>{errors.password.message}</div>
      )}

      <button className={styles["login-btn"]} type="submit">
        <SaveInProgress isVisible={saveInProgress} loadingText="" />
        {!saveInProgress && <>Login</>}
      </button>
      <div className="mt-3 mb-0">
        <Link to="/login/reset-your-password">Forgot your password?</Link>
      </div>
      <div className="mt-3 mb-0">
        <a href="https://classic.dncscrub.com/">Switch to DNCScrub Classic</a>
      </div>
    </form>
  );
};

export default Login;
