interface ICpaSection {
  [key: string]: boolean;
}

export default ICpaSection;

function getCaseInsensitiveValueFromCpaSection(
  section: ICpaSection,
  key: string,
): boolean | undefined {
  if (!section || !key) return undefined;

  const lowerCaseKey = key.toLowerCase();

  for (const sectionKey in section) {
    if (sectionKey.toLowerCase() === lowerCaseKey) {
      // Match found! Return value: ${section[sectionKey]}
      return section[sectionKey];
    }
  }

  // No match found, return undefined
  return undefined;
}

export { getCaseInsensitiveValueFromCpaSection };
