import { useEffect } from "react";
import * as React from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import SafeHtml from "src/components/SafeHtml";
import Citation from "src/components/ComplianceGuide/Citation";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";

const IDDisclosure: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className="regulatory-contents">
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }
  const rec = JSON.parse(data)[0];
  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      <div className="regulatory-contents">
        <table className="table table-border content-width-table mt-3">
          <tbody>
            <tr>
              <td>Has ID Disclosure State Law</td>
              <td>{rec.idStateLaw ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>Law is stricter than Fed</td>
              <td>{rec.idStricterThanFed ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>Permission to Continue</td>
              <td>{rec.idPermToContinue ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>Affirmative DNC Right Disclosure</td>
              <td>{rec.idAffirmativeDncRight ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>18-year-old Inquiry Rule</td>
              <td>{rec.id18yearOldInquiry ? "Yes" : "No"}</td>
            </tr>
          </tbody>
        </table>

        <RegulatoryGuideSection
          label="Compliance Rule"
          value={<SafeHtml html={rec.complianceRule} />}
        />
        <RegulatoryGuideSection
          label="Citation"
          value={
            <Citation
              statuteId={rec.complianceRuleStatuteId}
              statuteName={rec.complianceRuleStatuteNumber}
              ref1={rec.complianceRuleRef1}
              ref2={rec.complianceRuleRef2}
              ref3={rec.complianceRuleRef3}
            />
          }
        />
      </div>
    </>
  );
};

export default IDDisclosure;
