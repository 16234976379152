import { useState, useEffect, useRef } from "react";
import * as React from "react";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";
import { CpaSection } from "src/interfaces/Main/EBRMaster";
import Mapping from "./Mapping";
import DownloadEBRDatabase from "./DownloadEBRDatabase";
import SearchEBRDatabase from "./SearchEBRDatabase";
import UndoEBR from "./UndoEBR";
import AddEBRRecord from "./AddEBRRecord";

const EBRMasterMain = () => {
  const { projectId, campaignId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(false);
  const [access, setAccess] = useState<CpaSection>();
  const undoEBRRef = useRef<{ updateUndoDetails: () => void } | null>(null);
  const [showOnlyMapping, setShowOnlyMapping] = useState(false);
  const [isForTelephoneCompany, setIsForTelephoneCompany] = useState(false);
  const [isForAutoSales, setIsForAutoSales] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (projectId && campaignId) {
      fetchSettings();
      updateUndoDetails();
    }
    // eslint-disable-next-line
  }, [projectId, campaignId]);

  const updateUndoDetails = () => {
    if (undoEBRRef.current) {
      undoEBRRef.current.updateUndoDetails();
    }
  };

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `main/ebrmaster/?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        //first check for page access and redirect if not allowed
        if (!response.cpaSection.Add) {
          window.location.href = "/main/upload-list";
        }
        setAccess(response.cpaSection);
        setIsForTelephoneCompany(response.ProjectSettings.Tele);
        setIsForAutoSales(response.ProjectSettings.Auto);
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleMappingStepChange = (step: number | null | undefined) => {
    setShowOnlyMapping(step ? true : step === 0 ? false : true);
    if (step === 1) {
      scrollToTop();
    }
    //console.log(`current step: ${step}`);
  };

  const handleCheckboxChange = async (
    checkboxId: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    try {
      if (checkboxId === 1) {
        setIsForTelephoneCompany(isChecked);
      } else if (checkboxId === 2) {
        setIsForAutoSales(isChecked);
      }

      const apiUrl = `main/ebrmaster/toggle?projId=${projectId}&campaignId=${campaignId}&setting=${checkboxId}&status=${isChecked}`;
      const response = await callApi(apiUrl, HttpMethod.GET);

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      // Handle successful API call here, if necessary
      console.log(
        `API call successful for checkbox ${checkboxId}`,
        await response.json(),
      );
    } catch (error) {
      console.error(`API call failed for checkbox ${checkboxId}`, error);
    }
  };

  if (isLoading || access === undefined) {
    return <Loading />;
  }

  return (
    <>
      {!showOnlyMapping && (
        <>
          <div className="d-flex justify-content-between align-items-start">
            {/* Add hoc import */}
            <div className="card col-6 ">
              <div className="card-body">
                <b>EBRMaster&#8482;</b> (also known as ConsentMaster) allows
                IntelliScrub&#8482; to take advantage of your existing business
                relationship and consent status with customers while scrubbing
                call lists.
              </div>
            </div>
            {access.Undo && <UndoEBR ref={undoEBRRef} />}
          </div>

          <div className="">
            <div className="mt-3 card col-12">
              <div className="card-header">
                Settings that apply to all EBR Records in Project
              </div>
              <div className="card-body">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isForTelephoneCompany}
                      onChange={(e) => handleCheckboxChange(1, e)}
                    />
                    <span className="ps-2">
                      This Project is for a telephone company (Idaho does not
                      recognize telco EBR)
                    </span>
                  </label>
                </div>
                <label>
                  <input
                    type="checkbox"
                    checked={isForAutoSales}
                    className="form-check-input"
                    onChange={(e) => handleCheckboxChange(2, e)}
                  />
                  <span className="ps-2">
                    This Project is for automobile sales completed face-to-face
                    (lifts Louisiana's 6-month EBR limit)
                  </span>
                </label>
              </div>
            </div>

            <AddEBRRecord
              access={access}
              fetchUndoDetails={updateUndoDetails}
            />
          </div>

          {access.Delete && (
            <SearchEBRDatabase
              fetchUndoDetails={updateUndoDetails}
              access={access}
            />
          )}
        </>
      )}

      {access.Upload && <Mapping onStepChange={handleMappingStepChange} />}
      {!showOnlyMapping && (
        <DownloadEBRDatabase
          fetchUndoDetails={updateUndoDetails}
          access={access}
        />
      )}
    </>
  );
};

export default EBRMasterMain;
