import * as React from "react";
import { useLocation } from "react-router-dom";

interface ErrorProps {
  message?: string;
}

const Error: React.FC<ErrorProps> = ({ message }) => {
  const location = useLocation();
  const errorMessage =
    message ||
    location.state?.message ||
    "You do not have access to this campaign";

  return (
    <div className="p-3">
      <i className="h1 text-danger bi bi-exclamation-triangle pe-2"></i>
      <b>{errorMessage}</b>
    </div>
  );
};

export default Error;
