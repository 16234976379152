import {
  Fragment,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { UndoDTO } from "src/interfaces/Main/UndoDTO";

const UndoDNC = forwardRef((props, ref) => {
  const [undoDetails, setUndoDetails] = useState<UndoDTO | null>(null);
  const { projectId, campaignId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(false);

  const updateUndoDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const apiUrl = `main/dncdnm/undodetails?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        if (response.undoText !== "") {
          setUndoDetails(response);
        } else {
          setUndoDetails(null);
        }
      }
    } catch (error) {
      console.error("Failed to fetch undo details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [projectId, campaignId]);

  useEffect(() => {
    if (projectId && campaignId) {
      updateUndoDetails();
    }
  }, [projectId, campaignId, updateUndoDetails]);

  const handleUndo = async (tranId: number) => {
    setIsLoading(true);
    try {
      const apiUrl = `main/dncdnm/undo?projId=${projectId}&campaignId=${campaignId}&tranid=${tranId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        updateUndoDetails();
      } else {
        throw new Error("An unknown response received from the server.");
      }
    } catch (error) {
      console.error("Error undoing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    updateUndoDetails,
  }));

  return (
    <div>
      {isLoading && <div>Loading...</div>}
      {undoDetails && (
        <div className="undo-card">
          <div style={{ textTransform: "uppercase" }}>
            {undoDetails.undoText.includes(";")
              ? undoDetails.undoText.split(";").map((part, index) => (
                  <Fragment key={index}>
                    {part}
                    {index < undoDetails.undoText.split(";").length - 1 && (
                      <br />
                    )}
                  </Fragment>
                ))
              : undoDetails.undoText}
          </div>

          <div>{undoDetails.undoAction}</div>

          {!undoDetails.errorText && (
            <div className="p-2">
              <button
                className="btn btn-secondary"
                onClick={() => handleUndo(undoDetails.tranId)}
              >
                <i className="bi bi-x-circle me-1"></i>
                Undo Last Change
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default UndoDNC;
