import { useEffect } from "react";
import * as React from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import Citation from "src/components/ComplianceGuide/Citation";
import SafeHtml from "src/components/SafeHtml";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";
import YesNoIcon from "src/components/YesNoIcon";

const PermissionToContinue: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        {isCheckedHideStatesWithNoRules ? null : (
          <>
            <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
            <div className="regulatory-contents">
              <RegulatoryGuideSection label="" value="N/A" />
            </div>
          </>
        )}
      </>
    );
  }
  const rec = JSON.parse(data)[0];

  return (
    <>
      <>
        {rec.state !== "FCC" &&
        rec.state !== "FTC" &&
        rec.permDifferentFromFed === true ? (
          <>
            <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
            <div className="regulatory-rules-info">
              <div className="regulatory-contents">
                <table className="table table-border content-width-table mt-3">
                  <tbody>
                    <tr>
                      <td>Different From Fed</td>
                      <td>
                        <YesNoIcon value={rec.permDifferentFromFed} size={16} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <RegulatoryGuideSection
                  label="Compliance Rule"
                  value={<SafeHtml html={rec.complianceRule} />}
                />
                <RegulatoryGuideSection
                  label="Citation"
                  value={
                    <Citation
                      statuteId={rec.complianceRuleStatuteId}
                      statuteName={rec.complianceRuleStatuteNumber}
                      ref1={rec.complianceRuleRef1}
                      ref2={rec.complianceRuleRef2}
                      ref3={rec.complianceRuleRef3}
                    />
                  }
                />
                <RegulatoryGuideSection
                  label="Inbound Rule"
                  value={<SafeHtml html={rec.inboundRule} />}
                  hideIfValue={rec.inboundRule}
                />
              </div>
            </div>
          </>
        ) : isCheckedHideStatesWithNoRules === true ? null : (
          <>N/A</>
        )}
      </>
    </>
  );
};

export default PermissionToContinue;
