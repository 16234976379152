// LoginLayout.tsx
import { useState, useEffect } from "react";

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import styles from "src/pages/Login.module.css";
import { getLogo } from "src/services/logoService";
import InstallBannerForIOS from "src/components/InstallBannerForIOS";

type LoginLayoutProps = {
  children: React.ReactNode;
};

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  const [logo, setLogo] = useState("");
  const [isWhitelabelled, setIsWhitelabelled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const defaultLogo = "/images/DNCScrubLight.png";
    const fetchedLogo = getLogo("");
    if (fetchedLogo) {
      setIsWhitelabelled(true);
      setLogo(fetchedLogo);
    } else {
      setLogo(defaultLogo);
    }
  }, []);

  const supportEmail = logo.includes("nxxg")
    ? "compliance@nexxagroup.com"
    : "support@dnc.com";

  const customClass =
    location.pathname === "/login/self-register"
      ? styles.selfRegisterLoginFormContainer
      : "";

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* This div gives gradient background */}
      <div className={styles["login-page-background"]}>
        {/* This div gives us a flexbox container so login box centered but still towards top of screen */}
        <div className={`${styles["login-form-container"]} ${customClass}`}>
          {/* This div is the actual login box */}
          <div className={styles["login-form"]}>
            <img src={logo} alt="DNCScrub Logo" className={styles.appLogo} />
            {children}
            <p className="mt-3 mb-0 flex align-items-center">
              Questions? E-mail{" "}
              <a href="mailto:{supportEmail}">{supportEmail}</a>
            </p>

            <InstallBannerForIOS />
          </div>
        </div>
      </div>
      {!isWhitelabelled && (
        <footer className={styles["login-page-footer"]}>
          <a target="_blank" href="https://dnc.com/" rel="noreferrer noopener">
            Contact Center Compliance, Corp.
          </a>{" "}
          {/* Questions? <a href="mailto:info@dnc.com">support@dnc.com</a> */}
        </footer>
      )}
    </>
  );
};

export default LoginLayout;
