import * as React from "react";
import KeyValuePair from "src/interfaces/KeyValuePair";

interface DropdownProps {
  options: KeyValuePair[];
  name: string;
  register: any;
  disabled?: boolean;
  defaultValue?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  name,
  register,
  defaultValue,
  disabled = false,
}) => {
  return (
    <select
      name={name}
      {...register(name)}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {options.map((option, index) => (
        <option key={index} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
