import { useEffect } from "react";
import * as React from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import SafeHtml from "src/components/SafeHtml";
import Citation from "src/components/ComplianceGuide/Citation";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";
import YesNoIcon from "src/components/YesNoIcon";

const CommercialRegistration: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);
  let rec;

  if (data && data.length > 0) {
    rec = JSON.parse(data)[0];
  }

  if (!data || data.length === 0 || (rec && rec.regRule === null)) {
    return (
      <>
        {isCheckedHideStatesWithNoRules ? null : (
          <>
            <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
            <div className="regulatory-contents">
              <RegulatoryGuideSection label="" value="N/A" />
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      <div className="regulatory-contents">
        <table className="table table-border content-width-table">
          <tbody>
            <tr>
              <td>Registration Required</td>
              <td>
                {rec.regRequired ? (
                  <YesNoIcon value={true} size={16} />
                ) : (
                  <YesNoIcon value={false} size={16} />
                )}
              </td>
              <td>Inbound Included:</td>
              <td>
                {rec.regInboundIncluded ? (
                  <YesNoIcon value={true} size={16} />
                ) : (
                  <YesNoIcon value={false} size={16} />
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="content-width-table">
          <tbody>
            <tr>
              <td>
                <b>Exemptions available:</b>
              </td>
              <td className="ps-2">
                <SafeHtml html={rec.exemptionCount} />
              </td>
            </tr>
          </tbody>
        </table>

        <RegulatoryGuideSection
          label="Compliance Rule"
          value={<SafeHtml html={rec.regRule} />}
        />
        <RegulatoryGuideSection
          label="Citation"
          value={
            <Citation
              statuteId={rec.regRuleStatuteId}
              statuteName={rec.regRuleStatuteNumber}
              ref1={rec.regRuleRef1}
              ref2={rec.regRuleRef2}
              ref3={rec.regRuleRef3}
            />
          }
        />
        <RegulatoryGuideSection
          label="Inbound Rule"
          value={<SafeHtml html={rec.inboundRule} />}
        />

        <table className="table table-border content-width-table">
          <tbody>
            <tr>
              <td>Registration Fee (initial)</td>
              <td>{rec.regFee}</td>
            </tr>

            <tr>
              <td>Additional Fee may be required</td>
              <td>{rec.regAdditionalFees}</td>
            </tr>

            <tr>
              <td>Registration Frequency</td>
              <td>{rec.regAnnualMultiple}</td>
            </tr>

            <tr>
              <td>Registration Bond</td>
              <td>{rec.regBond}</td>
            </tr>

            <tr>
              <td>Additional Bond may be required</td>
              <td>{rec.regBond}</td>
            </tr>
          </tbody>
        </table>

        <table className="table table-border content-width-table">
          <tbody>
            <tr>
              <td>Contact</td>
              <td>{rec.regContact}</td>
            </tr>
            <tr>
              <td>URL</td>
              <td>{rec.regUrl}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CommercialRegistration;
